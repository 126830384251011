import {authenticate} from 'src/shared/auth/actions';
import {
  datacast,
  READ_DATACAST_COMPLETE,
} from 'src/shared/datacast/actions';
import {
  hasOutageMessagesSegment,
  hasProactiveIssueDetectionSegment,
} from 'src/shared/datacast/selectors';
import {startDataLayerTracking} from 'src/shared/dataLayer/actions';
import {readDropBury} from 'src/shared/dropBury/actions';
import {waitUntil} from 'src/shared/events';
import {preloadItgRecommendations} from 'src/shared/itgRecommendations/actions';
import {
  loadOutageConsolidated,
  loadOutages,
  loadOutageHistory,
  loadCombinedCurrentOutageAndOutageHistory,
  LOAD_OUTAGE_CONSOLIDATED_COMPLETE,
  LOAD_OUTAGE_HISTORY_COMPLETE,
} from 'src/shared/outages/actions';
import {
  loadPersonalization,
  loadPersonalizationHistory,
} from 'src/shared/personalization/actions';
import {
  LOAD_CONNECTIVITYSTATE_COMPLETE,
  loadConnectivityState,
} from 'src/shared/connectivityState/actions';

import {loadUser, LOAD_USER_COMPLETE} from 'src/shared/user/actions';
import {outageHistoryRoleCheckSelector} from 'src/shared/outages/selectors';
import {flags} from 'src/shared/config/selectors';

export const startPage = () => async (dispatch, getState) => {
  dispatch(authenticate());
  dispatch(loadUser());
  dispatch(startDataLayerTracking());
  dispatch(readDropBury());
  dispatch(loadPersonalization());
  dispatch(loadPersonalizationHistory());
  dispatch(datacast());
  dispatch(loadConnectivityState());

  await waitUntil(READ_DATACAST_COMPLETE, LOAD_CONNECTIVITYSTATE_COMPLETE);
  if (hasProactiveIssueDetectionSegment(getState())) {
    dispatch(preloadItgRecommendations());
  }
  if (hasOutageMessagesSegment(getState())) {
    dispatch(loadOutageConsolidated());
  } else {
    dispatch(loadOutages());
  }
  await waitUntil(LOAD_USER_COMPLETE);
  if (flags(getState()).storeCombinedOutageAndHistoryOutageEnabled
    && outageHistoryRoleCheckSelector(getState())) {
    dispatch(loadOutageHistory());
    await waitUntil(LOAD_OUTAGE_CONSOLIDATED_COMPLETE, LOAD_OUTAGE_HISTORY_COMPLETE);
    dispatch(loadCombinedCurrentOutageAndOutageHistory());
  }
};
