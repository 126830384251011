import React from 'react';
import {url} from 'src/shared/utils/url';

export const Internet = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props} role="img" aria-hidden="true">
    <g transform="translate(-1.5 15.5)">
      <defs>
        <linearGradient
          id="internet-dark-gradient-1"
          gradientUnits="userSpaceOnUse"
          x1="-37.215"
          y1="3.204"
          x2="-37.215"
          y2="2.213"
          gradientTransform="matrix(18.8885 0 0 62.8432 755.084 -135.65)"
        >
          <stop offset="0" stopColor="#021721"></stop>
          <stop offset="1" stopColor="#29343f"></stop>
        </linearGradient>
        <linearGradient
          id="internet-dark-gradient-2"
          gradientUnits="userSpaceOnUse"
          x1="-36.716"
          y1="3.063"
          x2="-36.716"
          y2="1.931"
          gradientTransform="matrix(12.424 0 0 61.2684 508.306 -132.496)"
        >
          <stop offset="0" stopColor="#364251"></stop>
          <stop offset="1" stopColor="#44576b"></stop>
        </linearGradient>
      </defs>
      <path fill="none" d="M3 67h97.6" stroke="#cad9e0" strokeLinecap="round"></path>
      <path fill={url('#internet-dark-gradient-1')} d="M60 63.1V6.9c0-1.9-1.6-3.5-3.6-3.5h-8.5c-2 0-3.6 1.6-3.6 3.5v56.2c0 .8-.7 1.6-1.6 1.6v1.6h18.9v-1.6c-.9 0-1.6-.7-1.6-1.6z"></path>
      <path fill={url('#internet-dark-gradient-2')} d="M45.9 66.3V6.9c0-1.1.9-1.9 2-1.9h8.5c1.1 0 2 .9 2 1.9v59.3H45.9v.1z"></path>
      <path fill="#51677a" fillOpacity=".5" d="M58.3 40.3L45.9 28.2v38.1h12.4z"></path>
      <path fill="#97aeb7" d="M52 13c-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3 1.3-.6 1.3-1.3S52.7 13 52 13z"></path>
      <path fill="#97aeb7" d="M52 19.6c-.7 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3s1.3-.6 1.3-1.3-.6-1.3-1.3-1.3z"></path>
      <path fill="#97aeb7" d="M52 26.2c-.7 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3s1.3-.6 1.3-1.3c0-.7-.6-1.3-1.3-1.3z"></path>
      <path fill="#97aeb7" d="M52 32.8c-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3 1.3-.6 1.3-1.3c0-.8-.6-1.3-1.3-1.3z"></path>
      <path fill="#97aeb7" d="M52 39.4c-.7 0-1.3.6-1.3 1.3S51.3 42 52 42s1.3-.6 1.3-1.3-.6-1.3-1.3-1.3z"></path>
      <path fill="#97aeb7" d="M52 46c-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3 1.3-.6 1.3-1.3S52.7 46 52 46z"></path>
    </g>
  </svg>
);
