import {
  READ_EXTENDED_PROFILE_COMPLETE,
  READ_EXTENDED_PROFILE_ERROR,
  READ_EXTENDED_PROFILE_START,
  READ_EXTENDED_PROFILE_SUCCESS,
} from 'src/shared/extendedProfile/actions';
import {createResourceReducer} from 'src/shared/utils/resource';

export const extendedProfile = createResourceReducer({
  start: READ_EXTENDED_PROFILE_START,
  success: READ_EXTENDED_PROFILE_SUCCESS,
  error: READ_EXTENDED_PROFILE_ERROR,
  complete: READ_EXTENDED_PROFILE_COMPLETE,
});
