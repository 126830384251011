import {combineReducers} from 'redux';
import camelCase from 'lodash/camelCase';

const makeReducer = (regex, computeValue = () => true) => (state = {}, {type}) => {
  if (regex.test(type)) {
    const [, resource, status] = regex.exec(type);
    return {...state, [camelCase(resource)]: computeValue(status)};
  }
  return state;
};

export const resourceStatus = combineReducers({
  complete: makeReducer(
    /(?:CREATE|READ|UPDATE|DELETE|LOAD)_(\w+)_(COMPLETE|START)/,
    status => status === 'COMPLETE'
  ),
  loaded: makeReducer(/(?:CREATE|READ|UPDATE|DELETE|LOAD)_(\w+)_(SUCCESS)/),
  loading: makeReducer(
    /(?:CREATE|READ|UPDATE|DELETE|LOAD)_(\w+)_(START|ERROR|SUCCESS)/,
    status => status === 'START'
  ),
  error: makeReducer(
    /(?:CREATE|READ|UPDATE|DELETE|LOAD)_(\w+)_(ERROR|START)/,
    status => status === 'ERROR'
  ),
});
