import {LOAD_COMBINED_OUTAGE_OBJ_COMPLETE} from 'src/shared/outages/actions';

import {logOutageTrackingEvent} from 'src/shared/dataLayer/loggers';

export const dataLayer = () => next => action => {
  next(action);
  if (action.type === LOAD_COMBINED_OUTAGE_OBJ_COMPLETE) {
    logOutageTrackingEvent();
  }
};
