import {connect} from 'react-redux';
import {UnconnectedPageFooter} from 'src/shared/components/PageFooter/UnconnectedPageFooter';
import {header, links} from 'src/shared/components/PageFooter/selectors';
import {triggerChannelTracking} from 'src/shared/channelTracking/actions/triggerChannelTrackingAction';

const mapStateToProps = (state, ownProps) => ({
  header: ownProps.header || header(state),
  links: ownProps.links || links(state),
});

const mapDispatchToProps = dispatch => ({
  triggerChannelTracking: data => dispatch(triggerChannelTracking(data)),
});

export const PageFooter = connect(mapStateToProps, mapDispatchToProps)(UnconnectedPageFooter);
