exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".mainNarrow-5e0fa,.mainWide-c9d1f{box-sizing:border-box;flex:1 0 auto;margin:0 auto 4rem;min-height:45rem;padding:0 1.2rem;width:100%}.mainNarrow-5e0fa:first-child,.mainWide-c9d1f:first-child{margin-top:3rem}@media(max-width: 320px){.mainNarrow-5e0fa,.mainWide-c9d1f{padding:0}}.mainNarrow-5e0fa .section-7b9a1,.mainWide-c9d1f .section-7b9a1{margin-bottom:2.4rem}.mainNarrow-5e0fa .section-7b9a1:last-child,.mainWide-c9d1f .section-7b9a1:last-child{margin-bottom:0}@media(max-width: 530px){.mainNarrow-5e0fa .flushMobile-32137,.mainWide-c9d1f .flushMobile-32137{margin-left:-1.2rem;width:calc(100% + 2.4rem)}}@media(max-width: 320px){.mainNarrow-5e0fa .flushSmallMobile-ba242,.mainWide-c9d1f .flushSmallMobile-ba242{margin-left:-1.2rem;width:calc(100% + 2.4rem)}}.mainNarrow-5e0fa{max-width:52.9rem}.mainWide-c9d1f{max-width:104.8rem}", ""]);

// Exports
exports.locals = {
	"mainNarrow": "mainNarrow-5e0fa",
	"mainWide": "mainWide-c9d1f",
	"section": "section-7b9a1",
	"flushMobile": "flushMobile-32137",
	"flushSmallMobile": "flushSmallMobile-ba242"
};