import PropTypes from 'prop-types';
import React from 'react';
import {
  action,
  directory,
  directoryItem,
} from 'src/pages/home/components/Directory/styles.module.scss';
import {Link} from 'src/shared/components/Link';
import {Home} from 'src/svgs/home/Home';
import {Internet} from 'src/svgs/home/Internet';
import {Mobile} from 'src/svgs/home/Mobile';
import {Video} from 'src/svgs/home/Video';
import {Voice} from 'src/svgs/home/Voice';

export const Directory = ({tiles}) => (
  <div className={directory}>
    {
      tiles.map(tile => {
        let icon;
        switch (tile.iconId) {
          case 'service:home':
            icon = <Home />;
            break;
          case 'service:internet':
            icon = <Internet />;
            break;
          case 'service:mobile':
            icon = <Mobile />;
            break;
          case 'service:video':
            icon = <Video />;
            break;
          case 'service:voice':
            icon = <Voice />;
            break;
          default:
            icon = null;
        }
        return (
          <div className={directoryItem} key={tile.title}>
            <Link {...tile.callToAction}>{icon} <h2>{tile.title}</h2></Link>
            {
              tile.links?.length
              && (<ul>{tile.links.map(link => (<li key={link.text}><Link {...link} /></li>))}</ul>)
            }
            {
              tile.description
              && (<p>{tile.description}</p>)
            }
            <Link className={action} {...tile.callToAction} />
          </div>
        );
      })
    }
  </div>
);

Directory.propTypes = {
  tiles: PropTypes.array,
};
