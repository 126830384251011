import {
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux';
import thunk from 'redux-thunk';
import {logger} from 'src/pages/home/store/enhancers/logger';
import {auth} from 'src/shared/auth/reducers';
import {config} from 'src/shared/config/reducers';
import {content} from 'src/shared/content/reducers';
import {cookie} from 'src/shared/cookie/reducers';
import {datacast} from 'src/shared/datacast/reducers';
import {dropBury} from 'src/shared/dropBury/reducers';
import {events} from 'src/shared/events/middleware';
import {extendedProfile} from 'src/shared/extendedProfile/reducers';
import {guidedSearch} from 'src/shared/guidedSearch/reducers';
import {
  outageConsolidated,
  outages,
  outageHistory,
  combinedOutageObj,
} from 'src/shared/outages/reducers';
import {connectivityState} from 'src/shared/connectivityState/reducers';
import {params} from 'src/shared/params/reducers';
import {
  personalization,
  personalizationHistory,
  supportlets,
} from 'src/shared/personalization/reducers';
import {resourceStatus} from 'src/shared/resourceStatus/reducers';
import {user} from 'src/shared/user/reducers';
import {compose} from 'src/shared/utils/compose';
import {createChannelTrackingMiddleware} from 'src/shared/channelTracking/middleware';
import {dataLayer} from 'src/pages/home/store/enhancers/dataLayer';

export const store = createStore(
  combineReducers({
    auth,
    combinedOutageObj,
    config,
    content,
    cookie,
    datacast,
    dropBury,
    extendedProfile,
    guidedSearch,
    outageConsolidated,
    outages,
    outageHistory,
    params,
    personalization,
    personalizationHistory,
    resourceStatus,
    supportlets,
    user,
    connectivityState,
  }),
  compose(
    applyMiddleware(
      thunk,
      logger,
      createChannelTrackingMiddleware(),
      events,
      dataLayer
    )
  )
);
