import PropTypes from 'prop-types';
import React from 'react';

const onKeyDown = event => {
  if (event.key === 'Enter') {
    event.target.click();
  }
};
const handleLinkBehavior = (text, url) => event => {
  if (url) {
    const y = screen.height - 612;
    const x = screen.width - 415;
    const newWindow = window.open('', text, `top=${y},left=${x},width=375,height=582`);
    if (newWindow) {
      newWindow.opener = null;
      newWindow.location = url;
    }
    event.preventDefault();
  }
};

export const Link = props => {
  const {text, children, openModal, ...rest} = props;

  if (rest.target === '_blank' && !rest.rel) {
    rest.rel = 'noopener noreferrer';
  }

  if (rest.href === 'openModal') {
    return (
      <button onClick={openModal} {...rest} style={{cursor: 'pointer'}}>
        {children || text}
      </button>
    );
  } else if (rest.behavior === 'popup') {
    return (
      <a onClick={handleLinkBehavior(text, rest.href)} onKeyDown={onKeyDown} {...rest} role="button" tabIndex={0}>
        {children || text}
      </a>
    );
  }

  return (
    <a {...rest}>
      {children || text}
    </a>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  openModal: PropTypes.func,
};
