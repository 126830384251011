import {createResourceSelector} from 'src/shared/utils/resource';
import {createSelector} from 'reselect';
export const connectivityState = createResourceSelector('connectivityState');

export const wanInterfaces = createSelector(
  connectivityState,
  connectivityState => connectivityState?.wanInterfaces || []
);
export const isStormReadyWifi = createSelector(
  connectivityState,
  connectivityState =>
    (connectivityState?.wanInterfaces?.some(wanInterface => wanInterface?.type === 'LTE')) || false
);
export const connectivityDeviceState = createSelector(
  connectivityState,
  connectivityState => connectivityState?.connectivityState?.toLowerCase() || null
);

