import {
  fetchWithToken,
  wait,
} from 'src/shared/api';
import {AUTH} from 'src/shared/auth/actions';
import {token} from 'src/shared/auth/selectors';
import {itgRecommendations} from 'src/shared/config/urls';
import {waitUntil} from 'src/shared/events';
import {anHashParam} from 'src/shared/extendedProfile/selectors';
import {errorToJSON} from 'src/shared/utils/errorToJSON';
import {createFetchResourceAction} from 'src/shared/utils/resource';

export const PRELOAD_ITG_RECOMMENDATIONS_START = 'PRELOAD_ITG_RECOMMENDATIONS_START';
export const PRELOAD_ITG_RECOMMENDATIONS_ERROR = 'PRELOAD_ITG_RECOMMENDATIONS_ERROR';
export const PRELOAD_ITG_RECOMMENDATIONS_SUCCESS = 'PRELOAD_ITG_RECOMMENDATIONS_SUCCESS';
export const PRELOAD_ITG_RECOMMENDATIONS_COMPLETE = 'PRELOAD_ITG_RECOMMENDATIONS_COMPLETE';

const suppressLogging = async response => {
  const json = await response.json();
  return response.ok && ['created', 'processing'].includes(json.status);
};

export const preloadItgRecommendations = () => createFetchResourceAction({
  start: PRELOAD_ITG_RECOMMENDATIONS_START,
  success: PRELOAD_ITG_RECOMMENDATIONS_SUCCESS,
  error: PRELOAD_ITG_RECOMMENDATIONS_ERROR,
  complete: PRELOAD_ITG_RECOMMENDATIONS_COMPLETE,
  waitUntil: AUTH,
  url: state => itgRecommendations(state, anHashParam(state)),
  token,
  fetchOptions: {suppressLogging},
});

export const LOAD_ITG_RECOMMENDATIONS_START = 'LOAD_ITG_RECOMMENDATIONS_START';
export const LOAD_ITG_RECOMMENDATIONS_IN_PROGRESS = 'LOAD_ITG_RECOMMENDATIONS_IN_PROGRESS';
export const LOAD_ITG_RECOMMENDATIONS_SUCCESS = 'LOAD_ITG_RECOMMENDATIONS_SUCCESS';
export const LOAD_ITG_RECOMMENDATIONS_ERROR = 'LOAD_ITG_RECOMMENDATIONS_ERROR';
export const LOAD_ITG_RECOMMENDATIONS_COMPLETE = 'LOAD_ITG_RECOMMENDATIONS_COMPLETE';
export const VIEW_ITG_RECOMMENDATIONS = 'VIEW_ITG_RECOMMENDATIONS';

const loadStart = () => ({
  type: LOAD_ITG_RECOMMENDATIONS_START,
});

const loadInProgress = () => ({
  type: LOAD_ITG_RECOMMENDATIONS_IN_PROGRESS,
});

const loadSuccess = resource => ({
  type: LOAD_ITG_RECOMMENDATIONS_SUCCESS,
  value: {resource},
});

const loadError = error => ({
  type: LOAD_ITG_RECOMMENDATIONS_ERROR,
  value: {error: errorToJSON(error)},
});

const loadComplete = timings => ({
  type: LOAD_ITG_RECOMMENDATIONS_COMPLETE,
  value: {timings},
});

export const loadItgRecommendations = (maxAttempts = 30) => async (dispatch, getState) => {
  const actionStartTime = window.performance.now();
  let requestStartTime;
  let requestEndTime;
  dispatch(loadStart());
  await waitUntil(AUTH);
  const url = itgRecommendations(getState(), anHashParam(getState()));
  let attempts = 0;
  try {
    requestStartTime = window.performance.now();
    while (true) {
      attempts += 1;
      const response = await fetchWithToken(url, token(getState()), {suppressLogging});
      if (response.ok) {
        const json = await response.json();
        if (json.status === 'success') {
          dispatch(loadSuccess(json));
          requestEndTime = window.performance.now();
          break;
        }
        if (json.status === 'created' || json.status === 'processing') {
          dispatch(loadInProgress());
          await wait(10000 * Math.tanh(attempts / 5));
        }
      } else {
        dispatch(loadError(new Error(response.status)));
        requestEndTime = window.performance.now();
        break;
      }
      if (attempts >= maxAttempts) {
        dispatch(loadError(new Error(`Attempted ${attempts} times, but recommendations did not complete.`)));
        break;
      }
    }
  } catch (error) {
    dispatch(loadError(error));
  } finally {
    dispatch(loadComplete({
      action: window.performance.now() - actionStartTime,
      request: requestEndTime - requestStartTime,
    }));
  }
};

export const viewItgRecommendation = itgId => ({
  type: VIEW_ITG_RECOMMENDATIONS,
  value: {itgId},
});

export const SET_PRESET_DOC_ID = 'SET_PRESET_DOC_ID';

export const setPresetDocId = presetDocId => ({
  type: SET_PRESET_DOC_ID,
  value: {presetDocId},
});
