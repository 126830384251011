import {createSelector} from 'reselect';
import {content} from 'src/shared/content/selectors';
import {socialFooterContent} from 'src/shared/components/SocialFooter/selectors';
import {flags} from 'src/shared/config/selectors';
import {xfinityAssistant} from 'src/shared/config/urls';
import {currentLanguage} from 'src/shared/utils/currentLanguage';
import {scheduleCallback} from 'src/shared/pagePaths';
import {TRANSFERRED} from 'src/shared/channelTracking/constants/actionIds';
import {SUCCESS} from 'src/shared/channelTracking/constants/status';
import {UNKNOWN} from 'src/shared/channelTracking/constants/interactionType';
import {XA} from 'src/shared/channelTracking/constants/targetChannels';

const isAccessibilityPage = location.pathname.includes('support/accessibility');
const isInternetPage = location.pathname.includes('support/internet');
const estaEnEspanol = currentLanguage() === 'es';

export const links = createSelector(
  [content, flags, xfinityAssistant],
  (content, flags, xfinityAssistant) => (
    (content?.footer?.footer?.content?.[0]?.links || [])
      .filter(link => flags.virtualHoldLinksEnabled || !link.url.includes(scheduleCallback()))
      .filter(link => flags.storeLocatorLinksEnabled || !link.url.includes('xfinity.com/local/store-offers'))
      .map(link => {
        const includesChatUrl = link.url.includes('support.xfinity.com/chat');
        const includesScheduleCallbackUrl = link.url.includes('support/schedule-callback');
        const includesXfinityAssistantUrl = link.url.includes('xfinity.com/xfinityassistant');

        if (isAccessibilityPage && (includesChatUrl || includesXfinityAssistantUrl)) {
          return {
            ...link,
            iconId: 'tool:xfinity-assistant',
            text: 'Ask Xfinity',
            subtext: 'Chat with Xfinity Assistant',
            url: 'https://support.xfinity.com/accessibility',
          };
        }
        if (isAccessibilityPage && includesScheduleCallbackUrl) {
          return {...link, url: `${link.url}?selectedIntent=ACCESSIBILITY&continue=https%3A%2F%2Fwww.xfinity.com%2Fsupport%2Faccessibility`};
        }
        if (estaEnEspanol && (includesChatUrl || includesXfinityAssistantUrl)) {
          return {
            iconId: 'tool:xfinity-assistant',
            iconUrl: '',
            subtext: 'Chat with Xfinity Assistant',
            text: 'Ask Xfinity',
            url: 'https://www.xfinity.com/xfinityassistant/?intent=other-style_spanish-chat&referrer=hs_spanish_chat',
            behavior: 'spanish',
          };
        }
        if (flags.xfinityAssistantEnabled && includesChatUrl) {
          const footerXfinityAssistantUrl = `${xfinityAssistant}${isInternetPage ? '&intent=skill.xfi.internet.troubleshoot' : ''}`;
          link = {
            behavior: 'popup',
            iconId: 'tool:xfinity-assistant',
            iconUrl: '',
            subtext: 'Chat with Xfinity Assistant',
            text: 'Ask Xfinity',
            url: footerXfinityAssistantUrl,
            fiveStandardsTracking: {
              action: {
                id: TRANSFERRED,
                status: SUCCESS,
                metadata: {
                  targetChannel: XA,
                },
              },
              interactionType: UNKNOWN,
            },
          };
        }
        return link;
      })
  )
);

export const header = createSelector(
  content,
  content => content?.footer?.footer?.content?.[0]?.header || ''
);

export const pageFooterContent = createSelector(
  [header, links, socialFooterContent],
  (header, links, socialFooterContent) => ({
    header,
    links,
    socialFooterContent,
  })
);
