import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {UnconnectedSupportlets} from 'src/shared/components/Supportlets/UnconnectedSupportlets';
import {
  dismissSupportlet,
  displaySupportlet,
} from 'src/shared/personalization/actions';
import {visibleSupportlets} from 'src/shared/personalization/selectors';
import {
  futureOutage,
  outageImpact,
} from 'src/shared/outages/selectors';
import {hasOutageMessagesSegment} from 'src/shared/datacast/selectors';
import {outageNotice} from 'src/shared/outages/selectors/outageNotice';

const mapStateToProps = (state, ownProps) => {
  if (!state.supportlets) {
    console.warn('The `supportlets` property is missing from the state. Be sure to add the `supportlets` reducer to the store.');
  }
  return {
    className: ownProps.className,
    futureOutage: futureOutage(state),
    hasOutageMessagesSegment: hasOutageMessagesSegment(state),
    outageImpact: outageImpact(state),
    outageNotice: outageNotice(state),
    supportlets: visibleSupportlets(ownProps.supportlets)(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onDismiss: supportlet => dispatch(dismissSupportlet(supportlet)),
  onDisplay: supportlet => dispatch(displaySupportlet(supportlet)),
});

export const Supportlets = connect(mapStateToProps, mapDispatchToProps)(UnconnectedSupportlets);

Supportlets.propTypes = {
  className: PropTypes.string,
  supportlets: PropTypes.arrayOf(PropTypes.func).isRequired,
};
