import React from 'react';
import {url} from 'src/shared/utils/url';

export const Home = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props} role="img" aria-hidden="true">
    <g transform="translate(-1.5 19.5)">
      <defs>
        <linearGradient
          id="home-dark-gradient-1"
          x1="-18.633"
          x2="-19.695"
          y1="103.106"
          y2="104.104"
          gradientTransform="matrix(63.6239 0 0 -59.8813 1277.09 6255.141)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#364251"></stop>
          <stop offset="1" stopColor="#44576b"></stop>
        </linearGradient>
        <linearGradient
          id="home-dark-gradient-2"
          x1="-19.82"
          x2="-18.756"
          y1="103.985"
          y2="103.985"
          gradientTransform="matrix(70.3606 0 0 -26.1981 1408.361 2715.72)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#001722"></stop>
          <stop offset="1" stopColor="#3f4b5a"></stop>
        </linearGradient>
      </defs>
      <path fill={url('#home-dark-gradient-1')} d="M82.7 63.2H19.1V26.8L50.9 3.3l31.8 23.5z"></path>
      <path fill="#51677a" fillOpacity=".3" d="M34.6 15.3L19.1 26.7v36.5h63.6z"></path>
      <path fill="none" stroke={url('#home-dark-gradient-2')} strokeLinecap="square" strokeWidth="4" d="M16.1 28L51.3 1.8 86.4 28"></path>
      <path fill="#5d7587" d="M31.8 35.5h15v27.7h-15zM55.7 35.5h15v17.2h-15z"></path>
      <path fill="none" stroke="#cad9e0" strokeLinecap="round" d="M2.6 63.2h96.9"></path>
    </g>
  </svg>
);
