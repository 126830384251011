import {xfinityAssistant} from 'src/shared/config/urls';
import {trackXA} from 'src/shared/xfinityAssistant/channelTracking';
import {triggerChannelTracking} from '../channelTracking/actions/triggerChannelTrackingAction';

export const launchXfinityAssistant = (utterance, intent) => (dispatch, getState) => {
  const paramList = {};
  if (utterance) {
    paramList.utterance = utterance;
  }
  if (intent) {
    paramList.intent = intent;
  }
  const y = screen.height - 612;
  const x = screen.width - 415;
  const newWindow = window.open('', 'Xfinity Assistant', `top=${y},left=${x},width=375,height=582`);
  dispatch(triggerChannelTracking(trackXA(utterance || intent)));
  if (newWindow) {
    newWindow.opener = null;
    newWindow.location = xfinityAssistant(getState(), paramList);
  }
};
