import {AUTH} from 'src/shared/auth/actions';
import {waitUntil} from 'src/shared/events';
import {errorToJSON} from 'src/shared/utils/errorToJSON';
import {createFetchResourceAction} from 'src/shared/utils/resource';
import {personalization} from 'src/shared/config/urls';
import {token} from 'src/shared/auth/selectors';
import {
  readJSONFromLocalStorage,
  writeJSONToLocalStorage,
} from 'src/shared/utils/storage';

const PERSONALIZATION_HISTORY_STORAGE_KEY = 'xhs-personalization-history';
const MAX_MESSAGE_HISTORY_SIZE = 32;
const MAX_MESSAGE_HISTORY_AGE = 6048e5; // 7 days

export const READ_PERSONALIZATION_START = 'READ_PERSONALIZATION_START';
export const READ_PERSONALIZATION_COMPLETE = 'READ_PERSONALIZATION_COMPLETE';
export const READ_PERSONALIZATION_SUCCESS = 'READ_PERSONALIZATION_SUCCESS';
export const READ_PERSONALIZATION_ERROR = 'READ_PERSONALIZATION_ERROR';

export const loadPersonalization = () => createFetchResourceAction({
  start: READ_PERSONALIZATION_START,
  success: READ_PERSONALIZATION_SUCCESS,
  error: READ_PERSONALIZATION_ERROR,
  complete: READ_PERSONALIZATION_COMPLETE,
  waitUntil: [AUTH],
  url: personalization,
  token,
});

export const READ_PERSONALIZATION_HISTORY_START = 'READ_PERSONALIZATION_HISTORY_START';
export const READ_PERSONALIZATION_HISTORY_COMPLETE = 'READ_PERSONALIZATION_HISTORY_COMPLETE';
export const READ_PERSONALIZATION_HISTORY_SUCCESS = 'READ_PERSONALIZATION_HISTORY_SUCCESS';
export const READ_PERSONALIZATION_HISTORY_ERROR = 'READ_PERSONALIZATION_HISTORY_ERROR';

export const readPersonalizationHistoryStart = () => ({
  type: READ_PERSONALIZATION_HISTORY_START,
});

export const readPersonalizationHistoryComplete = () => ({
  type: READ_PERSONALIZATION_HISTORY_COMPLETE,
});

export const readPersonalizationHistorySuccess = personalizationHistory => ({
  type: READ_PERSONALIZATION_HISTORY_SUCCESS,
  value: {personalizationHistory},
});

export const readPersonalizationHistoryError = error => ({
  type: READ_PERSONALIZATION_HISTORY_ERROR,
  value: {error: errorToJSON(error)},
});

export const loadPersonalizationHistory = () => async dispatch => {
  dispatch(readPersonalizationHistoryStart());
  await waitUntil(AUTH);
  try {
    const personalizationHistory = readJSONFromLocalStorage(PERSONALIZATION_HISTORY_STORAGE_KEY)
      || {messageHistory: {}};
    dispatch(readPersonalizationHistorySuccess(personalizationHistory));
  } catch (error) {
    dispatch(readPersonalizationHistoryError(error));
  } finally {
    dispatch(readPersonalizationHistoryComplete());
  }
};

export const UPDATE_PERSONALIZATION_HISTORY_START = 'UPDATE_PERSONALIZATION_HISTORY_START';
export const UPDATE_PERSONALIZATION_HISTORY_COMPLETE = 'UPDATE_PERSONALIZATION_HISTORY_COMPLETE';
export const UPDATE_PERSONALIZATION_HISTORY_SUCCESS = 'UPDATE_PERSONALIZATION_HISTORY_SUCCESS';
export const UPDATE_PERSONALIZATION_HISTORY_ERROR = 'UPDATE_PERSONALIZATION_HISTORY_ERROR';

export const updatePersonalizationHistoryStart = () => ({
  type: UPDATE_PERSONALIZATION_HISTORY_START,
});

export const updatePersonalizationHistoryComplete = () => ({
  type: UPDATE_PERSONALIZATION_HISTORY_COMPLETE,
});

export const updatePersonalizationHistorySuccess = personalizationHistory => ({
  type: UPDATE_PERSONALIZATION_HISTORY_SUCCESS,
  value: {personalizationHistory},
});

export const updatePersonalizationHistoryError = error => ({
  type: UPDATE_PERSONALIZATION_HISTORY_ERROR,
  value: {error: errorToJSON(error)},
});

export const updatePersonalizationHistory = ({messageHistory = {}} = {}, now = Date.now()) => async dispatch => {
  dispatch(updatePersonalizationHistoryStart());
  await waitUntil(AUTH);
  const prunedMessageHistory = Object.values(messageHistory)
    .filter(historyEntry => historyEntry.modified + MAX_MESSAGE_HISTORY_AGE > now)
    .sort((a, b) => b.modified - a.modified)
    .slice(0, MAX_MESSAGE_HISTORY_SIZE)
    .reduce((acc, record) => {
      acc[record.id] = record;
      return acc;
    }, {});
  const personalizationHistory = {messageHistory: prunedMessageHistory};
  try {
    writeJSONToLocalStorage(PERSONALIZATION_HISTORY_STORAGE_KEY, personalizationHistory);
    dispatch(updatePersonalizationHistorySuccess(personalizationHistory));
  } catch (error) {
    dispatch(updatePersonalizationHistoryError(error));
  } finally {
    dispatch(updatePersonalizationHistoryComplete());
  }
};

export const DISPLAY_SUPPORTLET = 'DISPLAY_SUPPORTLET';

export const displaySupportlet = (supportlet, timestamp = Date.now()) => (dispatch, getState) => {
  dispatch({
    type: DISPLAY_SUPPORTLET,
    value: {supportlet, timestamp},
  });
  dispatch(updatePersonalizationHistory(getState().personalizationHistory));
};

export const DISMISS_SUPPORTLET = 'DISMISS_SUPPORTLET';

export const dismissSupportlet = (supportlet, timestamp = Date.now()) => (dispatch, getState) => {
  dispatch({
    type: DISMISS_SUPPORTLET,
    value: {supportlet, timestamp},
  });
  dispatch(updatePersonalizationHistory(getState().personalizationHistory));
};
