import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {
  carousel,
  carouselContent,
  carouselImage,
  carouselItem,
  selected,
  tabLink,
  tabs,
} from 'src/pages/home/components/Carousel/styles.module.scss';
import {Link} from 'src/shared/components/Link';
import classNames from 'classnames';

const LEFT = 37;
const RIGHT = 39;

const makeId = str => str.replace(/\s+/g, '-').toLowerCase();

const changeTab = e => {
  const isDirectionPressed = (e.keyCode === LEFT || e.keyCode === RIGHT);
  if (!isDirectionPressed) {
    return;
  }
  if (e.keyCode === LEFT) {
    e.target.previousElementSibling?.focus();
  }
  if (e.keyCode === RIGHT) {
    e.target.nextElementSibling?.focus();
  }
  e.preventDefault();
};

export const Carousel = ({items}) => {
  const [currentItem, setCurrentItem] = useState(items[0]);

  return (
    <div className={carousel}>
      <div className={tabs}
        role="tablist"
        aria-label="Quick Fixes"
        aria-orientation="horizontal"
      >
        {
          items.map(item => (
            <button
              key={item.title}
              className={classNames(tabLink, {[selected]: item === currentItem})}
              onClick={() => setCurrentItem(item)}
              onKeyDown={changeTab}
              role="tab"
              aria-selected={item === currentItem}
              aria-controls={`${makeId(item.title)}-panel`}
              id={`${makeId(item.title)}-tab`}
              tabIndex={item === currentItem ? 0 : -1}
            >
              {item.title}
            </button>
          ))
        }
      </div>
      {
        items.map(item => (
          <article key={item.title} className={classNames(carouselItem, {[selected]: item === currentItem})}
            tabIndex="0"
            role="tabpanel"
            id={`${makeId(item.title)}-panel`}
            aria-labelledby={`${makeId(item.title)}-tab`}
          >
            <div className={carouselImage} style={{backgroundImage: `url(${item.image.src})`}} />
            <div className={carouselContent}>
              <h2>{item.heading}</h2>
              <p>{item.description} <Link {...item.link} /></p>
            </div>
          </article>
        ))
      }
    </div>
  );
};

Carousel.propTypes = {
  items: PropTypes.array,
};
