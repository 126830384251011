export const REDIRECT = 'REDIRECT';
export const RELOAD = 'RELOAD';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';

export const reload = () => ({
  type: RELOAD,
});

export const redirect = (redirectUrl, replace = false) => ({
  type: REDIRECT,
  value: {redirectUrl, replace},
});

export const setRedirectUrl = redirectUrl => ({
  type: SET_REDIRECT_URL,
  value: {redirectUrl},
});
