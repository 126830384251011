import {createSelector} from 'reselect';

export const auth = state => state.auth;

export const authenticationComplete = createSelector(
  auth,
  auth => 'isAuthenticated' in auth
);

export const isAuthenticated = createSelector(
  auth,
  auth => !!auth.isAuthenticated
);

export const inHome = createSelector(
  auth,
  auth => auth.inHome
);

export const lightAuth = createSelector(
  auth,
  auth => auth.lightAuth
);

export const isFullyAuthenticated = createSelector(
  isAuthenticated,
  inHome,
  lightAuth,
  (isAuthenticated, inHome, lightAuth) => isAuthenticated && !inHome && !lightAuth
);

export const secure = createSelector(
  auth,
  auth => auth.secure
);

export const token = createSelector(
  auth,
  auth => auth.token
);

export const customerGuid = createSelector(
  auth,
  auth => auth.customerGuid
);
