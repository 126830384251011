import {READ_DROP_BURY_SUCCESS} from './actions';

export const dropBury = (state = {}, action) => {
  switch (action.type) {
    case READ_DROP_BURY_SUCCESS:
      return action.value.dropBury;
    default:
      return state;
  }
};
