import {createSelector} from 'reselect';

import {selectedDevice} from 'src/pages/repair/store/selectors/selectedDevice';
import {repairType} from 'src/pages/repair/store/selectors/repairType';
import {TV} from 'src/pages/repair/constants/repairTypes';

export const selectedDeviceId = createSelector(
  [selectedDevice, repairType],
  (selectedDevice, repairType) =>
    repairType === TV ? selectedDevice.deviceId : selectedDevice.id
);
