import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {SearchResultIcon} from 'src/shared/components/GuidedSearch/SearchResultIcon';
import {a11yHidden} from 'src/styles/modules/a11y.module.scss';
import SubmitArrow from 'src/svgs/guidedsearch/SubmitArrow.svg';
import {
  gsCompact,
  gsCompactInput,
  gsCompactXaSubmit,
  gsExpanded,
  gsNarrowItem,
  gsRemove,
  gsResultIcon,
  gsResultItem,
  gsResults,
  gsResultsLabel,
  gsResultText,
  gsSearch,
  gsSelectedResult,
  gsSubmit,
  gsSubmitActive,
  gsXaPadding,
  guidedSearch,
  linkText,
  xaSumbit,
} from './style.module.scss';
import classNames from 'classnames';

const ESC = 27;
const UP_ARROW = 38;
const DOWN_ARROW = 40;
const ENTER = 13;

const availableOptions = value => [
  {
    href: `https://www.xfinity.com/search?q=${value}`,
    markup: `Search for "${value}" in support articles`,
    target: '_blank',
    title: 'article',
  },
  {
    href: '#',
    markup: `Ask the Xfinity Assistant about "${value}"`,
    target: '',
    title: 'xfinityAssistant',
    type: 'xfinityAssistant',
  },
];

export const UnconnectedGuidedSearch = ({
  className = '',
  formAction = 'https://www.xfinity.com/search',
  formTarget = '_blank',
  label = 'Search',
  maxResults = 7,
  minSearchChars = 2,
  onInitialize,
  onLaunchXfinityAssistant,
  onSaveHistory,
  onRemoveHistory,
  history,
  includeArticles = false,
  compact = false,
}) => {
  const [value, setValue] = useState('');
  const [results, setResults] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [inputFocused, setInputFocused] = useState(false);
  const [retainFocus, setRetainFocus] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const formRef = useRef(null);

  const showResults = results.length > 0 && (inputFocused || focusedIndex !== null || retainFocus);

  const focusSearchInput = () => {
    setFocusedIndex(null);
    formRef.current.querySelector('input[type="search"]').focus();
  };

  const handleKeyPress = event => {
    if ([ESC, UP_ARROW, DOWN_ARROW].includes(event.keyCode)) {
      event.preventDefault();
    }

    if (event.keyCode === ESC) {
      setValue('');
      setResults([]);
      focusSearchInput();
      return;
    }

    if (results.length === 0) {
      return;
    }

    if (event.keyCode === ENTER) {
      if (focusedIndex !== null) {
        event.preventDefault();
        const lItems = formRef.current.querySelectorAll(`.${gsResults} > ul > li`);
        lItems[focusedIndex].click();
      }
    }

    if (event.keyCode === UP_ARROW) {
      if (focusedIndex === null) {
        setFocusedIndex(results.length - 1);
        return;
      }
      if (focusedIndex === 0) {
        focusSearchInput();
        return;
      }
      setFocusedIndex(focusedIndex - 1);
      return;
    }

    if (event.keyCode === DOWN_ARROW) {
      if (focusedIndex === null) {
        setFocusedIndex(0);
        return;
      }
      if (focusedIndex === results.length - 1) {
        focusSearchInput();
        return;
      }
      setFocusedIndex(focusedIndex + 1);
    }
  };

  const handleMouseDown = () => {
    setRetainFocus(true);
  };

  const handleMouseUp = () => {
    setTimeout(() => setRetainFocus(false));
  };

  const handleBlur = () => {
    setInputFocused(false);
    if (!retainFocus) {
      setFocusedIndex(null);
    }
  };

  const handleFocusInput = () => {
    setInputFocused(true);
    setFocusedIndex(null);
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleXAPopup = () => {
    formRef.current.action = 'https://www.xfinity.com/xfinityassistant/';
    formRef.current.target = 'Xfinity Assistant';
    onLaunchXfinityAssistant(value);
  };

  const triggerSearch = result => {
    if (result && result.href) {
      if (result.target === '_blank') {
        window.open(result.href, '', 'noopener,noreferrer');
      } else {
        window.location.assign(result.href);
      }
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    if (value.trim() === '') {
      return;
    }
    onSaveHistory(value, maxResults);
    const searchOption = availableOptions(value)[0];
    triggerSearch(searchOption);
  };

  const handleClick = result => event => {
    event.preventDefault();
    switch (result.type) {
      case 'history':
        if (event.target.closest(`.${gsRemove}`)) {
          onRemoveHistory(result.title);
        } else {
          setValue(result.title);
        }
        focusSearchInput();
        break;
      case 'xfinityAssistant':
        handleXAPopup();
        onSaveHistory(value, maxResults);
        break;
      default:
        onSaveHistory(value, maxResults);
        triggerSearch(result);
        break;
    }
  };

  useEffect(() => {
    if (onInitialize) {
      onInitialize({includeArticles});
    }
  }, []);

  useEffect(() => {
    setSearchActive(false);
    if (value.trim() === '') {
      setResults(history || []);
      return;
    }
    if (value.trim().length < minSearchChars) {
      setResults([]);
      return;
    }
    setSearchActive(true);

    setResults(availableOptions(value));
  }, [history, inputFocused, focusedIndex, value]);

  return (
    <form
      action={formAction}
      className={classNames([guidedSearch, className].join(' '), {
        [gsCompact]: compact,
      })}
      method="GET"
      onBlur={handleBlur}
      onSubmit={handleSubmit}
      ref={formRef}
      role="search"
      target={formTarget}
    >
      <div className={gsSearch}>
        <label htmlFor="guidedSearchInput" className={a11yHidden}>
          {label}
        </label>
        <input
          id="guidedSearchInput"
          aria-activedescendant={focusedIndex === null ? 'guidedSearchInput' : `result${focusedIndex}`}
          aria-autocomplete="both"
          aria-controls="guidedSearchListBox"
          aria-describedby="initInstr"
          aria-expanded={showResults}
          aria-haspopup={showResults}
          aria-labelledby="guidedSearchLabel"
          aria-required="true"
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          className={classNames('', {
            [gsExpanded]: showResults,
            [gsXaPadding]: true,
            [gsCompactInput]: compact,
          })}
          maxLength="2048"
          name="utterance"
          onMouseDown={handleFocusInput}
          onFocus={handleFocusInput}
          onKeyDown={handleKeyPress}
          onChange={handleChange}
          placeholder="Search Xfinity"
          spellCheck="false"
          type="search"
          value={value}
          role="combobox"
        />
        <input type="hidden" name="referrer" value="hs_search" />
        {/* eslint-enable */}
        <button className={gsSubmit} type="submit">
          <SubmitArrow
            className={classNames(xaSumbit, {
              [gsSubmitActive]: searchActive,
              [gsXaPadding]: true,
              [gsCompactXaSubmit]: compact,
            })}
          />
          <span className={a11yHidden}>submit search</span>
        </button>
      </div>
      {
        showResults
        && (
          <div className={gsResults}>
            {
              !(searchActive) && <p id="searchLabel" className={gsResultsLabel}>Quick Links</p>
            }
            <ul
              id="guidedSearchListBox"
              aria-labelledby="searchLabel"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              role="listbox"
            >
              {
                results.map((result, index) => (
                  <li
                    aria-selected={focusedIndex === index}
                    className={classNames(gsResultItem, {
                      [gsSelectedResult]: focusedIndex === index,
                      [gsNarrowItem]: searchActive,
                    })}
                    id={`result${index}`}
                    key={result.title}
                    onClick={handleClick(result)}
                    onKeyDown={handleKeyPress}
                    rel={result.title}
                    role="option"
                    value={result}
                  >
                    {
                      result.iconId
                      && <SearchResultIcon iconId={result.iconId} className={gsResultIcon} />
                    }
                    <div className={gsResultText}>
                      {
                        searchActive
                          ? (
                            <a
                              className={linkText}
                              href={result.href}
                              id={`resultItem${index}`}
                            >
                              {result.markup}
                            </a>
                          )
                          : result.href
                            ? (
                              <a
                                dangerouslySetInnerHTML={{__html: result.markup}}
                                href={result.href}
                                id={`resultItem${index}`}
                              />
                            )
                            : (
                              <span
                                dangerouslySetInnerHTML={{__html: result.markup}}
                                id={`resultItem${index}`}
                              />
                            )
                      }
                      {
                        result.hint
                        && <aside>{result.hint}</aside>
                      }
                      {
                        (result.android || result.ios)
                        && (
                          <aside>
                            <span>Download from:</span>
                            {
                              result.android
                              && (
                                <a
                                  href={result.android}
                                  index={index}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  Google Play
                                </a>
                              )
                            }
                            {
                              result.ios
                              && (
                                <a
                                  href={result.ios}
                                  index={index}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  App Store
                                </a>
                              )
                            }
                          </aside>
                        )
                      }
                    </div>
                    {
                      result.remove
                      && (
                        <button className={gsRemove}>
                          <SearchResultIcon iconId="action:close" />
                          <span>Remove</span>
                        </button>
                      )
                    }
                  </li>
                ))
              }
            </ul>
          </div>
        )
      }
    </form>
  );
};

const target = PropTypes.oneOf(['_blank', '_self']);

UnconnectedGuidedSearch.propTypes = {
  className: PropTypes.string,
  compact: PropTypes.bool,
  formAction: PropTypes.string,
  formTarget: target,
  history: PropTypes.array,
  includeArticles: PropTypes.bool,
  label: PropTypes.string,
  maxResults: PropTypes.number,
  minSearchChars: PropTypes.number,
  onInitialize: PropTypes.func.isRequired,
  onLaunchXfinityAssistant: PropTypes.func.isRequired,
  onRemoveHistory: PropTypes.func.isRequired,
  onSaveHistory: PropTypes.func.isRequired,
};
