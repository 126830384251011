import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef,
} from 'react';
import {
  cardLink,
  linkText,
  nonLinkText,
} from 'src/styles/modules/cardLink.module.scss';
import {FlexGrid} from 'src/shared/components/FlexGrid';
import XfinityAssistantIcon from 'src/svgs/guidedsearch/XA.svg';
import {ChatAgentIcon} from 'src/svgs/footer/ChatAgentIcon';
import ServiceCenterIcon from 'src/svgs/footer/service-center.svg';
import TalkAgentIcon from 'src/svgs/footer/talk-agent.svg';
import {LanguageToggle} from 'src/shared/components/LanguageToggle';
import {SocialFooter} from '../SocialFooter';
import {
  pageFooter,
  footerCard,
} from './style.module.scss';
import {footerLink} from './propTypes';

const DisplayIcon = ({icon}) => {
  switch (icon) {
    case 'tool:chat-agent':
      return <ChatAgentIcon />;
    case 'tool:xfinity-assistant':
      return <XfinityAssistantIcon />;
    case 'tool:talk-agent':
      return <TalkAgentIcon />;
    case 'tool:service-center':
      return <ServiceCenterIcon />;
    default:
      return null;
  }
};

DisplayIcon.propTypes = {
  icon: PropTypes.string,
};

const getTarget = behavior => behavior === 'default' ? '_self' : '_blank';
const getRel = behavior => behavior === 'default' ? undefined : 'noopener noreferrer';
const getInstanceID = iconId => {
  switch (iconId) {
    case 'tool:chat-agent':
      return 'CHAT';
    case 'tool:xfinity-assistant':
      return 'XFINITY_ASSISTANT';
    case 'tool:talk-agent':
      return 'NO_CALLBACK';
    case 'tool:service-center':
      return 'STORE_LOCATOR';
    default:
      return iconId;
  }
};

const handleLinkBehavior = (behavior, text, url, track) => event => {
  track();
  if (behavior === 'popup' && url) {
    const y = screen.height - 612;
    const x = screen.width - 415;
    const newWindow = window.open('', text, `top=${y},left=${x},width=375,height=582`);
    if (newWindow) {
      newWindow.opener = null;
      newWindow.location = url;
    }
    event.preventDefault();
  }
};

export const UnconnectedPageFooter = ({
  header,
  links,
  socialFooterContent,
  triggerChannelTracking,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    links.map(({iconId}) => {
      const impressionData = new CustomEvent('c-tracking-log-dom', {
        bubbles: true,
        composed: true,
        detail: {
          eventMethod: 'component-Track',
          component: [{
            componentInfo: {
              componentID: 'contact-option',
              componentName: 'Tile',
              description: 'Tile',
              instanceID: getInstanceID(iconId),
            },
          }],
        },
      });
      ref.current.dispatchEvent(impressionData);
    });
  }, []);

  return (
    <aside className={pageFooter} ref={ref}>
      <section>
        <h2>{header}</h2>
        <FlexGrid>
          {
            links.map(({behavior, iconId, subtext, text, url, fiveStandardsTracking}) => (
              <div
                data-tracking-parent
                data-tracking={`{
                  "containerName": "Contact Options",
                  "component": [{
                    "componentInfo": {
                      "componentID": "contact-option",
                      "componentName": "Tile",
                      "description": "Tile",
                      "instanceID": "${getInstanceID(iconId)}"
                    }
                  }]
                }`}
                key={text}
              >
                <a
                  className={`${cardLink} ${footerCard}`}
                  href={url}
                  onClick={handleLinkBehavior(behavior, text, url, () => triggerChannelTracking(fiveStandardsTracking))}
                  rel={getRel(behavior)}
                  target={getTarget(behavior)}
                >
                  <DisplayIcon icon={iconId} />
                  <div>
                    <h3 className={nonLinkText}>{text}</h3>
                    <p className={linkText}>{subtext}</p>
                  </div>
                </a>
              </div>
            ))
          }
        </FlexGrid>
      </section>
      <SocialFooter
        {...socialFooterContent}
      />
      <LanguageToggle />
    </aside>
  );
};

UnconnectedPageFooter.propTypes = {
  header: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(footerLink).isRequired,
  socialFooterContent: PropTypes.object,
  triggerChannelTracking: PropTypes.func.isRequired,
};
