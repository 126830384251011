import PropTypes from 'prop-types';
import React from 'react';
import {url} from 'src/shared/utils/url';

export const ChatAgentIcon = ({className}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className={className} role="img" aria-hidden="true">
    <defs>
      <linearGradient id="chat-agent-gradient" x1="-292.6259" x2="-292.0329" y1="8.2888" y2="8.2888" gradientTransform="matrix(40.869 0 0 28.948 11960.7012 -227.4681)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#a8f4e9"></stop>
        <stop offset="1" stopColor="#75eddd"></stop>
      </linearGradient>
    </defs>
    <path fill="#dae5ec" d="M20.3 19.5h9.3c.6 0 1.1.5 1.1 1.1v7.5l-1.9-1.9h-9.2c-.6 0-1.1-.5-1.1-1.1V20c0-.3.2-.5.5-.5h1.3z"></path>
    <path fill={url('#chat-agent-gradient')} d="M3.5 3.9c-1.2 0-2.1 1-2.1 2.1v15l3.8-3.8h18.3c1.2 0 2.1-1 2.1-2.1v-10c0-.7-.5-1.2-1.2-1.2H3.5z"></path>
    <path fill="#75edde" d="M6.1 3.9H3.5c-1.2 0-2.1 1-2.1 2.1v15l3.8-3.8h14.2L6.1 3.9z"></path>
    <path fill="none" stroke="#1bd1ba" strokeLinecap="round" strokeWidth="1.1858" d="M5.6 7.5h15.7M5.6 10.7h15.7M5.6 13.8h15.7"></path>
    <path fill="#a2b9c3" d="M23 22.8c0 .6-.5 1-1 1-.6 0-1-.4-1-1s.5-1 1-1 1 .5 1 1M25.6 22.8c0 .6-.5 1-1 1-.6 0-1-.4-1-1s.5-1 1-1 1 .5 1 1M28.2 22.8c0 .6-.5 1-1 1-.6 0-1-.4-1-1s.5-1 1-1 1 .5 1 1"></path>
  </svg>
);

ChatAgentIcon.propTypes = {
  className: PropTypes.string,
};
