import mapValues from 'lodash/mapValues';
import {createSelector} from 'reselect';

export const params = state => state.params || {};

export const query = createSelector(
  params,
  params => params.query || {}
);

export const hash = createSelector(
  params,
  params => params.hash || {}
);

export const combined = createSelector(
  params,
  params => params.combined || {}
);

const parseAssociativeList = (param = '') => param
  .split(',')
  .filter(x => x)
  .reduce((acc, token) => {
    token = token.trim();
    if (token.includes(':')) {
      const [key, val] = token.split(':');
      acc[key] = val;
    } else {
      acc[token] = true;
    }
    return acc;
  }, {});

const parseTruthiness = val => {
  if (typeof val === 'string') {
    const str = val.trim().toLowerCase();
    return str !== 'false' && str !== '0' && str !== 'null' && str !== 'undefined';
  }
  return !!val;
};

export const debugDatacastSegmentsParam = createSelector(
  combined,
  combined => mapValues(parseAssociativeList(combined.debugDatacastSegments), parseTruthiness)
);

export const debugFlagsParam = createSelector(
  combined,
  combined => mapValues(parseAssociativeList(combined.debugFlags), parseTruthiness)
);
