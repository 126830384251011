import {connect} from 'react-redux';
import {HomePage} from 'src/pages/home/components/HomePage';
import {content} from 'src/shared/content/selectors';
import {hasOutageMessagesSegment} from 'src/shared/datacast/selectors';
import {futureOutage, outageList, pastOutage} from 'src/shared/outages/selectors';
import {isStormReadyWifi} from 'src/shared/connectivityState/selectors';
const mapStateToProps = state => ({
  content: content(state).home,
  futureOutage: futureOutage(state),
  hasOutageMessagesSegment: hasOutageMessagesSegment(state),
  isStormReadyWifi: isStormReadyWifi(state),
  outageList: outageList(state),
  pastOutage: pastOutage(state),
});

export const App = connect(mapStateToProps)(HomePage);
