import {createSelector} from 'reselect';
import {repairType} from 'src/pages/repair/store/selectors/repairType';

export const lineOfBusiness = createSelector(
  repairType,
  repairType => ({
    TV: 'video',
    INTERNET: 'internet',
    FLEX: 'storeFront',
  }[repairType])
);
