import isArray from 'lodash/isArray';
import {logTracking} from 'src/shared/channelTracking/actions/logTrackingAction';
import {TRIGGER_CHANNEL_TRACKING} from 'src/shared/channelTracking/actions/triggerChannelTrackingAction';

export const createChannelTrackingMiddleware = ({
  actionMap = {},
} = {}) => ({dispatch}) => next => action => {
  next(action);
  let data;
  if (action.type in actionMap) {
    data = actionMap[action.type];
  } else if (action.type === TRIGGER_CHANNEL_TRACKING) {
    data = action.value.data;
  }
  if (data) {
    if (!isArray(data)) {
      data = [data];
    }
    data.forEach(datum => dispatch(logTracking(datum)));
  }
};
