// TimeFrame
export const CURRENTOUTAGE = 'CURRENTOUTAGE';
export const FUTUREOUTAGE = 'FUTUREOUTAGE';
export const PASTOUTAGE = 'PASTOUTAGE';

// Type
export const CURRENT = 'Current';
export const INFORMATIONAL = 'Informational';
export const INTENTIONAL = 'Intentional';
export const MAINTENANCE = 'Maintenance';
export const NETWORK_IMPROVEMENT = 'Network Improvement';
export const OUTAGE = 'Outage';
export const OVERRIDE = 'Override';

// Color
export const RED = 'RED';
export const YELLOW = 'YELLOW';
export const GREEN = 'GREEN';
export const GREY = 'GREY';
export const GRAY = 'GRAY';

// Impact
export const FUTURE = 'Future';
export const HIGH = 'High Impact';
export const LOW = 'Low Impact';
export const MEDIUM = 'Medium Impact';
export const PAST = 'Resolved';

export const OUTAGE_TARGETING_KEY = 'outageTracking';
