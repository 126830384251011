import React from 'react';
import {url} from 'src/shared/utils/url';
import {path} from 'src/shared/utils/path';

export const AccountManagement = props => (
  <svg width="53px" height="52px" viewBox="0 0 53 52" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props} role="img" aria-hidden="true">
    <defs>
      <path d="M21.1166556,1.10844224 C12.6032567,1.13529032 4.59230047,6.33231187 1.3871509,14.7651665 L1.3871509,14.7651665 C0.450024991,17.2313546 0.00383544026,19.7627452 0,22.2532244 L0,22.2532244 L0,22.3171484 C0.014063281,30.8433321 5.2136418,38.8709086 13.6567243,42.0811721 L13.6567243,42.0811721 C16.1229124,43.018298 18.6530245,43.4644875 21.1435037,43.468323 L21.1435037,43.468323 L21.2150986,43.468323 C29.7387253,43.4529812 37.7637448,38.2534027 40.9727299,29.8103202 L40.9727299,29.8103202 C45.1277901,18.8805939 39.6341612,6.64937494 28.7031564,2.49431465 L28.7031564,2.49431465 C26.2471962,1.56102419 23.724755,1.11483464 21.2432251,1.10844224 L21.2432251,1.10844224 L21.1166556,1.10844224 Z" id="account-path-1"></path>
      <linearGradient x1="25.8751482%" y1="25.8737763%" x2="135.029102%" y2="135.028115%" id="account-linearGradient-3">
        <stop stopColor="#41E6D1" offset="0%"></stop>
        <stop stopColor="#41E6D1" offset="100%"></stop>
      </linearGradient>
      <path d="M1.3871509,8.37276609 C-2.76790939,19.3050493 2.72571955,31.5337114 13.6567243,35.6887716 L13.6567243,35.6887716 C21.7073134,38.749453 30.4572311,36.5722014 36.1720371,30.8471676 L36.1720371,30.8471676 L6.22875499,0.903885422 C4.16400965,2.95840292 2.49047921,5.47317326 1.3871509,8.37276609" id="account-path-4"></path>
      <linearGradient x1="-30.3889996%" y1="-6.14965325%" x2="67.6139136%" y2="91.85186%" id="account-linearGradient-6">
        <stop stopColor="#41E6D1" offset="0%"></stop>
        <stop stopColor="#1AD1BA" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="86.859899%" y1="86.8579354%" x2="3.6759259%" y2="3.67860316%" id="account-linearGradient-7">
        <stop stopColor="#0B5D66" offset="0%"></stop>
        <stop stopColor="#107E89" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-159.000000, -804.000000)">
        <g transform="translate(159.000000, 803.000000)">
          <g transform="translate(0.000000, -0.000000)">
            <g transform="translate(0.000000, 8.388068)">
              <mask id="account-mask-2" fill="white">
                <use href={path('#account-path-1')}></use>
              </mask>
              <path d="M21.1166556,1.10844224 C12.6032567,1.13529032 4.59230047,6.33231187 1.3871509,14.7651665 L1.3871509,14.7651665 C0.450024991,17.2313546 0.00383544026,19.7627452 0,22.2532244 L0,22.2532244 L0,22.3171484 C0.014063281,30.8433321 5.2136418,38.8709086 13.6567243,42.0811721 L13.6567243,42.0811721 C16.1229124,43.018298 18.6530245,43.4644875 21.1435037,43.468323 L21.1435037,43.468323 L21.2150986,43.468323 C29.7387253,43.4529812 37.7637448,38.2534027 40.9727299,29.8103202 L40.9727299,29.8103202 C45.1277901,18.8805939 39.6341612,6.64937494 28.7031564,2.49431465 L28.7031564,2.49431465 C26.2471962,1.56102419 23.724755,1.11483464 21.2432251,1.10844224 L21.2432251,1.10844224 L21.1166556,1.10844224 Z" fill={url('#account-linearGradient-3')} mask={url('#account-mask-2')}></path>
            </g>
            <g transform="translate(0.000000, 14.780469)">
              <mask id="account-mask-5" fill="white">
                <use href={path('#account-path-4')}></use>
              </mask>
              <path d="M1.3871509,8.37276609 C-2.76790939,19.3050493 2.72571955,31.5337114 13.6567243,35.6887716 L13.6567243,35.6887716 C21.7073134,38.749453 30.4572311,36.5722014 36.1720371,30.8471676 L36.1720371,30.8471676 L6.22875499,0.903885422 C4.16400965,2.95840292 2.49047921,5.47317326 1.3871509,8.37276609" fill={url('#account-linearGradient-6')} mask={url('#account-mask-5')}></path>
            </g>
            <g transform="translate(7.670881, 0.798079)">
              <path d="M21.5899489,24.6996324 C21.5899489,29.2344012 17.9130402,32.91131 13.3782713,32.91131 C8.84222398,32.91131 5.16531525,29.2344012 5.16531525,24.6996324 C5.16531525,20.163585 8.84222398,16.4866763 13.3782713,16.4866763 C17.9130402,16.4866763 21.5899489,20.163585 21.5899489,24.6996324" fill="#FFFFFF"></path>
              <path d="M30.7887373,0.375833687 L30.7887373,3.22020615 C29.5362004,3.46529388 28.3717229,3.95670095 27.3494952,4.64454768 L27.3494952,4.64454768 L25.3389087,2.63273455 L22.7605547,5.21046634 L24.771757,7.22227946 C24.0845244,8.24388889 23.5931161,9.40836352 23.3480278,10.6602815 L23.3480278,10.6602815 L20.5030325,10.6602815 L20.5030325,14.3064234 L23.3480278,14.3064234 C23.5931161,15.5583414 24.0845244,16.7222002 24.771757,17.7444254 L24.771757,17.7444254 L22.7605547,19.7562386 L25.3389087,22.3333546 L27.3494952,20.322773 C28.3717229,21.010004 29.5362004,21.5007952 30.7887373,21.7464988 L30.7887373,21.7464988 L30.7887373,24.5902554 L34.4336566,24.5902554 L34.4336566,21.7464988 C35.6868093,21.5007952 36.850671,21.010004 37.8722829,20.322773 L37.8722829,20.322773 L39.8834852,22.3333546 L42.4612233,19.7562386 L40.450021,17.7444254 C41.1372537,16.7222002 41.6292777,15.5583414 41.8737503,14.3064234 L41.8737503,14.3064234 L44.7187456,14.3064234 L44.7187456,10.6602815 L41.8737503,10.6602815 C41.6292777,9.40836352 41.1372537,8.24388889 40.450021,7.22227946 L40.450021,7.22227946 L42.4612233,5.21046634 L39.8834852,2.63273455 L37.8722829,4.64454768 C36.850671,3.95670095 35.6868093,3.46529388 34.4336566,3.22020615 L34.4336566,3.22020615 L34.4336566,0.375833687 L30.7887373,0.375833687 Z M27.977611,12.4836604 C27.977611,9.92440252 30.0522407,7.84977796 32.6115048,7.84977796 L32.6115048,7.84977796 C35.1701532,7.84977796 37.2447829,9.92440252 37.2447829,12.4836604 L37.2447829,12.4836604 C37.2447829,15.0423024 35.1701532,17.1163112 32.6115048,17.1163112 L32.6115048,17.1163112 C30.0522407,17.1163112 27.977611,15.0423024 27.977611,12.4836604 L27.977611,12.4836604 Z" id="Fill-4" fill={url('#account-linearGradient-7')}></path>
              <path d="M13.3778878,51.7806028 C18.2054286,51.7806028 22.6532608,50.0675696 26.2087139,47.1904831 C25.1603603,40.7119803 19.8163135,35.7806028 13.3778878,35.7806028 C6.93946207,35.7806028 1.5954153,40.7119803 0.54706163,47.1904831 C4.10251475,50.0675696 8.55034698,51.7806028 13.3778878,51.7806028" fill="#FFFFFF"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
