import PropTypes from 'prop-types';
import React from 'react';
import {
  solutionCard,
  linkText,
  nonLinkText,
} from 'src/pages/home/components/CommonSolutions/styles.module.scss';
import {FlexGrid} from 'src/shared/components/FlexGrid';
import {Link} from 'src/shared/components/Link';
import {Accessibility} from 'src/svgs/home/Accessibility';
import BillingAndPayment from 'src/svgs/home/BillingAndPayment.svg';
import {StatusCenter} from 'src/svgs/home/StatusCenter';
import {AccountManagement} from 'src/svgs/home/AccountManagement';

export const CommonSolutions = ({solutions}) => (
  <FlexGrid columns={solutions.length < 4 ? solutions.length : 2}>
    {
      solutions.map(solution => {
        let icon;
        switch (solution.iconId) {
          case 'account':
            icon = <AccountManagement />;
            break;
          case 'accessibility':
            icon = <Accessibility />;
            break;
          case 'billing':
            icon = <BillingAndPayment />;
            break;
          case 'status':
            icon = <StatusCenter />;
            break;
          default:
            icon = null;
        }
        return (
          <Link key={solution.title} className={solutionCard} {...solution.callToAction}>
            {icon}
            <div>
              <h3 dangerouslySetInnerHTML={{__html: solution.title}} className={nonLinkText} />
              <p className={nonLinkText}>{solution.description}</p>
              <p className={linkText} aria-hidden="true">{solution.callToAction.text}</p>
            </div>
          </Link>
        );
      })
    }
  </FlexGrid>
);

CommonSolutions.propTypes = {
  solutions: PropTypes.array.isRequired,
};
