import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import {createSelector} from 'reselect';
import {itgProcess} from 'src/shared/itgProcess/selectors/itgProcess';
import {itgSteps} from './itgSteps';

export const currentStep = createSelector(
  itgProcess,
  itgSteps,
  (itgProcess, itgSteps) => {
    if (!isEmpty(itgSteps)) {
      return last(itgSteps);
    }
    return itgProcess || {};
  }
);
