import React from 'react';
import {createSelector} from 'reselect';
import {INFORMATIONAL} from 'src/shared/outages/constants';
import {
  currentOutage,
  outageMessage,
  outageType,
} from 'src/shared/outages/selectors';
import {statusMap} from 'src/shared/pagePaths';
import {resourceStatus} from 'src/shared/resourceStatus/selectors';
import Interruption from 'src/svgs/status/interruption.svg';
import {redWatermelon, yellowPumpkin} from '../style.module.scss';
import {hasOutageMessagesSegment} from 'src/shared/datacast/selectors';

export const outageSupportlet = createSelector(
  [resourceStatus, currentOutage, outageType, outageMessage, hasOutageMessagesSegment],
  ({complete, error}, currentOutage, outageType, outageMessage, hasOutageMessagesSegment) => ({
    ready: (complete.outages || complete.outageConsolidated) && complete.datacast,
    eligible: !(error.outages || error.outageConsolidated)
      && !error.datacast && currentOutage && !hasOutageMessagesSegment,
    color: outageType === INFORMATIONAL ? yellowPumpkin : redWatermelon,
    icon: <Interruption />,
    id: 'H&S_OUTAGE_REPORTED_TOAST',
    links: [{
      url: statusMap(),
      text: 'View Outage Map',
      target: '_self',
    }],
    main: outageMessage,
  })
);
