import {createSelector} from 'reselect';
import compact from 'lodash/compact';
import {createResourceSelector} from 'src/shared/utils/resource';

export const user = createResourceSelector('user');

export const userAuthGuid = createSelector(
  user,
  user => user.authGuid || ''
);

export const userDisconnected = createSelector(
  user,
  user => user.accountStatus === 'D'
);

export const userRole = createSelector(
  user,
  user => user.role || ''
);

export const mobileNumber = createSelector(
  user,
  user => user.mobileNumber || ''
);

export const userRoles = createSelector(
  userRole,
  userRole => compact(userRole.split(','))
);

export const accountNumber = createSelector(
  user,
  user => user.accountNumber || ''
);

export const x1Account = createSelector(
  user,
  user => !!user.x1Account
);

const clean = str => (str || '').trim().replace(/\s+/g, ' ');

export const serviceAddress = createSelector(
  user,
  user => {
    const {serviceAddress: {address, city, poBox, state, zipcode} = {}} = user;
    return {
      address: clean(address),
      city: clean(city),
      poBox: clean(poBox),
      state: clean(state),
      zipcode: clean(zipcode),
    };
  }
);

export const services = createSelector(
  user,
  user => user.services || {}
);

export const firstName = createSelector(
  user,
  user => user.firstName || ''
);

export const lastName = createSelector(
  user,
  user => user.lastName || ''
);

export const voiceStatus = createSelector(
  user,
  user => user.services?.cdvStatus || ''
);
