import {token} from 'src/shared/auth/selectors';
import {AUTH} from 'src/shared/auth/actions';
import {requiresAuthentication} from 'src/shared/utils/resourcePrequisites';
import {connectivityState} from 'src/shared/config/urls';
import {createFetchResourceAction} from 'src/shared/utils/resource';

export const LOAD_CONNECTIVITYSTATE_START = 'LOAD_CONNECTIVITYSTATE_START';
export const LOAD_CONNECTIVITYSTATE_COMPLETE = 'LOAD_CONNECTIVITYSTATE_COMPLETE';
export const LOAD_CONNECTIVITYSTATE_SUCCESS = 'LOAD_CONNECTIVITYSTATE_SUCCESS';
export const LOAD_CONNECTIVITYSTATE_ERROR = 'LOAD_CONNECTIVITYSTATE_ERROR';

export const loadConnectivityState = () => createFetchResourceAction({
  start: LOAD_CONNECTIVITYSTATE_START,
  success: LOAD_CONNECTIVITYSTATE_SUCCESS,
  error: LOAD_CONNECTIVITYSTATE_ERROR,
  complete: LOAD_CONNECTIVITYSTATE_COMPLETE,
  url: connectivityState,
  waitUntil: [AUTH],
  qualify: requiresAuthentication,
  token,
});
