import {createSelector} from 'reselect';
import {accountNumber} from 'src/shared/user/selectors';
import {combined} from 'src/shared/params/selectors';
import {customerGuid} from 'src/shared/auth/selectors';
import {isInHarness} from 'src/shared/harness/selectors';
import {
  HELP_AND_SUPPORT,
  MY_ACCOUNT_APP_IOS,
  MY_ACCOUNT_APP_ANDROID,
} from 'src/shared/channelTracking/constants/channel';

export const channelId = (isInHarness, {device}) => {
  if (isInHarness) {
    if (device === 'ios') {
      return MY_ACCOUNT_APP_IOS;
    }
    if (device === 'android') {
      return MY_ACCOUNT_APP_ANDROID;
    }
  }
  return HELP_AND_SUPPORT;
};

export const event = createSelector(
  [
    customerGuid,
    accountNumber,
    isInHarness,
    combined,
  ],
  (customerGuid, accountNumber, isInHarness, combined) => ({
    LogType: 'DSS_CustomerInteraction',
    action: {
      metadata: {
        targetChannel: channelId(isInHarness, combined),
      },
    },
    session: {
      channel: channelId(isInHarness, combined),
      customer: {
        accountNumber,
        custGuid: customerGuid,
      },
    },
  })
);
