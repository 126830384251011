import {AUTH} from 'src/shared/auth/actions';
import {token} from 'src/shared/auth/selectors';
import {user} from 'src/shared/config/urls';
import {anHashParam} from 'src/shared/extendedProfile/selectors';
import {createFetchResourceAction} from 'src/shared/utils/resource';
import {requiresAuthentication} from 'src/shared/utils/resourcePrequisites';

export const LOAD_USER_START = 'LOAD_USER_START';
export const LOAD_USER_COMPLETE = 'LOAD_USER_COMPLETE';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR';

export const booleanize = value => typeof value === 'string' ? value.toLowerCase() === 'true' : !!value;

export const loadUser = () => createFetchResourceAction({
  start: LOAD_USER_START,
  success: LOAD_USER_SUCCESS,
  error: LOAD_USER_ERROR,
  complete: LOAD_USER_COMPLETE,
  waitUntil: [AUTH],
  qualify: requiresAuthentication,
  url: state => user(state, anHashParam(state)),
  token,
  postProcess: json => ({
    ...json,
    inHome: booleanize(json.inHome),
    x1Account: booleanize(json.x1Account),
  }),
});

