import React from 'react';
import {createSelector} from 'reselect';
import {resourceStatus} from 'src/shared/resourceStatus/selectors';
import {completionDate, installationDate, isDelayed, step} from 'src/shared/dropBury/selectors';
import {lagoon, redWatermelon, yellowPumpkin} from '../style.module.scss';
import ManualWrench from 'src/svgs/supportlets/manual-wrench.svg';
import {ASSIGNED, CANCELLED, COMPLETE, LOCATED, LOCATING, SUBMITTED} from 'src/shared/dropBury/constants';

const TWO_WEEKS = 1000 * 60 * 60 * 24 * 14;

export const dropBurySupportlet = createSelector(
  [resourceStatus, step, isDelayed, installationDate, completionDate],
  ({complete}, step, isDelayed, installationDate, completionDate) => ({
    ready: complete.dropBury,
    eligible: [SUBMITTED, LOCATING, LOCATED, ASSIGNED, CANCELLED].includes(step)
      || step === COMPLETE && completionDate && completionDate.getTime() > Date.now() - TWO_WEEKS,
    color: (() => {
      if (isDelayed) {
        return yellowPumpkin;
      }
      if (step === CANCELLED) {
        return redWatermelon;
      }
      return lagoon;
    })(),
    icon: <ManualWrench />,
    id: `drop bury ${isDelayed ? 'delay' : (step || '').toLowerCase()}`,
    links: [{
      url: 'underground-cable-work/',
      text: 'View Details',
      target: '_self',
    }],
    main: (() => {
      if (isDelayed) {
        return 'There has been a delay to your scheduled underground cable installation.';
      }
      switch (step) {
        case SUBMITTED:
          return 'A ticket for an underground cable installation at your address has been approved.';
        case LOCATING:
          return 'Your local utility company is scheduled to visit your property for underground cable installation preparation.';
        case LOCATED:
          return <>Your property has been marked and we&rsquo;re scheduling your underground cable installation.</>;
        case ASSIGNED:
          return installationDate
            ? `A team is scheduled to install your new underground cable on ${installationDate}`
            : `A team is scheduled to install your new underground cable.`;
        case COMPLETE:
          return 'Your new underground cable line installation is complete!';
        case CANCELLED:
          return 'Your scheduled underground cable installation has been cancelled.';
        default:
          return '';
      }
    })(),
  })
);
