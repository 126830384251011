export const answerflowHarnessSegment = 'helpandsupport-answerflow-harness';
export const answerflowSegment = 'helpandsupport-answerflow';
export const channelTrackingSegment = 'helpandsupport-channelTrackingSegment';
export const enrollSmsEnabledSegment = 'helpandsupport-enrollsmsenabled';
export const flexRepairSegment = 'helpandsupport-flex-repair';
export const flexStatusSegment = 'helpandsupport-flex-status';
export const informationalOutageSegment = 'helpandsupport-informationaloutage';
export const ingressSegment = 'helpandsupport-ingress';
export const moveLikelyEnabledSegment = 'helpandsupport-movelikelyenabled';
export const moveLikelySegment = 'Datacast_Movers_Likely_30_top3deciles';
export const outageMapDisableEtrSegment = 'helpandsupport-outagemap-disable-etr';
export const outageMapSegment = 'helpandsupport-outagemap';
export const outageMessagesSegment = 'helpandsupport-outage-messages';
export const overrideOutageSegment = 'helpandsupport-overrideoutage';
export const proactiveIssueDetectionSegment = 'helpandsupport-proactive-issue-detection';
export const repairOneRestartSegment = 'helpandsupport-repair-one-restart';
export const reportOutageSegment = 'helpandsupport-reportoutage';
export const rtmEnabledSegment = 'helpandsupport-rtm-enabled';
export const speedTestSegment = 'helpandsupport-repair-speedtest';
export const ssmOutageMapSegment = 'helpandsupport-ssmoutagemap';
export const virtualHoldInterstitialsSegment = 'helpandsupport-virtualholdinterstitials';
export const watchTowerSegment = 'helpandsupport-watchtower';
