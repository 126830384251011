import {createSelector} from 'reselect';

export const hasHarnessParams = createSelector(
  state => state?.params?.combined?.device,
  device => !!device
);

export const isInHarness = createSelector(
  hasHarnessParams,
  state => state.inHarness,
  (hasHarnessParams, inHarness) => !!(hasHarnessParams && inHarness)
);
