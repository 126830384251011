const writer = storage => (key, value) =>
  storage.setItem(key, JSON.stringify(value ?? null));

const reader = storage => key => {
  const item = storage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }
  return null;
};

export const writeJSONToLocalStorage = writer(window.localStorage);
export const readJSONFromLocalStorage = reader(window.localStorage);
export const writeJSONToSessionStorage = writer(window.sessionStorage);
export const readJSONFromSessionStorage = reader(window.sessionStorage);
