import {connect} from 'react-redux';
import {UnconnectedSocialFooter} from 'src/shared/components/SocialFooter/UnconnectedSocialFooter';
import {header, links} from 'src/shared/components/SocialFooter/selectors';

const mapStateToProps = (state, ownProps) => ({
  header: ownProps.header || header(state),
  links: ownProps.links || links(state),
});

export const SocialFooter = connect(mapStateToProps)(UnconnectedSocialFooter);
