import {errorToJSON} from './errorToJSON';
import {waitUntil} from 'src/shared/events';
import {fetch, fetchWithToken} from 'src/shared/api';
import isFunction from 'lodash/isFunction';

export const createAction = (type, value) => value
  ? {type, value}
  : {type};

export const createSuccessAction = (type, resource) => resource !== undefined
  ? {type, value: {resource}}
  : {type};

export const createErrorAction = (type, error) => ({
  type,
  value: {error: errorToJSON(error)},
});

/**
 * @function createFetchResourceAction - Creates a request as well as validates it and does error handling
 * @param {Object} fetch - Arbitrary name, but holds the request params as well as pre/post request options
 * @param {string} fetch.complete - The Redux action to dispatch after the request is finished and processed.
 * @param {string} fetch.error - The Redux action to dispatch after the request is finished and returns an error.
 * @param {Object|function} fetch.fetchOptions - An object or function that takes in state and returns an object that can contain the headers, method, or body
 * @param {Object} fetch.fetchOptions.headers - An object that can contain the headers for the backend
 * @param {string} fetch.fetchOptions.method - The http method string
 * @param {Object} fetch.fetchOptions.body - The body to be sent in the http request (needs to be stringified)
 * @param {string} fetch.headerKey - Optional, if included, it would return header value instead of body
 * @param {function} [fetch.postProcess=(x => x)] - function that will run on the data returned from the backend i.e. res.json()
 * @param {function} fetch.qualify - function that takes in state as an argument and returns true/false based on some value that is or isn't in state
 * @param {string} fetch.start - The Redux action to dispatch before the request is made.
 * @param {string} fetch.success - The Redux action to dispatch after the request is made and a response.ok is received
 * @param {string|function} fetch.token - accessToken or function to get the accessToken
 * @param {string|function} fetch.url - Endpoint for the http request or function to build the url. See createUrlSelector
 * @param {string|function} fetch.waitUntil - Action string or function to fire before request is made
*/

export const createFetchResourceAction = ({
  complete,
  error,
  fetchOptions,
  headerKey,
  postProcess = (x => x),
  qualify,
  start,
  success,
  token,
  url,
  waitUntil: wait,
}) => async (dispatch, getState) => {
  const actionStartTime = window.performance.now();
  const timeoutAt = 25000;
  let requestStartTime;
  let requestEndTime;
  dispatch(createAction(start, fetchOptions));
  try {
    if (wait) {
      if (isFunction(wait)) {
        await wait(getState());
      } else {
        await waitUntil(wait);
      }
    }
    if (qualify) {
      await qualify(getState());
    }
    if (isFunction(url)) {
      url = await url(getState());
    }
    if (isFunction(fetchOptions)) {
      fetchOptions = await fetchOptions(getState());
    }
    let response;
    if (token) {
      if (isFunction(token)) {
        token = await token(getState());
      }
      requestStartTime = window.performance.now();
      response = await fetchWithToken(url, token, {timeoutAt, ...fetchOptions});
    } else {
      requestStartTime = window.performance.now();
      response = await fetch(url, {timeoutAt, ...fetchOptions});
    }
    requestEndTime = window.performance.now();
    if (response.ok) {
      const body = await response.text();
      let resource;
      if (headerKey) {
        for (const el of response.headers.entries()) {
          if (el[0] === headerKey) {
            resource = el[1];
          }
        }
      } else {
        try {
          resource = JSON.parse(body);
        } catch (error) {
          resource = body;
        }
      }
      resource = await postProcess(resource);
      dispatch(createSuccessAction(success, resource));
    } else {
      dispatch(createErrorAction(error, new Error(response.status)));
    }
  } catch (err) {
    dispatch(createErrorAction(error, err));
  } finally {
    dispatch(createAction(complete, {
      timings: {
        action: window.performance.now() - actionStartTime,
        request: requestEndTime - requestStartTime,
      },
    }));
  }
};

export const createResourceReducer = ({
  initialState = {},
  success,
}) => (state = initialState, action) => {
  switch (action.type) {
    case success:
      return action.value?.resource ?? state;
    default:
      return state;
  }
};

export const createResourceSelector = (resourceName, defaultValue = {}) =>
  state => state?.[resourceName] || defaultValue;
