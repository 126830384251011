exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".sectionHeading-77fd0{color:#191919;font:700 4rem/5.5rem XfinityStandard,Helvetica,Arial,sans-serif;letter-spacing:.02rem;font-size:3rem;text-align:center}@media(max-width: 530px){.sectionHeading-77fd0{font-size:3rem;line-height:4rem}}.carouselSection-3e094{margin-top:6.4rem}.popularLinksWrapper-9a168{margin:1.4rem 0}@media only screen and (max-width: 530px){.supportlets-0e9cd{margin-top:3rem}}", ""]);

// Exports
exports.locals = {
	"sectionHeading": "sectionHeading-77fd0",
	"carouselSection": "carouselSection-3e094",
	"popularLinksWrapper": "popularLinksWrapper-9a168",
	"supportlets": "supportlets-0e9cd"
};