import React from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {isInHarness} from 'src/shared/harness/selectors';
import {redirect} from 'src/shared/redirect/actions';
import {Link} from 'src/shared/components/Link';

export const HarnessLink = ({
  children,
  href,
  text,
  ...rest
}) => {
  const inHarness = useSelector(isInHarness);
  const dispatch = useDispatch();
  const onClick = () => dispatch(redirect(href));

  return inHarness
    ? <button onClick={onClick} {...rest}>{children || text}</button>
    : <Link href={href} text={text} {...rest}>{children}</Link>;
};

HarnessLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
};
