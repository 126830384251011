import {parameterize} from 'src/shared/utils/parameterize';

const conditionalParams = params => {
  const query = parameterize(params);
  return query ? `?${query}` : '';
};

const conditionalSegment = segment => segment ? `/${segment}` : '';

const createPathFunction = path => params => `${path}${conditionalParams(params)}`;

export const article = (articlePath, params) => {
  return `articles/${articlePath}${conditionalParams(params)}`;
};

export const category = (categoryPath, params) => {
  return `${categoryPath}${conditionalParams(params)}`;
};

export const contactUs = createPathFunction('contact-us');

export const deviceInfo = createPathFunction('devices');

export const dropBury = createPathFunction('underground-cable-work');

export const enrollSms = createPathFunction('enroll-sms');

export const error404 = createPathFunction('404');

export const error500 = createPathFunction('error');

export const home = createPathFunction('');

export const remotes = createPathFunction('remotes');

export const repairFlex = createPathFunction('repair/flex');

export const repairGuided = (cxpSessionId, docId, params) =>
  `repair/guided${conditionalSegment(cxpSessionId)}${conditionalSegment(docId)}${conditionalParams(params)}`;

export const repairInternet = createPathFunction('repair/internet');

export const repairTv = createPathFunction('repair/tv');

export const reportIssue = createPathFunction('report-issue');

export const scheduleCallback = createPathFunction('schedule-callback');

export const serviceCenterLocations = createPathFunction('service-center-locations');

export const status = createPathFunction('status');

export const statusMap = createPathFunction('status-map');

export const storeSelector = createPathFunction('store-selector');

export const videoBill = createPathFunction('video-bill');
