import React from 'react';
import {createSelector} from 'reselect';
import {
  firstTimeLogin,
  hasRecentSystemRefresh,
  isSikShippedAndPendingActivation,
  recentRefreshableCodes,
  x1Customer,
} from 'src/shared/personalization/selectors';
import {resourceStatus} from 'src/shared/resourceStatus/selectors';
import UpdateWifi from 'src/svgs/tool/update-wifi.svg';
import PairRemote from 'src/svgs/tool/pair-remote.svg';
import SelfInstall from 'src/svgs/tool/self-install.svg';
import SystemRefresh from 'src/svgs/tool/system-refresh.svg';
import Callback from 'src/svgs/tool/callback.svg';
import {yellowPumpkin} from '../style.module.scss';

export const setupWifiSupportlet = createSelector(
  [resourceStatus, firstTimeLogin, x1Customer],
  ({loaded, error}, firstTimeLogin, x1Customer) => ({
    ready: error.personalization || loaded.personalization,
    eligible: !error.personalization && firstTimeLogin && !x1Customer,
    color: yellowPumpkin,
    icon: <UpdateWifi />,
    id: 'H&S_SETUP_WIFI_TOAST',
    links: [{
      url: 'https://customer.xfinity.com/#/services/internet',
      text: 'Update WiFi Settings',
      target: '_blank',
    }],
    main: 'Time to setup your WiFi',
    sub: 'Update your SSID and Password in MyAccount.',
  })
);

export const setupRemoteSupportlet = createSelector(
  [resourceStatus, firstTimeLogin, x1Customer],
  ({loaded, error}, firstTimeLogin, x1Customer) => ({
    ready: error.personalization || loaded.personalization,
    eligible: !error.personalization && firstTimeLogin && x1Customer,
    color: yellowPumpkin,
    icon: <PairRemote />,
    id: 'H&S_SETUP_REMOTE_TOAST',
    links: [{
      url: 'https://www.xfinity.com/support/cable-tv/programming-your-xfinity-remote/',
      text: 'Remote Pairing Guide',
      target: '_blank',
    }],
    main: 'Pair your new voice remote to your TV and audio system',
    sub: 'The remote pairing guide helps configure your remote quickly.',
  })
);

export const setupSikSupportlet = createSelector(
  [resourceStatus, isSikShippedAndPendingActivation],
  ({loaded, error}, isSikShippedAndPendingActivation) => ({
    ready: error.personalization || loaded.personalization,
    eligible: !error.personalization && isSikShippedAndPendingActivation,
    color: yellowPumpkin,
    icon: <SelfInstall />,
    id: 'H&S_SETUP_SIK_TOAST',
    links: [{
      url: 'https://www.xfinity.com/support/internet/what-is-included-in-an-xfinity-internet-self-install-kit/',
      text: 'Internet Installation Guide',
      target: '_blank',
    }],
    main: 'Internet Self-Installation Instructions',
    sub: 'Learn how to self install your internet and activate your services.',
  })
);

export const systemRefreshSupportlet = createSelector(
  [resourceStatus, recentRefreshableCodes, hasRecentSystemRefresh],
  ({loaded, error}, codes, hasRecentSystemRefresh) => ({
    ready: loaded.personalization || error.personalization,
    eligible: !error.personalization && codes.length > 0 && !hasRecentSystemRefresh,
    color: yellowPumpkin,
    icon: <SystemRefresh />,
    id: 'H&S_OFFER_SYSTEM_REFRESH_TOAST',
    links: [{
      url: 'https://www.xfinity.com/support/repair/tv/',
      text: 'System Refresh',
      target: '_blank',
    }],
    main: `We see that you experienced an ${codes?.[0]?.xreErrorCode} error.`,
    sub: 'Try a system refresh to resolve that issue.',
  })
);

export const scheduleCallbackSupportlet = createSelector(
  [resourceStatus, recentRefreshableCodes, hasRecentSystemRefresh],
  ({loaded, error}, codes, hasRecentSystemRefresh) => ({
    ready: loaded.personalization || error.personalization,
    eligible: !error.personalization && codes.length > 0 && hasRecentSystemRefresh,
    color: yellowPumpkin,
    icon: <Callback />,
    id: 'H&S_OFFER_SCHEDULED_CALLBACK_TOAST',
    links: [{
      url: 'https://www.xfinity.com/support/schedule-callback/',
      text: 'Get a call',
      target: '_blank',
    }],
    main: 'Are you still having TV issues?',
    sub: 'If a system refresh did not resolve your issue, let us give you a call.',
  })
);
