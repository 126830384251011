import {AUTH} from 'src/shared/auth/actions';
import {token} from 'src/shared/auth/selectors';
import {createFetchResourceAction} from 'src/shared/utils/resource';
import {generateUUID} from 'src/shared/utils/generateUuid';
import {channelTracking as channelTrackingUrl} from 'src/shared/config/urls';
export const CREATE_CHANNEL_TRACKING_START = 'CREATE_CHANNEL_TRACKING_START';
export const CREATE_CHANNEL_TRACKING_SUCCESS = 'CREATE_CHANNEL_TRACKING_SUCCESS';
export const CREATE_CHANNEL_TRACKING_ERROR = 'CREATE_CHANNEL_TRACKING_ERROR';
export const CREATE_CHANNEL_TRACKING_COMPLETE = 'CREATE_CHANNEL_TRACKING_COMPLETE';

export const channelTracking = data => {
  data['TransactionId'] = generateUUID();
  return createFetchResourceAction({
    start: CREATE_CHANNEL_TRACKING_START,
    success: CREATE_CHANNEL_TRACKING_SUCCESS,
    error: CREATE_CHANNEL_TRACKING_ERROR,
    complete: CREATE_CHANNEL_TRACKING_COMPLETE,
    waitUntil: [AUTH],
    url: channelTrackingUrl,
    token,
    fetchOptions: {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {'Content-Type': 'application/json'},
    },
  });
};
