import {createSelector} from 'reselect';
import {content} from 'src/shared/content/selectors';

export const header = createSelector(
  content,
  content => content?.footer?.social?.content?.[0]?.header || ''
);

export const links = createSelector(
  content,
  content => content?.footer?.social?.content?.[0].links || []
);

export const socialFooterContent = createSelector(
  [header, links],
  (header, links) => ({
    header,
    links,
  })
);
