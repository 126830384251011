import isEmpty from 'lodash/isEmpty';
import minBy from 'lodash/minBy';
import toArray from 'lodash/toArray';
import React from 'react';
import {createSelector} from 'reselect';
import {WARNING} from 'src/shared/components/Notice/statuses';
import {
  hasAnswerflowHarnessSegment,
  hasAnswerflowSegment,
  segments,
} from 'src/shared/datacast/selectors';
import {isInHarness} from 'src/shared/harness/selectors';
import {repairGuided} from 'src/shared/pagePaths';
import {createResourceSelector} from 'src/shared/utils/resource';
import ExclamationIcon from 'src/svgs/status/exclamation.svg';
import {cxpRecommendation} from 'src/shared/cxpRecommendation/selectors/cxpRecommendation';
import {
  ITG1351_ALL_OUT,
  ITG1356_LOW_MOCA,
  ITG1391_DEGRADED_INTERNET,
  ITG1393_MODEM_OFFLINE,
  ITG1394_DEVICE_OFFLINE,
  ITG1457_ABANDONED_TRUCK_ROLL,
  ITG1505_BOOTFILE,
  ITG6009_GATEWAY_SPEED_TEST,
} from 'src/shared/itgRecommendations/constants/itg';

export const getAction = recommendation =>
  (recommendation?.actions || [])
    .find(action => action.actionType === 'ITG_1.0');

export const getItgId = recommendation =>
  (getAction(recommendation)?.actionIdentifier).split(',')[0];

export const itgRecommendations = createResourceSelector('itgRecommendations');

const presetCxpSessionId = state => state.presetCxpSessionId;

export const cxpSessionId = createSelector(
  [itgRecommendations, presetCxpSessionId],
  (itgRecommendations, presetCxpSessionId) => presetCxpSessionId || itgRecommendations.cxpSessionId
);

export const unfilteredRecommendations = createSelector(
  [itgRecommendations, cxpRecommendation],
  (itgRecommendations, cxpRecommendation) => !isEmpty(itgRecommendations) ? itgRecommendations : cxpRecommendation
);

export const recommendations = createSelector(
  [isInHarness, hasAnswerflowHarnessSegment, hasAnswerflowSegment, unfilteredRecommendations, segments],
  (isInHarness, hasAnswerflowHarnessSegment, hasAnswerflowSegment, unfilteredRecommendations, segments) => {
    if (isInHarness ? !hasAnswerflowHarnessSegment : !hasAnswerflowSegment) {
      return [];
    }

    return toArray(unfilteredRecommendations?.recommendations)
      .filter(recommendation => segments.has(`helpandsupport-answerflow-${isInHarness ? 'harness-' : '' }${getItgId(recommendation).toLowerCase()}`));
  }
);

export const hasRecommendation = createSelector(
  recommendations,
  recommendations => !isEmpty(recommendations)
);

export const priorityRecommendation = createSelector(
  recommendations,
  recommendations => minBy(recommendations, 'priority')
);

export const action = createSelector(
  priorityRecommendation,
  priorityRecommendation => getAction(priorityRecommendation)
);

const presetDocId = state => state.presetDocId;

export const docId = createSelector(
  [action, presetDocId],
  (action, presetDocId) => presetDocId || (action?.actionIdentifier || '').split(',')[0].toUpperCase()
);

const filterDescription = text => /\s*IGNORE\s*/.test(text) ? '' : text;

const itgAllowlist = [
  ITG1351_ALL_OUT,
  ITG1356_LOW_MOCA,
  ITG1391_DEGRADED_INTERNET,
  ITG1393_MODEM_OFFLINE,
  ITG1394_DEVICE_OFFLINE,
  ITG1457_ABANDONED_TRUCK_ROLL,
  ITG1505_BOOTFILE,
  ITG6009_GATEWAY_SPEED_TEST,
];

export const itgNotice = createSelector(
  [priorityRecommendation, cxpSessionId, docId],
  (recommendation, cxpSessionId, docId) => {
    if (!itgAllowlist.includes(docId)) {
      return;
    }
    return {
      status: WARNING,
      icon: <ExclamationIcon />,
      docId,
      instanceId: `convoy issue detected ${recommendation.recommendationsName} ${docId}`,
      headline: filterDescription(recommendation.shortDescription),
      message: filterDescription(recommendation.longDescription),
      link: {
        isButton: true,
        target: '_self',
        text: (() => {
          switch (docId) {
            case ITG1351_ALL_OUT:
            case ITG1391_DEGRADED_INTERNET:
            case ITG1393_MODEM_OFFLINE:
            case ITG1394_DEVICE_OFFLINE:
            case ITG6009_GATEWAY_SPEED_TEST:
              return 'Let’s Fix It';
            case ITG1356_LOW_MOCA:
            case ITG1457_ABANDONED_TRUCK_ROLL:
            case ITG1505_BOOTFILE:
              return 'Continue';
            default:
              return '';
          }
        })(),
        url: repairGuided(cxpSessionId, docId),
      },
    };
  }
);
