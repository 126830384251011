import {connect} from 'react-redux';
import {UnconnectedSlimNotice} from 'src/shared/components/SlimNotice/UnconnectedSlimNotice';
import {hasOutageMessagesSegment} from 'src/shared/datacast/selectors';
import {
  futureOutage,
  outageEnrollAllowed,
  outageHeadline,
  outageImpact,
  outageMessage,
  outageTypeConsolidated,
  outagePlanned,
} from 'src/shared/outages/selectors';

const mapStateToProps = state => ({
  futureOutage: futureOutage(state),
  hasOutageMessagesSegment: hasOutageMessagesSegment(state),
  outageEnrollAllowed: outageEnrollAllowed(state),
  outageHeadline: outageHeadline(state),
  outageImpact: outageImpact(state),
  outageMessage: outageMessage(state),
  outageTypeConsolidated: outageTypeConsolidated(state),
  outagePlanned: outagePlanned(state),
});

export const SlimNotice = connect(mapStateToProps)(UnconnectedSlimNotice);
