import {createResourceReducer} from 'src/shared/utils/resource';
import {
  LOAD_USER_COMPLETE,
  LOAD_USER_ERROR,
  LOAD_USER_START,
  LOAD_USER_SUCCESS,
} from './actions';

export const user = createResourceReducer({
  start: LOAD_USER_START,
  success: LOAD_USER_SUCCESS,
  error: LOAD_USER_ERROR,
  complete: LOAD_USER_COMPLETE,
});
