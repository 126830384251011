import React from 'react';
import PropTypes from 'prop-types';
import {loadingDots, light as lightStyle} from './style.module.scss';
import compact from 'lodash/compact';

export const LoadingDots = ({light, className}) => (
  <span className={compact([loadingDots, light && lightStyle, className]).join(' ')}>
    <span></span>
    <span></span>
    <span></span>
  </span>
);

LoadingDots.propTypes = {
  light: PropTypes.bool,
  className: PropTypes.string,
};
