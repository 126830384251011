const parsedCookie = document.cookie
  .split(';')
  .reduce((acc, item) => {
    const [key, value] = item.split('=');
    if (key) {
      acc[key.trim()] = value;
    }
    return acc;
  }, {});

export const cookie = () => parsedCookie;
