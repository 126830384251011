import {createSelector} from 'reselect';
import {services} from 'src/pages/repair/store/selectors/services';
import {lineOfBusiness} from 'src/pages/repair/store/selectors/lineOfBusiness';

export const devices = createSelector(
  services,
  lineOfBusiness,
  (services, lineOfBusiness) =>
    (services[lineOfBusiness]?.devices || [])
      .filter(({mac}) => !!mac)
);
