import React from 'react';
import PropTypes from 'prop-types';
import {socialFooter, socialMedia} from './style.module.scss';
import FacebookIcon from 'src/svgs/social/facebook.svg';
import ForumsIcon from 'src/svgs/social/forums.svg';
import RedditIcon from 'src/svgs/social/reddit.svg';
import XIcon from 'src/svgs/social/x.svg';
import {socialLink} from './propTypes';

const DisplayIcon = ({icon}) => {
  switch (icon) {
    case 'social:facebook':
      return <FacebookIcon />;
    case 'social:forums':
      return <ForumsIcon />;
    case 'social:reddit':
      return <RedditIcon />;
    case 'social:twitter':
      return <XIcon />;
    default:
      return null;
  }
};

DisplayIcon.propTypes = {
  icon: PropTypes.string,
};

const getTarget = behavior => behavior === 'default' ? '_self' : '_blank';
const getRel = behavior => behavior === 'default' ? undefined : 'noopener noreferrer';

export const UnconnectedSocialFooter = ({header, links}) => (
  <article className={socialFooter}>
    <section className={socialMedia}>
      <div>
        <h3>{header}</h3>
        {
          links.map(({behavior, iconId, text, url}) => (
            <a target={getTarget(behavior)} rel={getRel(behavior)} href={url} key={iconId}>
              <DisplayIcon icon={iconId} />
              {text}
            </a>
          ))
        }
      </div>
    </section>
  </article>
);

UnconnectedSocialFooter.propTypes = {
  links: PropTypes.arrayOf(socialLink).isRequired,
  header: PropTypes.string.isRequired,
};
