import {READ_DATACAST_SUCCESS} from './actions';

export const datacast = (datacast = [], action) => {
  switch (action.type) {
    case READ_DATACAST_SUCCESS:
      return action.value.segments;
    default:
      return datacast;
  }
};

