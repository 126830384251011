import {guidedSearchHistory} from 'src/shared/guidedSearch/selectors';
import {errorToJSON} from 'src/shared/utils/errorToJSON';
import escape from 'lodash/escape';
import {
  readJSONFromLocalStorage,
  writeJSONToLocalStorage,
} from 'src/shared/utils/storage';

export const GUIDED_SEARCH_HISTORY_STORAGE_KEY = 'xhs-guided-search-history';

export const READ_GUIDED_SEARCH_HISTORY_START = 'READ_GUIDED_SEARCH_HISTORY_START';
export const READ_GUIDED_SEARCH_HISTORY_SUCCESS = 'READ_GUIDED_SEARCH_HISTORY_SUCCESS';
export const READ_GUIDED_SEARCH_HISTORY_ERROR = 'READ_GUIDED_SEARCH_HISTORY_ERROR';
export const READ_GUIDED_SEARCH_HISTORY_COMPLETE = 'READ_GUIDED_SEARCH_HISTORY_COMPLETE';

export const readGuidedSearchHistoryStart = () => ({
  type: READ_GUIDED_SEARCH_HISTORY_START,
});

export const readGuidedSearchHistorySuccess = resource => ({
  type: READ_GUIDED_SEARCH_HISTORY_SUCCESS,
  value: {resource},
});

export const readGuidedSearchHistoryError = error => ({
  type: READ_GUIDED_SEARCH_HISTORY_ERROR,
  value: {error: errorToJSON(error)},
});

export const readGuidedSearchHistoryComplete = () => ({
  type: READ_GUIDED_SEARCH_HISTORY_COMPLETE,
});

export const readGuidedSearchHistory = () => dispatch => {
  dispatch(readGuidedSearchHistoryStart());
  try {
    const history = readJSONFromLocalStorage(GUIDED_SEARCH_HISTORY_STORAGE_KEY) || [];
    dispatch(readGuidedSearchHistorySuccess(history));
  } catch (error) {
    dispatch(readGuidedSearchHistoryError(error));
  } finally {
    dispatch(readGuidedSearchHistoryComplete());
  }
};

export const UPDATE_GUIDED_SEARCH_HISTORY_START = 'UPDATE_GUIDED_SEARCH_HISTORY_START';
export const UPDATE_GUIDED_SEARCH_HISTORY_SUCCESS = 'UPDATE_GUIDED_SEARCH_HISTORY_SUCCESS';
export const UPDATE_GUIDED_SEARCH_HISTORY_ERROR = 'UPDATE_GUIDED_SEARCH_HISTORY_ERROR';
export const UPDATE_GUIDED_SEARCH_HISTORY_COMPLETE = 'UPDATE_GUIDED_SEARCH_HISTORY_COMPLETE';

export const updateGuidedSearchHistoryStart = () => ({
  type: UPDATE_GUIDED_SEARCH_HISTORY_START,
});

export const updateGuidedSearchHistorySuccess = resource => ({
  type: UPDATE_GUIDED_SEARCH_HISTORY_SUCCESS,
  value: {resource},
});

export const updateGuidedSearchHistoryError = error => ({
  type: UPDATE_GUIDED_SEARCH_HISTORY_ERROR,
  value: {error: errorToJSON(error)},
});

export const updateGuidedSearchHistoryComplete = () => ({
  type: UPDATE_GUIDED_SEARCH_HISTORY_COMPLETE,
});

export const updateGuidedSearchHistory = history => dispatch => {
  dispatch(updateGuidedSearchHistoryStart());
  try {
    writeJSONToLocalStorage(GUIDED_SEARCH_HISTORY_STORAGE_KEY, history);
    dispatch(updateGuidedSearchHistorySuccess(history));
  } catch (error) {
    dispatch(updateGuidedSearchHistoryError(error));
  } finally {
    dispatch(updateGuidedSearchHistoryComplete());
  }
};

export const addGuidedSearchHistoryEntry = (value, maxResults) => (dispatch, getState) => {
  const val = escape(value.trim());
  if (!val) {
    return;
  }
  const history = guidedSearchHistory(getState());
  if (!history.find(entry => entry.title === val)) {
    dispatch(updateGuidedSearchHistory([
      {
        hint: '',
        href: '#',
        markup: val,
        target: '',
        title: val,
        type: 'history',
        remove: true,
      },
      ...history,
    ].slice(0, maxResults)));
  }
};

export const removeGuidedSearchHistoryEntry = value => (dispatch, getState) => {
  const val = value.trim();
  if (!val) {
    return;
  }
  const history = guidedSearchHistory(getState());
  dispatch(updateGuidedSearchHistory(history.filter(entry => entry.title !== val)));
};
