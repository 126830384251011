import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
  ball,
  container,
  expandArrow,
  expandText,
  slimNotice,
  slimNoticeBody,
  slimNoticeContainer,
  slimNoticeTrigger,
} from './style.module.scss';
import ExpandIcon from 'src/svgs/action/expand.svg';
import BellIcon from 'src/svgs/status/bell.svg';

const slimNoticeImpression = description => {
  return JSON.stringify({
    containerName: 'SlimNotice',
    component: [
      {
        componentInfo: {
          componentID: 'xhs-slim-notice',
          componentName: 'SlimNotice',
          description: description,
        },
      },
    ],
  });
};

export const UnconnectedSlimNotice = ({
  futureOutage,
  hasOutageMessagesSegment,
  outageEnrollAllowed,
  outageHeadline,
  outageImpact,
  outageMessage,
  outageTypeConsolidated,
  outagePlanned,
}) => {
  const ref = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const onToggle = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (hasOutageMessagesSegment && futureOutage) {
      const impressionData = new CustomEvent('c-tracking-log-dom', {
        bubbles: true,
        composed: true,
        detail: {
          eventMethod: 'component-Track',
          component: [{
            componentInfo: {
              componentID: 'xhs-slim-notice',
              componentName: 'SlimNotice',
              description: `Outage Message|impactStyle:${outageImpact}^outageType:${outageTypeConsolidated}^optInEnroll:${outageEnrollAllowed}^plannedState:${outagePlanned}`,
            },
          }],
        },
      });
      ref.current.dispatchEvent(impressionData);
    }
  }, [hasOutageMessagesSegment, futureOutage]);

  if (!hasOutageMessagesSegment || !futureOutage) {
    return null;
  }

  return (
    <div
      className={slimNotice}
      data-tracking-parent
      data-tracking={slimNoticeImpression(`Outage Message|impactStyle:${outageImpact}^outageType:${outageTypeConsolidated}^optInEnroll:${outageEnrollAllowed}^plannedState:${outagePlanned}`)}
      ref={ref}
    >
      <div className={slimNoticeContainer}>
        <BellIcon className={ball} />
        {outageHeadline}
        <button
          className={slimNoticeTrigger}
          aria-label={expanded ? 'Hide Details' : 'View Details'}
          aria-expanded={expanded}
          onClick={onToggle}
        >
          <p className={expandText}>{expanded ? 'Hide Details' : 'View Details'}</p>
          <ExpandIcon className={expandArrow} />
        </button>
      </div>
      <div className={`${slimNoticeBody} ${container}`} aria-hidden={!expanded}>
        {outageMessage}
      </div>
    </div>
  );
};

UnconnectedSlimNotice.propTypes = {
  futureOutage: PropTypes.bool,
  hasOutageMessagesSegment: PropTypes.bool,
  outageEnrollAllowed: PropTypes.string,
  outageHeadline: PropTypes.string,
  outageImpact: PropTypes.string,
  outageMessage: PropTypes.string,
  outageTypeConsolidated: PropTypes.string,
  outagePlanned: PropTypes.bool,
};
