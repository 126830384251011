import {createSelector} from 'reselect';
import {params} from 'src/shared/params/selectors';
import {
  COMPLETE,
  DELAY,
} from './constants';

export const dropBury = state => state.dropBury;

export const events = createSelector(
  dropBury,
  dropBury => (dropBury?.events || [])
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
);

export const interactions = createSelector(
  events,
  events => events.map(event => event?.data?.interaction)
);

export const step = createSelector(
  [interactions, params],
  (interactions, params) => {
    if (params.combined.debugDropburyStep) {
      return params.combined.debugDropburyStep;
    }
    const lastInteractionWithoutDelay = interactions.filter(interaction => interaction.status !== DELAY)[0];
    if (lastInteractionWithoutDelay) {
      return lastInteractionWithoutDelay.status;
    }
  }
);

export const isDelayed = createSelector(
  [interactions, params],
  (interactions, params) => {
    if ('debugDropburyDelay' in params.combined) {
      return params.combined.debugDropburyDelay !== 'false';
    }
    return interactions.length > 0 && interactions[0].status === DELAY;
  }
);

export const installationDate = createSelector(
  () => ''
);

export const completionDate = createSelector(
  [step, events],
  (step, events) => {
    if (step === COMPLETE) {
      return new Date(events[0].timestamp);
    }
  }
);

export const delayReason = createSelector(
  () => ''
);

export const confirmationNumber = createSelector(
  interactions,
  interactions => {
    const interaction = interactions.find(i => i.interaction_id);
    if (interaction) {
      return interaction.interaction_id;
    }
    return '';
  }
);

const labels = {
  callFirstRequested: 'Call First Required',
  crossesNeighborProperty: 'Crosses Neighbor Property',
  dogInYard: 'Dog In Yard',
  drivewayBores: 'Driveway Bores',
  dropPathObstacle: 'Drop Path Obstacle',
  electricDogFence: 'Electric Dog Fence',
  homeDuringBury: 'Home During Bury',
  landscaping: 'Landscaping',
  lockedGateOrAccessCode: 'Locked Gate Or Access Code',
  premiseAccessLimitations: 'Premise Access Limitations',
  privateElectric: 'Private Electric',
  privateGasLine: 'Private Gas Line',
  privateLighting: 'Private Lighting',
  roadBores: 'Road Bores',
  sidewalkBores: 'Sidewalk Bores',
  sprinklerSystem: 'Sprinkler System',
  tempLine: 'Temporary Line',
};

export const customerInfo = createSelector(
  events,
  events => {
    const findProp = propName => events.find(event => event?.data?.[propName])?.data?.[propName];
    const info = {
      ...findProp('additionalInfo'),
      ...findProp('dropPathObstacles'),
      ...findProp('premiseAccessLimitations'),
    };
    return Object.entries(info)
      .filter(([key]) => labels[key])
      .map(([key, value]) => ({name: `${labels[key]}:`, value: value.trim()}));
  }
);
