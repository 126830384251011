import merge from 'lodash/merge';
import isFunction from 'lodash/isFunction';
import {AUTH} from 'src/shared/auth/actions';
import {LOAD_USER_COMPLETE} from 'src/shared/user/actions';
import {waitUntil} from 'src/shared/events';
import {event} from 'src/shared/channelTracking/selectors';
import {READ_DATACAST_COMPLETE} from 'src/shared/datacast/actions';
import {hasChannelTrackingSegment} from 'src/shared/datacast/selectors';
import {channelTracking} from 'src/shared/channelTracking/actions/channelTrackingAction';

export const logTracking = data => async (dispatch, getState) => {
  await waitUntil(AUTH, LOAD_USER_COMPLETE, READ_DATACAST_COMPLETE);
  if (isFunction(data)) {
    data = data(getState());
  }
  if (data && hasChannelTrackingSegment(getState())) {
    dispatch(channelTracking(merge({}, event(getState()), data, {eventTimestamp: Date.now().toString()})));
  }
};
