import {createResourceReducer} from 'src/shared/utils/resource';
import {
  DISMISS_SUPPORTLET,
  DISPLAY_SUPPORTLET,
  READ_PERSONALIZATION_COMPLETE,
  READ_PERSONALIZATION_ERROR,
  READ_PERSONALIZATION_HISTORY_SUCCESS,
  READ_PERSONALIZATION_START,
  READ_PERSONALIZATION_SUCCESS,
  UPDATE_PERSONALIZATION_HISTORY_SUCCESS,
} from 'src/shared/personalization/actions';

export const personalization = createResourceReducer({
  start: READ_PERSONALIZATION_START,
  success: READ_PERSONALIZATION_SUCCESS,
  error: READ_PERSONALIZATION_ERROR,
  complete: READ_PERSONALIZATION_COMPLETE,
});

export const personalizationHistory = (personalizationHistory = {messageHistory: {}}, action) => {
  switch (action.type) {
    case DISPLAY_SUPPORTLET:
    case DISMISS_SUPPORTLET:
      const actionProperty = action.type === DISPLAY_SUPPORTLET ? 'displayed' : 'dismissed';
      const {supportlet: {id}, timestamp: modified} = action.value;
      const historyEntry = personalizationHistory.messageHistory[id];
      return {
        ...personalizationHistory,
        messageHistory: {
          ...personalizationHistory.messageHistory,
          [id]: {...historyEntry, id, modified, [actionProperty]: true},
        },
      };
    case READ_PERSONALIZATION_HISTORY_SUCCESS:
    case UPDATE_PERSONALIZATION_HISTORY_SUCCESS:
      return action.value.personalizationHistory;
    default:
      return personalizationHistory;
  }
};

export const supportlets = (state = {dismissed: {}, displayed: {}}, action) => {
  switch (action.type) {
    case DISPLAY_SUPPORTLET:
      return Object.assign({}, state, {
        displayed: Object.assign({}, state.displayed, {
          [action.value.supportlet.id]: action.value.timestamp,
        }),
      });
    case DISMISS_SUPPORTLET:
      return Object.assign({}, state, {
        dismissed: Object.assign({}, state.dismissed, {
          [action.value.supportlet.id]: action.value.timestamp,
        }),
      });
    default:
      return state;
  }
};
