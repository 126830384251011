import pickBy from 'lodash/pickBy';
import {createSelector} from 'reselect';
import {
  answerflowHarnessSegment,
  answerflowSegment,
  channelTrackingSegment,
  enrollSmsEnabledSegment,
  flexRepairSegment,
  flexStatusSegment,
  informationalOutageSegment,
  moveLikelyEnabledSegment,
  moveLikelySegment,
  outageMapDisableEtrSegment,
  outageMapSegment,
  outageMessagesSegment,
  overrideOutageSegment,
  proactiveIssueDetectionSegment,
  repairOneRestartSegment,
  reportOutageSegment,
  rtmEnabledSegment,
  speedTestSegment,
  ssmOutageMapSegment,
  virtualHoldInterstitialsSegment,
  watchTowerSegment,
} from 'src/shared/datacast/constants';
import {debugDatacastSegmentsParam} from 'src/shared/params/selectors';

export const datacast = state => state.datacast;

export const segments = createSelector(
  [datacast, debugDatacastSegmentsParam],
  (datacast, debugDatacastSegmentsParam) => {
    const datacastSet = new Set();
    datacast.forEach(segment => datacastSet.add(segment.name));
    Object.keys(pickBy(debugDatacastSegmentsParam, x => x)).forEach(segment => datacastSet.add(segment));
    Object.keys(pickBy(debugDatacastSegmentsParam, x => !x)).forEach(segment => datacastSet.delete(segment));
    return datacastSet;
  }
);

const createSegmentSelector = segment => createSelector(
  segments,
  segments => segments.has(segment)
);

export const hasAnswerflowHarnessSegment = createSegmentSelector(answerflowHarnessSegment);
export const hasAnswerflowSegment = createSegmentSelector(answerflowSegment);
export const hasChannelTrackingSegment = createSegmentSelector(channelTrackingSegment);
export const hasEnrollSmsEnabledSegment = createSegmentSelector(enrollSmsEnabledSegment);
export const hasFlexRepairSegment = createSegmentSelector(flexRepairSegment);
export const hasFlexStatusSegment = createSegmentSelector(flexStatusSegment);
export const hasInformationalOutageSegment = createSegmentSelector(informationalOutageSegment);
export const hasMoveLikelyEnabledSegment = createSegmentSelector(moveLikelyEnabledSegment);
export const hasMoveLikelySegment = createSegmentSelector(moveLikelySegment);
export const hasOutageMapDisableEtrSegment = createSegmentSelector(outageMapDisableEtrSegment);
export const hasOutageMapSegment = createSegmentSelector(outageMapSegment);
export const hasOutageMessagesSegment = createSegmentSelector(outageMessagesSegment);
export const hasOverrideOutageSegment = createSegmentSelector(overrideOutageSegment);
export const hasProactiveIssueDetectionSegment = createSegmentSelector(proactiveIssueDetectionSegment);
export const hasRepairOneRestartSegment = createSegmentSelector(repairOneRestartSegment);
export const hasReportOutageSegment = createSegmentSelector(reportOutageSegment);
export const hasRtmEnabledSegment = createSegmentSelector(rtmEnabledSegment);
export const hasSpeedTestSegment = createSegmentSelector(speedTestSegment);
export const hasSsmOutageMapSegment = createSegmentSelector(ssmOutageMapSegment);
export const hasVirtualHoldInterstitialsSegment = createSegmentSelector(virtualHoldInterstitialsSegment);
export const hasWatchTowerSegment = createSegmentSelector(watchTowerSegment);
