import PropTypes from 'prop-types';
import React from 'react';
import ArticleIcon from 'src/svgs/guidedsearch/article.svg';
import CollectionIcon from 'src/svgs/guidedsearch/collection.svg';
import CommunityIcon from 'src/svgs/guidedsearch/community.svg';
import ContactIcon from 'src/svgs/guidedsearch/contact.svg';
import EditIcon from 'src/svgs/guidedsearch/edit.svg';
import ExternalIcon from 'src/svgs/guidedsearch/external.svg';
import MailIcon from 'src/svgs/guidedsearch/mail.svg';
import SearchIcon from 'src/svgs/guidedsearch/search.svg';
import StatusCenterIcon from 'src/svgs/guidedsearch/statusCenter.svg';
import ToolIcon from 'src/svgs/guidedsearch/tool.svg';
import VideoIcon from 'src/svgs/guidedsearch/video.svg';

export const SearchResultIcon = ({
  className,
  iconId,
}) => {
  switch (iconId) {
    case 'guidedsearch:article':
      return <ArticleIcon className={className} />;
    case 'guidedsearch:collection':
      return <CollectionIcon className={className} />;
    case 'guidedsearch:community':
      return <CommunityIcon className={className} />;
    case 'guidedsearch:contact':
      return <ContactIcon className={className} />;
    case 'guidedsearch:edit':
      return <EditIcon className={className} />;
    case 'guidedsearch:external':
      return <ExternalIcon className={className} />;
    case 'guidedsearch:search':
      return <SearchIcon className={className} />;
    case 'guidedsearch:statusCenter':
      return <StatusCenterIcon className={className} />;
    case 'guidedsearch:tool':
      return <ToolIcon className={className} />;
    case 'guidedsearch:video':
      return <VideoIcon className={className} />;
    case 'guidedsearch:mail':
      return <MailIcon className={className} />;
    default:
      return <span className={className} />;
  }
};

SearchResultIcon.propTypes = {
  className: PropTypes.string,
  iconId: PropTypes.string,
};
