import {waitForPeriodOfInactivity} from 'src/shared/auth/waitForPeriodOfInactivity';
import {
  activityTimeout,
  oauthOptions,
} from 'src/shared/config/selectors';
import {TRACKING_STARTED} from 'src/shared/dataLayer/actionTypes';
import {waitUntil} from 'src/shared/events';
import {
  READ_EXTENDED_PROFILE_COMPLETE,
  readExtendedProfile,
} from 'src/shared/extendedProfile/actions';
import {isInHarness} from 'src/shared/harness/selectors';
import {params} from 'src/shared/params/selectors';
import {configurePolaris} from 'src/shared/utils/configurePolaris';
import {signIn, signOut} from 'src/shared/config/urls';

const IN_HOME_ACR_CLAIM = 'urn:oasis:names:tc:SAML:2.0:ac:classes:InternetProtocol';

export const AUTH = 'AUTH';
export const auth = oauthInfo => ({
  type: AUTH,
  value: {
    oauthInfo,
  },
});

const updateVerifiedBy = verifier => {
  const setVerifiedBy = () => {
    document.querySelector('xc-header').setAttribute('verified-by', verifier);
  };
  const setVerifiedByEvent = () => {
    setVerifiedBy();
    document.removeEventListener('XCHeaderLoaded', setVerifiedByEvent);
  };
  try {
    setVerifiedBy();
  } catch (e) {
    document.addEventListener('XCHeaderLoaded', setVerifiedByEvent);
  }
};

const dispatchEvent = async (eventName, detail) => {
  await waitUntil(TRACKING_STARTED);
  window.dispatchEvent(new CustomEvent(eventName, {detail, bubbles: true, composed: true}));
};

export const authenticate = ({requiresAuthentication} = {}) => (dispatch, getState) => {
  const {Oauth} = window;
  const {hash, combined, query} = params(getState());
  const hasPresetToken = !!(hash.access_token && hash.expires_in);
  const idToken = hash.id_token || query.context_id;
  const md5AccountNumber = combined.an_hash;
  const configOauthOptions = oauthOptions(getState());
  configurePolaris(signIn(getState()), signOut(getState()));

  if (hasPresetToken) {
    Object.assign(configOauthOptions, {redirect: true});
  } else if (idToken) {
    Object.assign(configOauthOptions, {
      allowIframeLoginWithoutPromptNone: true,
      useIframe: false,
      redirect: true,
      authOpts: {
        id_token_hint: idToken,
        redirect_uri: configOauthOptions.authOpts.redirect_uri,
      },
    });
  } else if (requiresAuthentication) {
    Object.assign(configOauthOptions, {redirect: true});
  }

  const oauthSuccess = async oauthInfo => {
    const lightAuth = !Object.keys(oauthInfo.tokenInfo).length;
    const inHarness = isInHarness(getState());
    const user = {
      isAuthenticated: true,
      inHome: oauthInfo.tokenInfo.acrClaimValue === IN_HOME_ACR_CLAIM,
      lightAuth,
      secure: oauthInfo.secure,
      token: oauthInfo.accessToken,
      customerGuid: oauthInfo?.tokenInfo?.decodedToken?.sub,
    };

    if (lightAuth) {
      updateVerifiedBy('Mobile');
    }

    if (md5AccountNumber) {
      dispatch(readExtendedProfile(user.token));
      await waitUntil(READ_EXTENDED_PROFILE_COMPLETE);
    }

    if (requiresAuthentication) {
      waitForPeriodOfInactivity(activityTimeout(getState())).then(() => {
        Oauth.logout({...configOauthOptions, logoutUrl: signOut(getState())});
      });
    }

    dispatchEvent('log', {event: 'help-user-authenticated', currentPage: window.location.pathname, lightAuth, inHarness, trackingEnabled: !!window._satellite});
    dispatch(auth(user));
  };

  const oauthError = ({error}) => {
    const inHarness = isInHarness(getState());
    const user = {isAuthenticated: false};

    // Filtering out default unauthenticated cima errors customized by oauthjs
    if (error !== 'login required' && error !== 'redirecting') {
      dispatchEvent('log', {event: 'error', data: error});
    }
    dispatchEvent('log', {event: 'help-user-unauthenticated', currentPage: window.location.pathname, inHarness, trackingEnabled: !!window._satellite});
    dispatch(auth(user));
    return user;
  };
  return Oauth.login(configOauthOptions)
    .then(oauthSuccess)
    .catch(oauthError);
};
