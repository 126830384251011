import flattenDeep from 'lodash/flattenDeep';

const eventsPromise = {};
const eventsResolver = {};
const nextEventsResolver = {};

const getPromise = eventType => {
  eventsPromise[eventType] = eventsPromise[eventType] || new Promise(resolve => {
    eventsResolver[eventType] = resolve;
  });
  return eventsPromise[eventType];
};

export const waitUntil = (...eventTypes) =>
  Promise.all(flattenDeep(eventTypes).map(getPromise));

export const waitUntilOneOf = (...eventTypes) =>
  Promise.race(flattenDeep(eventTypes).map(getPromise));

export const waitForNext = (...eventTypes) =>
  Promise.all(flattenDeep(eventTypes).map(eventType => {
    if (!nextEventsResolver[eventType]) {
      nextEventsResolver[eventType] = [];
    }
    return new Promise(resolve => nextEventsResolver[eventType].push(resolve));
  }));

export const waitForNextOneOf = (...eventTypes) =>
  Promise.race(flattenDeep(eventTypes).map(event => waitForNext(event)))
    .then(resolved => resolved.filter(x => x)[0]);

export const resolve = (...events) =>
  flattenDeep(events).forEach(event => {
    getPromise(event.type);

    eventsResolver[event.type](event);

    if (nextEventsResolver[event.type]) {
      while (nextEventsResolver[event.type].length) {
        nextEventsResolver[event.type].shift()(event);
      }
    }
  });
