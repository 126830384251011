import {AUTH} from 'src/shared/auth/actions';
import {createFetchResourceAction} from 'src/shared/utils/resource';
import {
  outages,
  outageConsolidated,
  unauthOutageConsolidated,
  outageHistory,
} from 'src/shared/config/urls';
import {token} from 'src/shared/auth/selectors';
import {anHashParam} from 'src/shared/extendedProfile/selectors';
import {sessionToken} from 'src/pages/statusMap/store/selectors/session';
import {writeJSONToLocalStorage} from '../utils/storage';
import {OUTAGE_TARGETING_KEY} from './constants';
import {errorToJSON} from 'src/shared/utils/errorToJSON';
import {waitUntil} from 'src/shared/events';
import {LOAD_USER_SUCCESS} from 'src/shared/user/actions';
import {outageHistoryProductCheck, outageList} from './selectors';

export const LOAD_OUTAGES_START = 'LOAD_OUTAGES_START';
export const LOAD_OUTAGES_COMPLETE = 'LOAD_OUTAGES_COMPLETE';
export const LOAD_OUTAGES_SUCCESS = 'LOAD_OUTAGES_SUCCESS';
export const LOAD_OUTAGES_ERROR = 'LOAD_OUTAGES_ERROR';

export const LOAD_OUTAGE_CONSOLIDATED_START = 'LOAD_OUTAGE_CONSOLIDATED_START';
export const LOAD_OUTAGE_CONSOLIDATED_COMPLETE = 'LOAD_OUTAGE_CONSOLIDATED_COMPLETE';
export const LOAD_OUTAGE_CONSOLIDATED_SUCCESS = 'LOAD_OUTAGE_CONSOLIDATED_SUCCESS';
export const LOAD_OUTAGE_CONSOLIDATED_ERROR = 'LOAD_OUTAGE_CONSOLIDATED_ERROR';

export const LOAD_OUTAGE_HISTORY_START = 'LOAD_OUTAGE_HISTORY_START';
export const LOAD_OUTAGE_HISTORY_COMPLETE = 'LOAD_OUTAGE_HISTORY_COMPLETE';
export const LOAD_OUTAGE_HISTORY_SUCCESS = 'LOAD_OUTAGE_HISTORY_SUCCESS';
export const LOAD_OUTAGE_HISTORY_ERROR = 'LOAD_OUTAGE_HISTORY_ERROR';

export const LOAD_COMBINED_OUTAGE_OBJ_START = 'LOAD_COMBINED_OUTAGE_OBJ_START';
export const LOAD_COMBINED_OUTAGE_OBJ_COMPLETE = 'LOAD_COMBINED_OUTAGE_OBJ_COMPLETE';
export const LOAD_COMBINED_OUTAGE_OBJ_SUCCESS = 'LOAD_COMBINED_OUTAGE_OBJ_SUCCESS';
export const LOAD_COMBINED_OUTAGE_OBJ_ERROR = 'LOAD_COMBINED_OUTAGE_OBJ_ERROR';

export const loadOutages = () => createFetchResourceAction({
  start: LOAD_OUTAGES_START,
  success: LOAD_OUTAGES_SUCCESS,
  error: LOAD_OUTAGES_ERROR,
  complete: LOAD_OUTAGES_COMPLETE,
  waitUntil: [AUTH],
  url: state => outages(state, anHashParam(state)),
  token,
});

export const loadOutageConsolidated = address => createFetchResourceAction({
  start: LOAD_OUTAGE_CONSOLIDATED_START,
  success: LOAD_OUTAGE_CONSOLIDATED_SUCCESS,
  error: LOAD_OUTAGE_CONSOLIDATED_ERROR,
  complete: LOAD_OUTAGE_CONSOLIDATED_COMPLETE,
  waitUntil: [AUTH],
  ...(address ? {
    url: state => unauthOutageConsolidated(state, address),
    token: sessionToken,
  } : {
    url: state => outageConsolidated(state, anHashParam(state)),
    token,
  }),
});

export const loadOutageHistory = () => createFetchResourceAction({
  start: LOAD_OUTAGE_HISTORY_START,
  success: LOAD_OUTAGE_HISTORY_SUCCESS,
  error: LOAD_OUTAGE_HISTORY_ERROR,
  complete: LOAD_OUTAGE_HISTORY_COMPLETE,
  waitUntil: [AUTH],
  url: state => outageHistory(state, anHashParam(state)),
  token,
});

export const loadCombinedCurrentOutageAndOutageHistoryStart = () => ({
  type: LOAD_COMBINED_OUTAGE_OBJ_START,
});

export const loadCombinedCurrentOutageAndOutageHistorySuccess = () => ({
  type: LOAD_COMBINED_OUTAGE_OBJ_SUCCESS,
});

export const loadCombinedCurrentOutageAndOutageHistoryError = error => ({
  type: LOAD_COMBINED_OUTAGE_OBJ_ERROR,
  value: {error: errorToJSON(error)},
});

export const loadCombinedCurrentOutageAndOutageHistoryComplete = () => ({
  type: LOAD_COMBINED_OUTAGE_OBJ_COMPLETE,
});

export const loadCombinedCurrentOutageAndOutageHistory = () => async (dispatch, getState) => {
  dispatch(loadCombinedCurrentOutageAndOutageHistoryStart());
  await waitUntil([
    AUTH,
    LOAD_USER_SUCCESS,
  ]);

  const state = getState();
  const getLastOutageDate = (lob, state) => outageHistoryProductCheck(lob)(state)?.actualEnd?.date || null;

  const outages = [
    {
      service: 'HSD',
      currentOutage: outageList(state)?.internetOutage,
      dateOfLastOutage: getLastOutageDate('hsd', state),
    },
    {
      service: 'Video',
      currentOutage: outageList(state)?.tvOutage,
      dateOfLastOutage: getLastOutageDate('video', state),
    },
    {
      service: 'Telephony',
      currentOutage: outageList(state)?.voiceOutage,
      dateOfLastOutage: getLastOutageDate('telephony', state),
    },
  ];

  const data = {
    timestamp: Date.now(),
    authGuid: getState()?.user?.authGuid,
    outages,
  };
  try {
    writeJSONToLocalStorage(OUTAGE_TARGETING_KEY, data);
    dispatch(loadCombinedCurrentOutageAndOutageHistorySuccess());
  } catch (error) {
    dispatch(loadCombinedCurrentOutageAndOutageHistoryError(error));
  } finally {
    dispatch(loadCombinedCurrentOutageAndOutageHistoryComplete());
  }
};

