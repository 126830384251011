import React from 'react';
import {url} from 'src/shared/utils/url';

export const Voice = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props} role="img" aria-hidden="true">
    <g transform="translate(-1.5 15)">
      <defs>
        <linearGradient
          id="voice-dark-gradient-1"
          x1="-17.807"
          x2="-18.276"
          y1="106.115"
          y2="106.583"
          gradientTransform="matrix(41.5698 0 0 -41.5698 810.035 4477.2)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#364251"></stop>
          <stop offset="1" stopColor="#44576b"></stop>
        </linearGradient>
        <linearGradient
          id="voice-dark-gradient-2"
          x1="-17.927"
          x2="-18.159"
          y1="106.555"
          y2="107.205"
          gradientTransform="matrix(20.1802 0 0 -56.4593 408.924 6063.697)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#364251"></stop>
          <stop offset="1" stopColor="#44576b"></stop>
        </linearGradient>
        <linearGradient
          id="voice-dark-gradient-3"
          x1="-18.527"
          x2="-17.528"
          y1="106.452"
          y2="106.452"
          gradientTransform="matrix(20.2558 0 0 -36.7326 410.36 3932.75)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#364251"></stop>
          <stop offset="1" stopColor="#44576b"></stop>
        </linearGradient>
      </defs>
      <path fill={url('#voice-dark-gradient-1')} d="M66.2 67.6H35c-2.9 0-5.2-2.3-5.2-5.2V31.3c0-2.9 2.3-5.2 5.2-5.2h31.1c2.9 0 5.2 2.3 5.2 5.2v31.1c.1 2.9-2.3 5.2-5.1 5.2z"></path>
      <path fill="#51677a" fillOpacity=".3" d="M71.4 62.4v-5.6L40.6 26.1H35c-2.9 0-5.2 2.3-5.2 5.2v31.1c0 2.9 2.3 5.2 5.2 5.2h31.1c2.9 0 5.3-2.4 5.3-5.2z"></path>
      <path fill="#51677a" d="M39.6 62.1c-2.9 0-5.2-2.3-5.2-5.2V8.6c0-2.9 2.3-5.2 5.2-5.2h12c2.9 0 5.2 2.3 5.2 5.2v48.2c0 2.9-2.3 5.2-5.2 5.2l-12 .1z"></path>
      <path fill={url('#voice-dark-gradient-2')} d="M51.2 60.6h-12c-2.3 0-4.1-1.8-4.1-4.1V8.2c0-2.3 1.8-4.1 4.1-4.1h12c2.3 0 4.1 1.8 4.1 4.1v48.2c0 2.4-1.8 4.2-4.1 4.2z"></path>
      <path fill={url('#voice-dark-gradient-3')} d="M51.2 4.1h-12c-2.3 0-4.1 1.8-4.1 4.1v12.4l20.3 20.3V8.2c-.1-2.2-1.9-4.1-4.2-4.1z"></path>
      <path fill="#29343f" d="M37.4 16.2h15.8v10.2H37.4z"></path>
      <circle fill="#51677a" cx="45.2" cy="9" r="1.8"></circle>
      <path fill="#51677a" d="M41.2 41.4h-3.3c-.3 0-.5-.2-.5-.5v-1.4c0-.3.2-.5.5-.5h3.3c.3 0 .5.2.5.5v1.4c.1.3-.2.5-.5.5zM47 41.4h-3.3c-.3 0-.5-.2-.5-.5v-1.4c0-.3.2-.5.5-.5H47c.3 0 .5.2.5.5v1.4c.1.3-.2.5-.5.5zM52.6 41.4h-3.3c-.3 0-.5-.2-.5-.5v-1.4c0-.3.2-.5.5-.5h3.3c.3 0 .5.2.5.5v1.4c.1.3-.2.5-.5.5zM41.2 45.4h-3.3c-.3 0-.5-.2-.5-.5v-1.4c0-.3.2-.5.5-.5h3.3c.3 0 .5.2.5.5v1.4c.1.3-.2.5-.5.5zM47 45.4h-3.3c-.3 0-.5-.2-.5-.5v-1.4c0-.3.2-.5.5-.5H47c.3 0 .5.2.5.5v1.4c.1.3-.2.5-.5.5zM52.6 45.4h-3.3c-.3 0-.5-.2-.5-.5v-1.4c0-.3.2-.5.5-.5h3.3c.3 0 .5.2.5.5v1.4c.1.3-.2.5-.5.5zM41.2 49.4h-3.3c-.3 0-.5-.2-.5-.5v-1.4c0-.3.2-.5.5-.5h3.3c.3 0 .5.2.5.5v1.4c.1.3-.2.5-.5.5zM47 49.4h-3.3c-.3 0-.5-.2-.5-.5v-1.4c0-.3.2-.5.5-.5H47c.3 0 .5.2.5.5v1.4c.1.3-.2.5-.5.5zM52.6 49.4h-3.3c-.3 0-.5-.2-.5-.5v-1.4c0-.3.2-.5.5-.5h3.3c.3 0 .5.2.5.5v1.4c.1.3-.2.5-.5.5zM41.2 53.5h-3.3c-.3 0-.5-.2-.5-.5v-1.4c0-.3.2-.5.5-.5h3.3c.3 0 .5.2.5.5V53c.1.3-.2.5-.5.5zM47 53.5h-3.3c-.3 0-.5-.2-.5-.5v-1.4c0-.3.2-.5.5-.5H47c.3 0 .5.2.5.5V53c.1.3-.2.5-.5.5zM52.6 53.5h-3.3c-.3 0-.5-.2-.5-.5v-1.4c0-.3.2-.5.5-.5h3.3c.3 0 .5.2.5.5V53c.1.3-.2.5-.5.5z"></path>
      <circle fill="#51677a" cx="45.2" cy="32.4" r="4.1"></circle>
      <path fill="#51677a" d="M39.3 36.5h-1.4c-.3 0-.5-.2-.5-.5v-7.1c0-.3.2-.5.5-.5h1.4c.3 0 .5.2.5.5V36c.1.3-.2.5-.5.5zM52.9 36.5h-1.4c-.3 0-.5-.2-.5-.5v-7.1c0-.3.2-.5.5-.5h1.4c.3 0 .5.2.5.5V36c0 .3-.2.5-.5.5z"></path>
      <path fill="none" stroke="#cad9e0" strokeLinecap="round" d="M2.6 67.6h97.9"></path>
    </g>
  </svg>
);
