import {connect} from 'react-redux';
import {UnconnectedLanguageToggle} from 'src/shared/components/LanguageToggle/UnconnectedLanguageToggle';
import {languages} from 'src/shared/config/selectors';
import {currentLanguage} from 'src/shared/utils/currentLanguage';

const mapStateToProps = state => ({
  currentLanguage: currentLanguage(),
  languages: languages(state),
});

export const LanguageToggle = connect(mapStateToProps)(UnconnectedLanguageToggle);

