import 'whatwg-fetch';

export function timeout(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => reject({ms: ms, timeout: true}), ms);
  });
}

export function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function mark(detail) {
  window.dispatchEvent(
    new CustomEvent('mark', {detail})
  );
}

let markId = 0;
export function markPromise(url, {method = 'GET', suppressLogging = false}) {
  const startTime = window.performance.now();
  const name = `api-${markId++}-${url}`;
  const logData = {url};
  mark({name, logData});

  const addPreventLoggingFlag = async (logData, response, elapsedTime) => {
    if (suppressLogging) {
      if (typeof suppressLogging === 'function') {
        const tmp = await suppressLogging(response.clone(), elapsedTime);
        if (tmp) {
          logData.suppressLogging = tmp;
        }
      } else {
        logData.suppressLogging = true;
      }
    }
    return logData;
  };

  return response => {
    const elapsedTime = window.performance.now() - startTime;
    const {status} = response;
    if (!response.ok) {
      response.clone().text().then(text => {
        let logData;
        try {
          logData = {response: {status, data: JSON.parse(text)}, request: {method}};
        } catch (error) {
          logData = {response: {status}, request: {method}};
        } finally {
          addPreventLoggingFlag(logData, response, elapsedTime).then(logData => {
            mark({name, logData, end: true});
          });
        }
      });
    } else {
      const logData = {response: {status}, request: {method}};
      addPreventLoggingFlag(logData, response, elapsedTime).then(logData => {
        mark({name, logData, end: true});
      });
    }
    return response;
  };
}

export const fetch = (url, options = {}) => {
  const {onProgress, progressAt = 5000, timeoutAt} = options;
  let fetchPromise = window.fetch(url, options);
  const endMarkFunc = markPromise(url, options);

  if (timeoutAt) {
    fetchPromise = Promise.race([
      fetchPromise,
      wait(timeoutAt).then(() =>
        new Response(new Blob([''], {type: 'text/plain'}), {status: 598, statusText: 'Network read timeout error'})
      ),
    ]);
  }

  return Promise.race([
    fetchPromise.then(endMarkFunc),
    timeout(progressAt),
  ]).catch(err => {
    if (!err.timeout) {
      return Promise.reject(err);
    }

    if (onProgress) {
      onProgress(err);
    }
  }).then(() => fetchPromise);
};

export const fetchWithToken = (url, token, options = {}) => {
  if (!token) {
    return Promise.reject(new Error('Token is missing.'));
  }
  return fetch(url, Object.assign({mode: 'cors'}, options, {
    headers: Object.assign({Authorization: `Bearer ${token}`}, options.headers),
  }));
};
