import {AUTH} from 'src/shared/auth/actions';
import {
  customerGuid,
  inHome,
  isAuthenticated,
  lightAuth,
} from 'src/shared/auth/selectors';
import {TRACKING_STARTED} from 'src/shared/dataLayer/actionTypes';
import {waitUntil} from 'src/shared/events';
import {LOAD_USER_COMPLETE} from 'src/shared/user/actions';
import {
  userAuthGuid,
  userRole,
  x1Account,
} from 'src/shared/user/selectors';

const trackingStarted = () => ({
  type: TRACKING_STARTED,
});

export const startDataLayerTracking = ({awaitAuth = true, awaitUser = true} = {}) => async (dispatch, getState) => {
  document.dispatchEvent(new CustomEvent('c-tracking-wait'));

  if (awaitAuth) {
    await waitUntil(AUTH);

    if (awaitUser) {
      await waitUntil(LOAD_USER_COMPLETE);
    }
  }

  document.dispatchEvent(new CustomEvent('c-tracking-log-page', {details: window.digitalData, bubbles: true, composed: true}));

  let attributes = {};
  let profileInfo = {
    authenticationType: 'unauthenticated',
    customerType: 'new',
    recognizationType: 'unrecognized',
  };
  if (isAuthenticated(getState())) {
    profileInfo = {
      authGUID: userAuthGuid(getState()),
      authenticationType: lightAuth(getState()) ? 'lite authenticated' : 'authenticated',
      customerType: 'existing',
      userGUID: customerGuid(getState()) || '',
      recognizationType: `${inHome(getState()) ? 'ip' : 'cookie'} recognized`,
      type: userRole(getState()),
    };

    attributes = {
      isx1Customer: x1Account(getState()),
    };
  }

  window.dispatchEvent(
    new CustomEvent('log', {
      detail: {
        event: 'dl-tracking',
        currentPage: window.location.pathname,
        helpUser: isAuthenticated(getState()),
      },
      bubbles: true,
      composed: true,
    })
  );

  document.dispatchEvent(new CustomEvent('c-tracking-log-update', {
    bubbles: true,
    detail: {
      user: [{
        profile: [{
          profileInfo,
        }],
        attributes,
      }],
    },
  }));
  document.dispatchEvent(new CustomEvent('c-tracking-resume'));
  dispatch(trackingStarted());
};
