import {createSelector} from 'reselect';
import {createResourceSelector} from 'src/shared/utils/resource';
import {content} from 'src/shared/content/selectors';

export const guidedSearch = createResourceSelector('guidedSearch');

export const guidedSearchArticlesIndex = createSelector(
  guidedSearch,
  guidedSearch => guidedSearch.articlesIndex
);

export const guidedSearchCmsIndex = createSelector(
  content,
  content => content.guidedSearch.cmsIndex
);

export const guidedSearchHistory = createSelector(
  guidedSearch,
  guidedSearch => guidedSearch.history || []
);
