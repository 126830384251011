import React from 'react';
import {createSelector} from 'reselect';
import {isAuthenticated} from 'src/shared/auth/selectors';
import {
  CHECKING,
  ERROR,
  GOOD,
  UNKNOWN,
  WARNING,
} from 'src/shared/components/Notice/statuses';
import {flags} from 'src/shared/config/selectors';
import {
  hasEnrollSmsEnabledSegment,
  hasOutageMessagesSegment,
} from 'src/shared/datacast/selectors';
import {
  currentOutage,
  futureOutage,
  informationalOutage,
  microOutage,
  noOutage,
  outageEnrollAllowed,
  outageHeadline,
  outageImpact,
  outageMessage,
  outagePlanned,
  outageStatus,
  outageTypeConsolidated,
  overrideOutage,
  pastOutage,
} from 'src/shared/outages/selectors';
import {resourceStatus} from 'src/shared/resourceStatus/selectors';
import CheckIcon from 'src/svgs/status/check.svg';
import InterruptionIcon from 'src/svgs/status/interruption.svg';
import NullIcon from 'src/svgs/status/null.svg';
import {LOW} from 'src/shared/outages/constants';
import TIAIcon from 'src/svgs/status/ServiceTruckInArea.svg';

export const outageNotice = createSelector(
  currentOutage,
  flags,
  futureOutage,
  hasEnrollSmsEnabledSegment,
  hasOutageMessagesSegment,
  informationalOutage,
  isAuthenticated,
  microOutage,
  noOutage,
  outageEnrollAllowed,
  outageHeadline,
  outageImpact,
  outageMessage,
  outagePlanned,
  outageStatus,
  outageTypeConsolidated,
  overrideOutage,
  pastOutage,
  resourceStatus,
  (
    currentOutage,
    flags,
    futureOutage,
    hasEnrollSmsEnabledSegment,
    hasOutageMessagesSegment,
    informationalOutage,
    isAuthenticated,
    microOutage,
    noOutage,
    outageEnrollAllowed,
    outageHeadline,
    outageImpact,
    outageMessage,
    outagePlanned,
    outageStatus,
    outageTypeConsolidated,
    overrideOutage,
    pastOutage,
    resourceStatus
  ) => {
    const showMessage = hasOutageMessagesSegment || (!isAuthenticated && flags.unauthStatusMapEnabled);
    const waiting = showMessage
      ? (resourceStatus.loading.outageConsolidated || !resourceStatus.complete.outageConsolidated)
      : !resourceStatus.complete.outages;

    if (waiting) {
      return {instanceId: 'checking', status: CHECKING};
    }

    if (futureOutage) {
      return {
        icon: <CheckIcon />,
        instanceId: hasOutageMessagesSegment ? undefined : 'future outage',
        message: outageMessage,
        status: GOOD,
      };
    }

    if (pastOutage) {
      return {
        headline: hasOutageMessagesSegment ? outageHeadline : undefined,
        icon: <CheckIcon />,
        instanceId: hasOutageMessagesSegment ? `impactStyle:${outageImpact}^outageType:${outageTypeConsolidated}^optInEnroll:${outageEnrollAllowed}^plannedState:${outagePlanned}` : 'past outage',
        message: outageMessage,
        status: GOOD,
        isOutageMessage: hasOutageMessagesSegment,
      };
    }

    if (informationalOutage && !showMessage) {
      return {
        icon: <InterruptionIcon />,
        instanceId: 'informational outage',
        message: outageMessage,
        status: WARNING,
      };
    }

    if (overrideOutage && !showMessage) {
      return {
        icon: <InterruptionIcon />,
        instanceId: 'override outage',
        message: outageMessage,
        status: microOutage ? WARNING : ERROR,
      };
    }

    if (currentOutage && !showMessage) {
      return {
        headline: outageMessage
          ? undefined
          : microOutage
            ? 'You may be experiencing a service issue.'
            : 'An outage has been detected.',
        icon: <InterruptionIcon />,
        instanceId: 'current outage',
        message: outageMessage
          ? outageMessage
          : microOutage
            ? 'Your service should be restored soon. We apologize for any inconvenience.'
            : 'We are working hard to resolve the issue. ',
        status: microOutage ? WARNING : ERROR,
        link: hasEnrollSmsEnabledSegment
          ? {
            isButton: true,
            prelink: false,
            target: '_self',
            text: 'Text me when it\'s resolved',
            url: `enroll-sms/?continue=${encodeURIComponent(window.location.href)}`,
          }
          : undefined,
      };
    }

    if (noOutage) {
      return {
        headline: 'There are no service outages at your address.',
        icon: <CheckIcon />,
        instanceId: 'no outage',
        message: 'If you\'re experiencing issues, try tightening all connections to your equipment.',
        status: GOOD,
      };
    }

    if (showMessage && outageImpact && outageTypeConsolidated && !futureOutage) {
      return {
        headline: outageHeadline,
        icon: outageImpact === LOW ? <TIAIcon /> : <InterruptionIcon />,
        instanceId: `impactStyle:${outageImpact}^outageType:${outageTypeConsolidated}^optInEnroll:${outageEnrollAllowed}^plannedState:${outagePlanned}`,
        message: outageMessage,
        status: outageStatus,
        link: hasEnrollSmsEnabledSegment && hasOutageMessagesSegment && outageEnrollAllowed === 'enrollAllowed'
          ? {
            isButton: true,
            prelink: false,
            target: '_self',
            text: 'Text me with updates',
            url: `enroll-sms/?continue=${encodeURIComponent(window.location.href)}`,
          }
          : undefined,
        isOutageMessage: true,
      };
    }

    return {
      headline: 'Service status unavailable',
      icon: <NullIcon />,
      instanceId: 'no status',
      link: {
        prelink: true,
        target: '_self',
        text: 'Refresh this page',
        url: encodeURI(window.location.href),
      },
      message: ' to try again.',
      status: UNKNOWN,
    };
  }
);
