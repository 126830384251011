import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
  alertIcon,
  body,
  dismissButton,
  dismissIcon,
  linkOnly,
  main as mainStyle,
  multipleListBottom,
  sub as subStyle,
  supportlet as supportletStyle,
} from 'src/shared/components/Supportlets/style.module.scss';
import Close from 'src/svgs/action/close.svg';
import {supportlet, supportlets} from './propTypes';
import {Notice} from 'src/shared/components/Notice';

const dataTracking = id => JSON.stringify(
  {
    containerName: 'Supportlet',
    component: [
      {
        componentInfo: {
          componentID: 'xhs-supportlet',
          componentName: 'Supportlet',
          description: 'Supportlet personalization toast',
          instanceID: id,
        },
      },
    ],
  }
);

const displayTrackingEvent = id => new CustomEvent(
  'c-tracking-log-dom',
  {
    bubbles: true,
    composed: true,
    detail: {
      eventMethod: 'component-Track',
      component: [{
        componentInfo: {
          componentID: 'xhs-supportlet',
          componentName: 'Supportlet',
          description: 'Supportlet personalization toast',
          instanceID: id,
        },
      }],
    },
  });

const dismissSupportlet = (id, onDismiss, ref) => {
  onDismiss({id});
  ref.current.dispatchEvent(
    new CustomEvent('log', {
      bubbles: true,
      composed: true,
      detail: {event: 'personalized-message-dismissed', messageId: id},
    })
  );
};

const Supportlet = ({
  onDismiss,
  supportlet,
}) => {
  const ref = useRef(null);
  const {
    color,
    icon,
    id,
    links = [],
    main,
    sub,
  } = supportlet;
  return (
    <article
      data-tracking-parent
      data-tracking={dataTracking(id)}
      className={`${supportletStyle} ${color}`}
      key={id}
      ref={ref}
    >
      <div className={alertIcon}>{icon}</div>
      <div className={body}>
        <p className={mainStyle}>{main}</p>
        {
          (sub || links.length)
          && (
            <p className={`${subStyle} ${sub ? '' : linkOnly}`}>
              {sub}
              {
                links.map(({target, text, url}) => (
                  <a href={url} target={target} rel="noopener" key={url}>{text}</a>
                ))
              }
            </p>
          )
        }
      </div>
      <button onClick={() => dismissSupportlet(supportlet.id, onDismiss, ref)} className={dismissButton}>
        <Close className={dismissIcon} />
      </button>
    </article>
  );
};

Supportlet.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  supportlet: supportlet.isRequired,
};

export const UnconnectedSupportlets = ({
  className,
  futureOutage,
  hasOutageMessagesSegment,
  onDismiss,
  onDisplay,
  outageImpact,
  outageNotice,
  supportlets,
}) => {
  const [alreadyShown, setAlreadyShown] = useState([]);
  const ref = useRef(null);

  useEffect(() => {
    const shown = supportlets.map(({id}) => id).filter(id => !alreadyShown.includes(id));
    shown.forEach(id => {
      ref.current.dispatchEvent(displayTrackingEvent(id));
      onDisplay(id);
    });
    setAlreadyShown([...shown, ...alreadyShown]);
  }, [supportlets]);

  if (supportlets.length === 0 && (!outageImpact || futureOutage)) {
    return null;
  }

  return (
    <section ref={ref} className={className}>
      {(outageImpact && !futureOutage)
        && hasOutageMessagesSegment && <Notice className={supportlets ? multipleListBottom : ''} {...outageNotice} />
      }
      {
        supportlets.map(supportlet => (
          <Supportlet
            key={supportlet.id}
            onDismiss={onDismiss}
            supportlet={supportlet}
          />
        ))
      }
    </section>
  );
};

UnconnectedSupportlets.propTypes = {
  className: PropTypes.string,
  futureOutage: PropTypes.bool,
  hasOutageMessagesSegment: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  onDisplay: PropTypes.func.isRequired,
  outageImpact: PropTypes.string,
  outageNotice: PropTypes.shape({
    action: PropTypes.shape({
      onClick: PropTypes.func,
      text: PropTypes.string,
    }),
    headline: PropTypes.string,
    instanceId: PropTypes.string,
    message: PropTypes.string,
    link: PropTypes.shape({
      target: PropTypes.string,
      text: PropTypes.string,
      url: PropTypes.string,
    }),
    status: PropTypes.string,
  }),
  supportlets: supportlets.isRequired,
};
