import isFunction from 'lodash/isFunction';
import {createSelector} from 'reselect';
import {selectedDeviceId} from 'src/pages/repair/store/selectors/selectedDeviceId';
import {
  oauthOptions,
  urls,
} from 'src/shared/config/selectors';
import {accountNumber} from 'src/shared/linkedAccounts/selectors';
import {orderId} from 'src/pages/answerflow/store/selectors/orderId';
import {currentStepId} from 'src/shared/itgStep/selectors/currentStepId';
import {cxpSessionId} from 'src/shared/cxpSession/selectors/cxpSessionId';
import {docId} from 'src/shared/itgRecommendations/selectors';
import {jsessionId} from 'src/shared/itgProcess/selectors/jsessionId';
import {processSessionId} from 'src/shared/itgProcess/selectors/processSessionId';
import {query} from 'src/shared/params/selectors';
import {userAuthGuid} from 'src/shared/user/selectors';
import {appendParams} from 'src/shared/utils/appendParams';

const validateParams = (allowlist, params) => {
  if (!allowlist || !params) {
    return;
  }
  const invalid = Object.keys(params).filter(key => !allowlist.includes(key));
  if (invalid.length) {
    throw new Error(`Invalid parameters specified. Expected only ${allowlist}, but received ${invalid}`);
  }
};

const getBase = (name, root, path, state) => {
  const urlConfigs = urls(state);
  return urlConfigs[name] || `${urlConfigs[root]}${path}`;
};

const replacePathSegments = (base, pathReplacements, state) => Object.entries(pathReplacements)
  .reduce(
    (url, [token, selector]) => url.replace(token, isFunction(selector) ? selector(state) : selector),
    base
  );

export const createUrlSelector = (name, root, path, pathReplacements = {}, paramsAllowlist) => (state, params) => {
  validateParams(paramsAllowlist, params);
  let url = getBase(name, root, path, state);
  url = replacePathSegments(url, pathReplacements, state);
  url = appendParams(url, params);
  return url;
};

// Schedule Callback URLs

export const scheduleCallbacks = createUrlSelector(
  'scheduleCallbacks',
  'middleware',
  '/scheduled-callbacks',
  {},
  []
);

export const scheduleAsapCallbacks = createUrlSelector(
  'scheduleCallbacks',
  'middleware',
  '/scheduled-callbacks/asap',
  {},
  []
);

export const deleteInteraction = (state, appointmentId) => {
  const url = getBase('deleteInteraction', 'middleware', '/scheduled-callbacks/{appointmentId}', state);
  return replacePathSegments(url, {'{appointmentId}': appointmentId}, state);
};

// CSP URLs

export const account = createUrlSelector(
  'account',
  'csp',
  '/selfhelp/account/{accountNumber}?stbDetails=true',
  {'{accountNumber}': accountNumber},
  ['cspToken']
);

export const extendedProfile = createUrlSelector(
  'extendedProfile',
  'csp',
  '/selfhelp/user/me?response_profile=extended',
  {},
  []
);

export const internetDevices = createUrlSelector(
  'internetDevices',
  'csp',
  '/selfhelp/account/{accountNumber}/services/internet/devices/?profile=wifi&profile=si',
  {'{accountNumber}': accountNumber},
  ['cspToken']
);

export const rebootTv = createUrlSelector(
  'rebootTv',
  'csp',
  '/selfhelp/account/{accountNumber}/services/video/devices/{deviceId}/reboot',
  {'{accountNumber}': accountNumber, '{deviceId}': selectedDeviceId},
  ['cspToken']
);

export const rebootInternet = createUrlSelector(
  'rebootInternet',
  'csp',
  '/selfhelp/account/{accountNumber}/services/internet/devices/{deviceId}',
  {'{accountNumber}': accountNumber, '{deviceId}': selectedDeviceId},
  ['cspToken']
);

export const systemRefreshes = createUrlSelector(
  'systemRefreshes',
  'csp',
  '/selfhelp/account/{accountNumber}/systemRefreshes',
  {'{accountNumber}': accountNumber},
  []
);

export const wholeHomeRefresh = createUrlSelector(
  'wholeHomeRefresh',
  'csp',
  '/selfhelp/account/{accountNumber}/refresh?profile=WHOLE_HOME_REFRESH',
  {'{accountNumber}': accountNumber},
  []
);

// SSM URLs

export const cancelService = createUrlSelector(
  'cancelService',
  'middleware',
  '/cancel-services/cancel',
  {},
  []
);

export const contactUs = createUrlSelector(
  'contactUs',
  'middleware',
  '/contactus',
  {},
  []
);

export const currentBillSummary = createUrlSelector(
  'currentBillSummary',
  'middleware',
  '/bill/current/summary',
  {},
  []
);

export const customerTicket = createUrlSelector(
  'customerTicket',
  'middleware',
  '/customerticket',
  {},
  ['an_hash']
);

export const dropBuryUrl = createUrlSelector(
  'dropBury',
  'middleware',
  '/timeline/onedrops',
  {},
  ['startDate', 'endDate']
);

export const hic = createUrlSelector(
  'hic',
  'middleware',
  '/devices/status',
  {},
  []
);

export const connectivityState = createUrlSelector(
  'connectivityState',
  'middleware',
  '/wifi/connectivity',
  {},
  []
);

export const outageOptIn = createUrlSelector(
  'outageOptIn',
  'middleware',
  '/outage-optin/V2',
  {},
  ['phoneNumber']
);

export const outageData = createUrlSelector(
  'outagedata',
  'middleware',
  '/outagedata/v2',
  {},
  ['an_hash']
);

export const unauthOutageData = (state, result) => {
  const url = getBase('unauthOutageData', 'middleware', '/outagedata/v2?address={result}', state);
  return replacePathSegments(url, {'{result}': result}, state);
};

export const outages = createUrlSelector(
  'outages',
  'middleware',
  '/outage',
  {},
  ['an_hash']
);

export const outageConsolidated = createUrlSelector(
  'outageConsolidated',
  'middleware',
  '/outage/consolidated/lob',
  {},
  ['an_hash']
);

export const outageHistory = createUrlSelector(
  'outageHistory',
  'middleware',
  '/outage/history',
  {},
  ['an_hash']
);

export const unauthOutageConsolidated = (state, result) => {
  const url = getBase('unauthOutages', 'middleware', '/outage/consolidated/lob?address={result}', state);
  return replacePathSegments(url, {'{result}': result}, state);
};

export const outageDataSession = createUrlSelector(
  'outageDataSession',
  'middleware',
  '/outagedata/session',
  {},
  []
);

export const addressTypeahead = (state, address) => {
  const url = getBase('addressTypeahead', 'middleware', '/address/typeahead?origins=eloc&max_results=5&tokenized=true&address={address}', state);
  return replacePathSegments(url, {'{address}': address}, state);
};

export const personalization = createUrlSelector(
  'personalization',
  'middleware',
  '/personalization',
  {},
  []
);

export const pht = createUrlSelector(
  'pht',
  'middleware',
  '/pht',
  {},
  ['an_hash', 'lcstatus', 'moca']
);

export const phtTimeline = createUrlSelector(
  'phtTimeline',
  'middleware',
  '/timeline/pht',
  {},
  ['an_hash', 'endDate', 'startDate']
);

export const rateCard = createUrlSelector(
  'rateCard',
  'middleware',
  '/rate-card',
  {},
  []
);

export const restart = createUrlSelector(
  'restart',
  'middleware',
  '/devices/restart/v2',
  {},
  []
);

export const deviceUptime = createUrlSelector(
  'deviceUptime',
  'middleware',
  '/devices/status',
  {},
  []
);

export const remoteBrands = (state, remoteId, deviceType) => {
  const url = getBase('remoteBrands', 'middleware', '/remotes/{remoteId}/{deviceType}/brands', state);
  return replacePathSegments(url, {'{remoteId}': remoteId, '{deviceType}': deviceType}, state);
};

export const remoteCodes = (state, remoteId, deviceType, brand) => {
  const url = getBase('remoteCodes', 'middleware', '/remotes/{remoteId}/{deviceType}/brands/{brand}/codes', state);
  return replacePathSegments(url, {'{remoteId}': remoteId, '{deviceType}': deviceType, '{brand}': brand}, state);
};

export const user = createUrlSelector(
  'user',
  'middleware',
  '/user',
  {},
  ['an_hash']
);

export const person = createUrlSelector(
  'person',
  'middleware',
  '/person',
  {},
  ['an_hash']
);

export const cxpSession = createUrlSelector(
  'cxpSession',
  'middleware',
  '/itg/cxp/session',
  {},
  ['an_hash', 'flow']
);

export const cxpRecommendation = createUrlSelector(
  'cxpRecommendation',
  'middleware',
  '/itg/cxp/session/{cxpSessionId}/recommendations',
  {'{cxpSessionId}': cxpSessionId},
  ['an_hash']
);

export const itgRecommendations = createUrlSelector(
  'itgRecommendations',
  'middleware',
  '/itg/recommendations',
  {},
  ['an_hash']
);

export const itgProcess = createUrlSelector(
  'itgProcess',
  'middleware',
  '/itg/cxp/session/{cxpSessionId}/process/{docId}',
  {'{cxpSessionId}': cxpSessionId, '{docId}': docId},
  ['an_hash', 'outputOptionId']
);

export const itgStep = createUrlSelector(
  'itgStep',
  'middleware',
  '/itg/session/{processSessionId}/step/{stepId}?jsessionId={jsessionId}',
  {'{processSessionId}': processSessionId, '{stepId}': currentStepId, '{jsessionId}': jsessionId},
  ['an_hash', 'displayFormReferenceKey', 'labels', 'variables']
);

export const mdiAddressSearch = (state, address) => {
  const url = getBase('mdiAddressSearch', 'middleware', '/mdi/location/address/search?streetAddress={address}', state);
  return replacePathSegments(url, {'{address}': address}, state);
};

export const mdiAddressSpeedTiers = (state, elocId) => {
  const url = getBase('mdiAddressSpeedTiers', 'middleware', '/mdi/location/address/speedtiers/{elocId}', state);
  return replacePathSegments(url, {'{elocId}': elocId}, state);
};

export const mdiAddressValid = (state, address) => {
  const url = getBase('mdiAddressValid', 'middleware', '/mdi/location/address/validate?address={address}', state);
  return replacePathSegments(url, {'{address}': address}, state);
};

export const mdiDeviceAutofill = createUrlSelector(
  'mdiDeviceAutofill',
  'middleware',
  '/mdi/devices/search/autofilldata',
  {},
  []
);

export const mdiDeviceCompare = (state, ids) => {
  const url = getBase('mdiDeviceCompare', 'middleware', '/mdi/devices/details/compare?deviceIds={ids}', state);
  return replacePathSegments(url, {'{ids}': ids}, state);
};

export const mdiDeviceDetails = (state, devicesId) => {
  const url = getBase('mdiDeviceDetails', 'middleware', '/mdi/devices/details/{devicesId}', state);
  return replacePathSegments(url, {'{devicesId}': devicesId}, state);
};

export const mdiDeviceSearch = createUrlSelector(
  'mdiDeviceSearch',
  'middleware',
  '/mdi/devices/search/filter',
  {},
  []
);

export const mdiFilterData = createUrlSelector(
  'mdiFilterData',
  'middleware',
  '/mdi/devices/search/filterdata',
  {},
  []
);

export const elocAddress = (state, elocId) => {
  const url = getBase('elocAddress', 'middleware', '/mdi/location/address/{elocId}', state);
  return replacePathSegments(url, {'{elocId}': elocId}, state);
};

export const mdiSession = createUrlSelector(
  'mdiSession',
  'middleware',
  '/mdi/session',
  {},
  []
);

export const mdiUserAccountDevices = createUrlSelector(
  'mdiUserAccountDevices',
  'middleware',
  '/mdi/account/devices',
  {},
  []
);

export const mdiUserAccountProfile = createUrlSelector(
  'mdiUserAccountProfile',
  'middleware',
  '/mdi/account/profile',
  {},
  []
);

export const mdiUserAccountSpeedtier = createUrlSelector(
  'mdiUserAccountSpeedtier',
  'middleware',
  '/mdi/account/speedtierinfo',
  {},
  []
);

export const mdiZipcodeAutofill = createUrlSelector(
  'mdiZipcodeAutofill',
  'middleware',
  '/mdi/location/zipcode/autofilldata',
  {},
  []
);

export const mdiZipcodeSpeedTiers = (state, zip5) => {
  const url = getBase('mdiZipcodeSpeedTiers', 'middleware', '/mdi/location/zipcode/speedtiers/{zip5}', state);
  return replacePathSegments(url, {'{zip5}': zip5}, state);
};

// H&S Middleware URLs

export const channelLineup = createUrlSelector(
  'channelLineup',
  'middleware',
  '/channels/lineup',
  ['address']
);

export const enrollment = (state, mobileNumber) => {
  const url = getBase('enrollment', 'apiSupport', '/customerticket/{accountnumber}/{mobilenumber}', state);
  return replacePathSegments(url, {'{accountnumber}': accountNumber, '{mobilenumber}': mobileNumber}, state);
};

export const logger = createUrlSelector(
  'logger',
  'apiSupport',
  '/logger/',
  {},
  []
);

export const scheduleCallback = (state, phoneNumber) => {
  const url = getBase('scheduleCallback', 'apiSupport', '/schedulecallback/{accountNumber}/{phoneNumber}', state);
  return replacePathSegments(
    url,
    {
      '{accountNumber}': accountNumber,
      '{phoneNumber}': phoneNumber.replace(/\D/g, ''),
    },
    state
  );
};

export const videoBill = state => {
  const authGuid = userAuthGuid(state);
  const queryParams = query(state);
  const videoBillUrl = getBase('videoBill', 'apiSupport', '/videobill/VideoBillObjectAsync/', state);
  if (queryParams.b && authGuid) {
    return `${videoBillUrl}?queryString=b=${queryParams.b}%26g=${authGuid}%26v=${queryParams.v || 'undefined'}`;
  }
  if (authGuid) {
    return `${videoBillUrl}?authGuid=${authGuid}`;
  }
};

export const watchTower = createUrlSelector(
  'watchtower',
  'apiSupport',
  '/watchtower/{accountNumber}',
  {'{accountNumber}': accountNumber},
  []
);

export const yext = createUrlSelector(
  'yext',
  'apiSupport',
  '/servicecenters',
  {},
  []
);

// Miscellaneous URLs

export const guidedSearchArticlesIndex = createSelector(
  urls,
  urls => urls.guidedSearchArticlesIndex || '/support/data/indices/articles'
);

export const myAccount = createUrlSelector(
  'myAccount',
  'myAccountWeb',
  '',
  {}
);

export const billPay = createUrlSelector(
  'billPay',
  'myAccountWeb',
  '/#/billing/payment',
  {}
);

export const manageWifi = createUrlSelector(
  'manageWifi',
  'myAccountWeb',
  '/settings/wifi',
  {}
);

export const moving = createUrlSelector(
  'moving',
  'xfinity',
  '/learn/moving',
  {}
);

export const polaris = createSelector(
  urls,
  urls => urls.polaris
);

export const signIn = createSelector(
  urls,
  oauthOptions,
  (urls, oauthOptions) => window.Oauth.getLoginUrl({...oauthOptions, redirect: true, authOpts: {...oauthOptions.authOpts, prompt: 'login'}})
);

export const signOut = createSelector(
  urls,
  oauthOptions,
  (urls, oauthOptions) => appendParams(urls.signOut, {client_id: oauthOptions.clientId, state: location.href})
);

export const datacastSegments = createUrlSelector(
  'datacastSegments',
  'xapi',
  '/personalization/evaluation',
  ['TAGS', 'caller']
);

export const locationsSuggestions = createUrlSelector(
  'locationsSuggestions',
  'middleware',
  '/mdi/location/address/search',
  {},
  ['streetAddress']
);

export const appointments = createUrlSelector(
  'appointments',
  'myAccountWeb',
  '/appointments/new',
  {}
);

export const xfinityAssistant = createUrlSelector(
  'xfinityAssistant',
  'xfinityAssistant',
  {},
  ['utterance', 'intent']
);

export const speedTest = createSelector(
  urls, ({speedTest}) => speedTest
);

export const learnFlex = createUrlSelector(
  'learnFlex',
  'xfinity',
  '/learn/flex',
  {}
);

export const channelTracking = createUrlSelector(
  'channelTracking',
  'channelTracking',
  '',
  {},
  []
);

// getBase

export const equipmentUpdate = state => {
  if (orderId(state)) {
    const url = getBase('equipmentUpdate', 'myAccountWeb', '/devices/equipment-update/{orderId}', state);
    return replacePathSegments(url, {'{orderId}': orderId}, state);
  }

  return getBase('equipmentUpdate', 'myAccountWeb', '/devices/equipment-update/new', state);
};

export const storeLocator = createUrlSelector(
  'storeLocator',
  'xfinity',
  '/local/store-offers',
  {}
);

export const cancelServiceForm = createUrlSelector(
  'cancelService',
  'xfinity',
  '/support/cancel-service',
  {}
);
