import {token as tokenSelector} from 'src/shared/auth/selectors';
import {extendedProfile} from 'src/shared/config/urls';
import {createFetchResourceAction} from 'src/shared/utils/resource';

export const READ_EXTENDED_PROFILE_START = 'READ_EXTENDED_PROFILE_START';
export const READ_EXTENDED_PROFILE_SUCCESS = 'READ_EXTENDED_PROFILE_SUCCESS';
export const READ_EXTENDED_PROFILE_ERROR = 'READ_EXTENDED_PROFILE_ERROR';
export const READ_EXTENDED_PROFILE_COMPLETE = 'READ_EXTENDED_PROFILE_COMPLETE';

export const readExtendedProfile = token => createFetchResourceAction({
  start: READ_EXTENDED_PROFILE_START,
  success: READ_EXTENDED_PROFILE_SUCCESS,
  error: READ_EXTENDED_PROFILE_ERROR,
  complete: READ_EXTENDED_PROFILE_COMPLETE,
  url: extendedProfile,
  token: token || tokenSelector,
});
