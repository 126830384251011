import React from 'react';
import {url} from 'src/shared/utils/url';

export const Mobile = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props} role="img" aria-hidden="true">
    <g transform="translate(-1 17)">
      <defs>
        <linearGradient
          id="mobile-dark-gradient-1"
          x1="-19.044"
          x2="-19.466"
          y1="105.573"
          y2="106.356"
          gradientTransform="matrix(33.9692 0 0 -63.2644 703.4 6733.288)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#364251"></stop>
          <stop offset="1" stopColor="#44576b"></stop>
        </linearGradient>
        <linearGradient
          id="mobile-dark-gradient-2"
          x1="-18.904"
          x2="-19.513"
          y1="105.261"
          y2="106.125"
          gradientTransform="matrix(33.9692 0 0 -48.5517 703.4 5166.391)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#44576b"></stop>
          <stop offset="1" stopColor="#51677a"></stop>
        </linearGradient>
      </defs>
      <path fill={url('#mobile-dark-gradient-1')} d="M63.4 66.1H38.1c-2.4 0-4.4-1.9-4.4-4.3V7.2c0-2.4 1.9-4.3 4.4-4.3h25.3c2.4 0 4.4 1.9 4.4 4.3v54.6c-.1 2.4-2 4.3-4.4 4.3z"></path>
      <path fill={url('#mobile-dark-gradient-2')} d="M33.8 10.2h33.9v48.6H33.8z"></path>
      <path fill="#51677a" fillOpacity=".5" d="M33.8 10.2v48.6h33.9V44z"></path>
      <ellipse cx="51.1" cy="62.1" fill="#021721" rx="1.8" ry="1.8"></ellipse>
      <path fill="none" stroke="#021721" strokeLinecap="round" d="M48.5 7.3H53"></path>
      <path fill="none" stroke="#cad9e0" strokeLinecap="round" d="M2.8 66.1h95.6"></path>
    </g>
  </svg>
);
