export const HSD_ITG_IN_PROGRESS = 'HSD_ITG_IN_PROGRESS';
export const HSD_ITG_RECOMMENDED = 'HSD_ITG_RECOMMENDED';
export const HSD_PHT_COMPLETED = 'HSD_PHT_COMPLETED';
export const HSD_PHT_IN_PROGRESS = 'HSD_PHT_IN_PROGRESS';
export const HSD_SPEED_TEST = 'HSD_SPEED_TEST';
export const HSD_WIFI_NAME = 'HSD_WIFI_NAME';
export const HSD_WIFI_PASSWORD = 'HSD_WIFI_PASSWORD';
export const HSD_XFINITY_PASSWORD = 'HSD_XFINITY_PASSWORD';
export const HSD_XFINITY_PASSWORD_DECLINED = 'HSD_XFINITY_PASSWORD_DECLINED';
export const HSD_XFINITY_USERNAME = 'HSD_XFINITY_USERNAME';
export const HSD_XFINITY_USERNAME_DECLINED = 'HSD_XFINITY_USERNAME_DECLINED';
export const ITG_RECOMMENDED = 'ITG_RECOMMENDED';
export const POWERCYCLE_RECOMMENDED = 'POWERCYCLE_RECOMMENDED';
export const REBOOT_ATTEMPTED = 'REBOOT_ATTEMPTED';
export const REBOOT_COMPLETED = 'REBOOT_COMPLETED';
export const REBOOT_FAILED = 'REBOOT_FAILED';
export const REBOOT_IN_PROGRESS = 'REBOOT_IN_PROGRESS';
export const REFRESH_COMPLETED = 'REFRESH_COMPLETED';
export const REFRESH_FAILED = 'REFRESH_FAILED';
export const REFRESH_IN_PROGRESS = 'REFRESH_IN_PROGRESS';
export const REMOTEPAIRING_BRAND_SELECTION = 'REMOTEPAIRING_BRAND_SELECTION';
export const REMOTEPAIRING_DEVICE_TYPE_SELECTION = 'REMOTEPAIRING_DEVICE_TYPE_SELECTION';
export const REMOTEPAIRING_INSTRUCTIONS = 'REMOTEPAIRING_INSTRUCTIONS';
export const REMOTEPAIRING_REMOTE_SELECTION = 'REMOTEPAIRING_REMOTE_SELECTION';
export const RESTART_COMPLETED = 'RESTART_COMPLETED';
export const RESTART_FAILED = 'RESTART_FAILED';
export const RESTART_IN_PROGRESS = 'RESTART_IN_PROGRESS';
export const TRANSFERRED = 'TRANSFERRED';
export const TRUCK_ROLL_SCHEDULED = 'TRUCK_ROLL_SCHEDULED';
export const VIEW_CHANNEL_LINEUP = 'VIEW_CHANNEL_LINEUP';
export const WIFI_DETAIL = 'WIFI_DETAIL';
