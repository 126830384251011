import {connect} from 'react-redux';
import {UnconnectedGuidedSearch} from 'src/shared/components/GuidedSearch/UnconnectedGuidedSearch';
import {loadGuidedSearch} from 'src/shared/guidedSearch/actions';
import {
  addGuidedSearchHistoryEntry,
  removeGuidedSearchHistoryEntry,
} from 'src/shared/guidedSearch/actions/guidedSearchHistory';
import {
  guidedSearch,
  guidedSearchArticlesIndex,
  guidedSearchCmsIndex,
  guidedSearchHistory,
} from 'src/shared/guidedSearch/selectors';
import {launchXfinityAssistant} from 'src/shared/xfinityAssistant/actions';

const stateToProps = state => {
  /* istanbul ignore if */
  if (!guidedSearch(state)) {
    console.warn('Guided search is missing from the state! Be sure to add the `guidedSearch` reducer.');
  }
  return {
    articleIndex: guidedSearchArticlesIndex(state),
    cmsIndex: guidedSearchCmsIndex(state),
    history: guidedSearchHistory(state),
  };
};

const dispatchToProps = dispatch => ({
  onInitialize: ({includeArticles}) => {
    dispatch(loadGuidedSearch({loadArticlesIndex: includeArticles}));
  },
  onSaveHistory: (value, maxResults) => {
    dispatch(addGuidedSearchHistoryEntry(value, maxResults));
  },
  onRemoveHistory: value => {
    dispatch(removeGuidedSearchHistoryEntry(value));
  },
  onLaunchXfinityAssistant: utterance => dispatch(launchXfinityAssistant(utterance)),
});

export const GuidedSearch = connect(stateToProps, dispatchToProps)(UnconnectedGuidedSearch);
