import md5 from 'md5';
import {createSelector} from 'reselect';
import {anHashSalt} from 'src/shared/config/selectors';
import {combined} from 'src/shared/params/selectors';
import {createResourceSelector} from 'src/shared/utils/resource';

export const extendedProfile = createResourceSelector('extendedProfile');

export const matchingAccount = createSelector(
  [extendedProfile, anHashSalt, combined],
  (extendedProfile, salt, {an_hash: md5AccountNumber}) =>
    md5AccountNumber
    && (extendedProfile?.accounts || [])
      .find(({accountNumber}) => md5AccountNumber === md5(`${accountNumber}${salt}`))
);

export const accountNumber = createSelector(
  matchingAccount,
  matchingAccount => matchingAccount && matchingAccount.accountNumber
);

export const cspToken = createSelector(
  matchingAccount,
  matchingAccount => matchingAccount && matchingAccount.cspToken
);

export const anHashParam = createSelector(
  combined,
  ({an_hash: anHash}) => anHash ? {an_hash: anHash} : {}
);

export const cspTokenParam = createSelector(
  cspToken,
  cspToken => cspToken ? {cspToken} : {}
);
