import {createSelector} from 'reselect';
import {debugFlagsParam} from 'src/shared/params/selectors';

export const config = state => state.config;

export const flags = createSelector(
  [config, debugFlagsParam],
  (config, debugFlagsParam) => ({...config.flags, ...debugFlagsParam})
);

export const languages = createSelector(
  config,
  config => (config.languages || []).map(language => ({
    ...language,
    href: window.location.href.replace(document.baseURI, language.baseURI),
  }))
);

export const oauthOptions = createSelector(
  config,
  config => config.oauthOptions
);

export const clientId = createSelector(
  oauthOptions,
  oauthOptions => oauthOptions.clientId
);

export const activityTimeout = createSelector(
  config,
  config => config.oauth.activityTimeout
);

export const timeouts = createSelector(
  config,
  config => config.timeouts
);

export const urls = createSelector(
  config,
  config => config.urls
);

export const anHashSalt = createSelector(
  config,
  config => config.anHashSalt
);
