import React from 'react';
import {url} from 'src/shared/utils/url';

export const Video = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props} role="img" aria-hidden="true">
    <g transform="translate(-13 15)">
      <defs>
        <linearGradient
          id="video-dark-gradient-1"
          x1="-6.488"
          x2="-7.271"
          y1="102.388"
          y2="101.877"
          gradientTransform="matrix(11.2233 0 0 -5.9546 110.945 672.519)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#414b59"></stop>
          <stop offset="1" stopColor="#505d6b"></stop>
        </linearGradient>
        <linearGradient
          id="video-dark-gradient-2"
          x1="-7.46"
          x2="-6.678"
          y1="102.388"
          y2="101.877"
          gradientTransform="matrix(11.2233 0 0 -5.9546 171.527 672.519)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#414b59"></stop>
          <stop offset="1" stopColor="#505d6b"></stop>
        </linearGradient>
        <linearGradient
          id="video-dark-gradient-3"
          x1="-7.399"
          x2="-8.268"
          y1="107.646"
          y2="108.188"
          gradientTransform="matrix(92.0096 0 0 -59.0706 774.418 6403.694)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#364250"></stop>
          <stop offset="1" stopColor="#44566b"></stop>
        </linearGradient>
        <linearGradient
          id="video-dark-gradient-4"
          x1="-7.578"
          x2="-8.383"
          y1="107.655"
          y2="108.129"
          gradientTransform="matrix(82.8694 0 0 -50.1854 702.82 5439.645)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#44566b"></stop>
          <stop offset="1" stopColor="#506779"></stop>
        </linearGradient>
      </defs>
      <path fill={url('#video-dark-gradient-1')} d="M31.9 61.8l-4.8 5.9h6.4l4.8-5.9z"></path>
      <path fill={url('#video-dark-gradient-2')} d="M87.6 61.8l4.9 5.9h6.4L94 61.8z"></path>
      <path fill={url('#video-dark-gradient-3')} d="M18.4 3.3c-.6 0-1.1.5-1.1 1.1v56.9c0 .6.5 1.1 1.1 1.1h89.8c.6 0 1.1-.5 1.1-1.1V4.4c0-.6-.5-1.1-1.1-1.1H18.4z"></path>
      <path fill={url('#video-dark-gradient-4')} d="M21.9 57.9h82.9V7.7H21.9z"></path>
      <path fill="#51677a" fillOpacity=".7" d="M38.3 7.7H21.9v50.2H90z"></path>
      <path fill="none" stroke="#cad9e0" strokeLinecap="round" d="M2.3 67.5h120.5"></path>
    </g>
  </svg>
);
