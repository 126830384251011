import {createSelector} from 'reselect';
import {createResourceSelector} from 'src/shared/utils/resource';
import takeWhile from 'lodash/takeWhile';

const MAX_VISIBLE_SUPPORTLETS = 1;
const TWO_HOURS_MS = 72e5;
const FIVE_DAYS_MS = 432e6;
const XRE_ERRORS_FIXABLE_BY_SYSTEM_REFRESH = [
  'XRE-03044',
  'XRE-10007',
  'XRE-00021',
  'XRE-03061',
  'XRE-03063',
  'XRE-03007',
  'XRE-07002',
  'XRE-03047',
];

export const personalization = createResourceSelector('personalization');

export const firstTimeLogin = createSelector(
  [personalization],
  personalization => personalization.firstTimeLogin
);
export const timeline = createSelector(
  [personalization],
  personalization => personalization.timeline || {}
);

export const x1Customer = createSelector(
  [personalization],
  personalization => personalization.x1Customer
);

export const sikPendingActivation = createSelector(
  [personalization],
  personalization => personalization.sikPendingActivation
);

export const sik = createSelector(
  [timeline],
  timeline => timeline.sik || []
);

export const isSikShippedAndPendingActivation = createSelector(
  [sikPendingActivation, sik, () => Date.now()],
  (sikPendingActivation, sik, now) => sikPendingActivation
    && sik.some(sik => !sik.sikActivated
      && sik.sikShipped
      && sik.sikShipmentDate
      && new Date(sik.sikShipmentDate).getTime() < now - FIVE_DAYS_MS)
);

export const xreErrorCodes = createSelector(
  [timeline],
  timeline => timeline.xreErrorCodes || []
);

export const recentRefreshableCodes = createSelector(
  [xreErrorCodes, () => Date.now()],
  (xreErrorCodes, now) => xreErrorCodes
    .filter(({xreErrorCode, timestamp}) => XRE_ERRORS_FIXABLE_BY_SYSTEM_REFRESH.includes(xreErrorCode)
      && new Date(timestamp).getTime() > now - TWO_HOURS_MS)
);

export const hasRecentSystemRefresh = createSelector(
  [timeline, () => Date.now()],
  (timeline, now) => !!(
    timeline.systemRefresh
    && timeline.systemRefresh.timeStamp
    && new Date(timeline.systemRefresh.timeStamp).getTime() > now - TWO_HOURS_MS
  )
);

export const visibleSupportlets = candidateSupportlets => state => {
  const {dismissed} = state.supportlets;
  const supportlets = candidateSupportlets.map(f => f(state));
  return takeWhile(supportlets, supportlet => supportlet.ready)
    .filter(supportlet => supportlet.eligible)
    .slice(0, MAX_VISIBLE_SUPPORTLETS)
    .filter(({id}) => !dismissed[id]);
};
