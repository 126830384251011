import PropTypes from 'prop-types';
import React from 'react';
import {Carousel} from 'src/pages/home/components/Carousel';
import {CommonSolutions} from 'src/pages/home/components/CommonSolutions';
import {Directory} from 'src/pages/home/components/Directory';
import {
  carouselSection,
  sectionHeading,
  supportlets,
} from 'src/pages/home/components/styles.module.scss';
import {GuidedSearch} from 'src/shared/components/GuidedSearch';
import {PageFooter} from 'src/shared/components/PageFooter';
import {StatefulHeader} from 'src/shared/components/StatefulHeader';
import {collapseMarginMobile} from 'src/shared/components/StatefulHeader/style.module.scss';
import {Supportlets} from 'src/shared/components/Supportlets';
import {dropBurySupportlet} from 'src/shared/components/Supportlets/supportlets/dropBury';
import {outageSupportlet} from 'src/shared/components/Supportlets/supportlets/outage';
import {
  scheduleCallbackSupportlet,
  setupRemoteSupportlet,
  setupSikSupportlet,
  setupWifiSupportlet,
  systemRefreshSupportlet,
} from 'src/shared/components/Supportlets/supportlets/personalization';
import {
  flushMobile,
  mainWide,
  section,
} from 'src/styles/modules/main.module.scss';

export const HomePage = ({
  content: {solutions, tabs, tiles},
  futureOutage,
  hasOutageMessagesSegment,
  isStormReadyWifi,
  outageList,
  pastOutage,
}) => (
  <>
    <StatefulHeader
      className={hasOutageMessagesSegment && futureOutage ? '' : collapseMarginMobile}
      slimNoticeEnabled={true}
      headline="How can we help?"
      logo={true}
      search={<GuidedSearch placeholder="Search topics or keywords" />}
    >
    </StatefulHeader>
    <main className={mainWide}>
      {
      /* Displays the ProactiveIssueNotice component when either Wi-Fi is not storm-ready
       or Wi-Fi is storm-ready and there's no internet outage. */
        (
          !isStormReadyWifi || (isStormReadyWifi && !outageList.internetOutage && !pastOutage)
        ) && <Supportlets
          className={`${section} ${supportlets}`}
          supportlets={[
            dropBurySupportlet,
            outageSupportlet,
            scheduleCallbackSupportlet,
            setupRemoteSupportlet,
            setupSikSupportlet,
            setupWifiSupportlet,
            systemRefreshSupportlet,
          ]} />
      }

      <section className={`${section} ${flushMobile}`}>
        <Directory tiles={tiles.content} />
      </section>
      <section className={section}>
        <h2 className={sectionHeading}>{solutions.title}</h2>
        <CommonSolutions solutions={solutions.content} />
      </section>
      <section className={`${section} ${carouselSection}`}>
        <Carousel items={tabs.content} />
      </section>
    </main>
    <PageFooter />
  </>
);

HomePage.propTypes = {
  content: PropTypes.shape({
    solutions: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.array,
    }),
    tabs: PropTypes.shape({
      content: PropTypes.array,
    }),
    tiles: PropTypes.shape({
      content: PropTypes.array,
    }),
  }),
  futureOutage: PropTypes.bool,
  hasOutageMessagesSegment: PropTypes.bool,
  isStormReadyWifi: PropTypes.bool,
  outageList: PropTypes.object,
  pastOutage: PropTypes.bool,
};
