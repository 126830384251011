import {READ_GUIDED_SEARCH_ARTICLES_INDEX_SUCCESS} from 'src/shared/guidedSearch/actions/articlesIndex';
import {
  READ_GUIDED_SEARCH_HISTORY_SUCCESS,
  UPDATE_GUIDED_SEARCH_HISTORY_SUCCESS,
} from 'src/shared/guidedSearch/actions/guidedSearchHistory';

export const guidedSearch = (state = {}, action) => {
  switch (action.type) {
    case READ_GUIDED_SEARCH_HISTORY_SUCCESS:
    case UPDATE_GUIDED_SEARCH_HISTORY_SUCCESS:
      return {...state, history: action.value.resource};
    case READ_GUIDED_SEARCH_ARTICLES_INDEX_SUCCESS:
      return {...state, articlesIndex: action.value.resource};
    default:
      return state;
  }
};
