import merge from 'lodash/merge';
import {TRACKING_STARTED} from 'src/shared/dataLayer/actionTypes';
import {waitUntil} from 'src/shared/events';

export const logPage = async page => {
  await waitUntil(TRACKING_STARTED);
  const detail = JSON.parse(JSON.stringify(window.digitalData || {}));
  detail.page = merge(detail.page || {}, page);
  document.dispatchEvent(new CustomEvent('c-tracking-log-page', {detail, bubbles: true}));
};

export const logScreenName = screenName => logPage({
  pageInfo: {
    screenName,
  },
});

export const logExperience = inHarness => logPage({
  pageInfo: {
    attributes: {
      experience: inHarness ? 'native' : 'web',
    },
  },
});

export const logTransaction = async selfServiceTransactions => {
  await waitUntil(TRACKING_STARTED);
  (window.document.getElementById('app')).dispatchEvent(new CustomEvent('c-tracking-log-dom', {
    detail: {
      eventMethod: 'self-ServiceTransaction',
      eventAction: 'self service transaction',
      eventName: 'self service transaction',
      transaction: {
        attributes: {
          selfServiceTransactions,
        },
      },
    },
    bubbles: true,
  }));
};

export const logOutageTrackingEvent = async () => {
  await waitUntil(TRACKING_STARTED);
  (window.document.getElementById('app')).dispatchEvent(new CustomEvent('c-tracking-log-dom', {
    detail: {
      eventName: 'outageTracking_stored',
    },
    bubbles: true,
  }));
};
