import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import {createSelector} from 'reselect';
import {isAuthenticated} from 'src/shared/auth/selectors';
import {
  hasInformationalOutageSegment,
  hasOutageMessagesSegment,
  hasOverrideOutageSegment,
} from 'src/shared/datacast/selectors';
import {flags} from 'src/shared/config/selectors';
import {
  CURRENT,
  CURRENTOUTAGE,
  FUTURE,
  FUTUREOUTAGE,
  GREEN,
  GREY,
  HIGH,
  INFORMATIONAL,
  LOW,
  MEDIUM,
  OVERRIDE,
  PAST,
  PASTOUTAGE,
  RED,
  YELLOW,
} from 'src/shared/outages/constants';
import {createResourceSelector} from 'src/shared/utils/resource';
import {
  ERROR,
  GOOD,
  UNKNOWN,
  WARNING,
} from 'src/shared/components/Notice/statuses';
import {repairType} from 'src/pages/repair/store/selectors/repairType';
import {TV, INTERNET, FLEX} from 'src/pages/repair/constants/repairTypes';
import {userRoles} from 'src/shared/user/selectors';
import {resourceStatus} from 'src/shared/resourceStatus/selectors';

const microOutageMapping = {
  internetOutage: ['emailOutage', 'homeSecurityOutage'],
  tvOutage: ['channelOutage', 'onDemandOutage', 'cDVROutage'],
  voiceOutage: ['voiceMailOutage'],
};

const macroOutages = [
  'internetOutage',
  'tvOutage',
  'voiceOutage',
];

export const outages = createResourceSelector('outages');

export const outageConsolidated = createResourceSelector('outageConsolidated');

export const outageHistory = createResourceSelector('outageHistory');

const summary = createSelector(
  outages,
  outages => outages?.summary || {}
);

const showMessage = createSelector(
  flags,
  hasOutageMessagesSegment,
  isAuthenticated,
  (flags, hasOutageMessagesSegment, isAuthenticated) =>
    hasOutageMessagesSegment || (flags.unauthStatusMapEnabled && !isAuthenticated)
);

export const outageInfoResponseList = createSelector(
  outageConsolidated,
  outageConsolidated => outageConsolidated?.outageInfoResponseList || []
);

export const outageList = createSelector(
  outageInfoResponseList,
  outageInfoResponseList => {
    const servicesAffected = {
      internet: false,
      tv: false,
      voice: false,
      cdvr: false,
      channels: false,
      email: false,
      homesecurity: false,
      ondemand: false,
      voicemail: false,
    };
    for (const outage of outageInfoResponseList) {
      if (outage.outages === CURRENT) {
        for (const service of outage.services) {
          servicesAffected[service.replace(/\s/g, '').toLowerCase()] = true;
        }
      }
    }
    const {internet, tv, voice, cdvr, channels, email, homesecurity, ondemand, voicemail} = servicesAffected;
    return {
      internetOutage: internet,
      tvOutage: tv,
      voiceOutage: voice,
      cDVROutage: cdvr,
      channelOutage: channels,
      emailOutage: email,
      homeSecurityOutage: homesecurity,
      onDemandOutage: ondemand,
      voiceMailOutage: voicemail,
    };
  }
);

export const outageType = createSelector(
  outages,
  outages => {
    const type = outages?.customerMessage?.outage?.outageType;
    if (type) {
      return startCase(type.toLowerCase());
    }
  }
);

export const outageTypeConsolidated = createSelector(
  outageConsolidated,
  outageConsolidated => {
    const type = outageConsolidated?.customerMessage?.outageType;
    if (type) {
      return startCase(type.toLowerCase());
    }
  }
);

export const outageTimeFrame = createSelector(
  outages,
  outages => outages?.customerMessage?.outage?.outageTimeFrame
);

export const outageImpact = createSelector(
  outageConsolidated,
  outageConsolidated => outageConsolidated?.customerMessage?.impactStyle
);

export const lineOfBusiness = createSelector(
  repairType,
  repairType => {
    switch (repairType) {
      case INTERNET:
      case FLEX:
        return 'HSD';
      case TV:
      default:
        return 'Video';
    }
  }
);

export const outageMessage = createSelector(
  outageConsolidated,
  outages,
  lineOfBusiness,
  (outageConsolidated, outages, lineOfBusiness) => {
    const outageLob = outageConsolidated?.lob?.filter(line => {
      return line.impactStyle === MEDIUM && line?.lob === lineOfBusiness;
    });
    return (!!lineOfBusiness && (outageLob?.[0]?.messages?.detailedDescription)
    || outages?.customerMessage?.outage?.message
    || outageConsolidated?.customerMessage?.messages?.detailedDescription);
  }
);

export const informationalOutage = createSelector(
  hasInformationalOutageSegment,
  outageType,
  (hasInformationalOutageSegment, outageType) =>
    hasInformationalOutageSegment
    && outageType === INFORMATIONAL
);

export const overrideOutage = createSelector(
  hasOverrideOutageSegment,
  outageType,
  (hasOverrideOutageSegment, outageType) =>
    hasOverrideOutageSegment
    && outageType === OVERRIDE
);

export const futureOutage = createSelector(
  outageImpact,
  outageTimeFrame,
  showMessage,
  (
    outageImpact,
    outageTimeFrame,
    showMessage
  ) => showMessage ? outageImpact === FUTURE : outageTimeFrame === FUTUREOUTAGE
);

export const pastOutage = createSelector(
  outageImpact,
  outageTimeFrame,
  showMessage,
  (
    outageImpact,
    outageTimeFrame,
    showMessage
  ) => showMessage ? outageImpact === PAST : outageTimeFrame === PASTOUTAGE
);

export const currentOutage = createSelector(
  outageInfoResponseList,
  outageTimeFrame,
  outageType,
  hasInformationalOutageSegment,
  hasOverrideOutageSegment,
  showMessage,
  (
    outageInfoResponseList,
    outageTimeFrame,
    outageType,
    hasInformationalOutageSegment,
    hasOverrideOutageSegment,
    showMessage
  ) => {
    if (showMessage) {
      for (const outage of outageInfoResponseList) {
        if (outage.outages === CURRENT) {
          return true;
        }
      }
      return false;
    }
    if (outageType === INFORMATIONAL) {
      return hasInformationalOutageSegment;
    }
    if (outageType === OVERRIDE) {
      return hasOverrideOutageSegment;
    }
    if (outageTimeFrame !== CURRENTOUTAGE) {
      return false;
    }
    return true;
  }
);

export const noOutage = createSelector(
  hasInformationalOutageSegment,
  hasOverrideOutageSegment,
  outageConsolidated,
  outageImpact,
  outages,
  outageTimeFrame,
  outageType,
  showMessage,
  (
    hasInformationalOutageSegment,
    hasOverrideOutageSegment,
    outageConsolidated,
    outageImpact,
    outages,
    outageTimeFrame,
    outageType,
    showMessage
  ) => {
    if (showMessage) {
      return outageConsolidated && !outageImpact;
    }
    if (isEmpty(outages)) {
      return false;
    }
    if (!outageTimeFrame) {
      return true;
    }
    if (outageType === INFORMATIONAL) {
      return !hasInformationalOutageSegment;
    }
    if (outageType === OVERRIDE) {
      return !hasOverrideOutageSegment;
    }
    return false;
  }
);

export const outagesStatusColor = createSelector(
  outageConsolidated,
  outageImpact,
  outageList,
  outages,
  showMessage,
  (
    outageConsolidated,
    outageImpact,
    outageList,
    {outage, summary = {}},
    showMessage
  ) => {
    if (showMessage) {
      const defaultColor = outageConsolidated === undefined ? GREY : GREEN;
      return macroOutages.reduce((status, macro) => {
        const hasMicro = microOutageMapping[macro].some(micro => outageList[micro]);
        if (outageImpact === HIGH) {
          status[macro] = outageList[macro] && RED || hasMicro && RED || defaultColor;
        } else if (outageImpact === MEDIUM) {
          status[macro] = outageList[macro] && YELLOW || hasMicro && YELLOW || defaultColor;
        } else if (outageImpact === LOW) {
          status[macro] = outageList[macro] && GREY || hasMicro && GREY || defaultColor;
        } else {
          status[macro] = defaultColor;
        }
        return status;
      }, {});
    }
    const defaultColor = outage === undefined ? GREY : GREEN;
    return macroOutages.reduce((status, macro) => {
      const hasMicro = microOutageMapping[macro].some(micro => summary[micro]);
      status[macro] = summary[macro] && RED || hasMicro && YELLOW || defaultColor;
      return status;
    }, {});
  }
);

export const macroOutage = createSelector(
  summary,
  ({internetOutage, tvOutage, voiceOutage}) => internetOutage || tvOutage || voiceOutage || false
);

export const microOutage = createSelector(
  summary,
  macroOutage,
  ({emailOutage, channelOutage, onDemandOutage}, macroOutage) =>
    !macroOutage
    && (emailOutage || channelOutage || onDemandOutage || false)
);

export const currentMacroOutage = createSelector(
  currentOutage,
  macroOutage,
  (currentOutage, macroOutage) => currentOutage && macroOutage
);

export const currentMicroOutage = createSelector(
  currentOutage,
  microOutage,
  (currentOutage, microOutage) => currentOutage && microOutage
);

export const outageStatus = createSelector(
  futureOutage,
  noOutage,
  outageImpact,
  pastOutage,
  (
    futureOutage,
    noOutage,
    outageImpact,
    pastOutage
  ) => {
    if (outageImpact === HIGH) {
      return ERROR;
    } else if (outageImpact === MEDIUM) {
      return WARNING;
    } else if (outageImpact === LOW) {
      return UNKNOWN;
    }
    if (futureOutage || pastOutage || noOutage) {
      return GOOD;
    }
    return UNKNOWN;
  }
);

export const outageEnrollAllowed = createSelector(
  outageConsolidated,
  outageConsolidated => outageConsolidated?.customerMessage?.optInEnroll
);

export const elevatedIssueEligible = createSelector(
  currentOutage,
  microOutage,
  (currentOutage, microOutage) => currentOutage && !microOutage
);

export const outageHeadline = createSelector(
  outageConsolidated,
  outageConsolidated => outageConsolidated?.customerMessage?.messages?.shortDescription
);

export const outagePlanned = createSelector(
  outageConsolidated,
  outageConsolidated => outageConsolidated?.customerMessage?.planned
);

const rolesThatCanCallOutageHistoryAPI = ['manager', 'owner', 'primary'];

export const outageHistoryRoleCheckSelector = createSelector(
  isAuthenticated,
  userRoles,
  resourceStatus,
  (isAuthenticated, userRoles, {complete}) => (
    complete.user
      && isAuthenticated
      && userRoles.some(role => rolesThatCanCallOutageHistoryAPI.includes(role.toLowerCase())
      )
  ));

export const outageHistoryEvents = createSelector(
  outageHistory,
  outageHistory => outageHistory?.body?.events || []
);

export const outageHistoryProductCheck = lob => createSelector(
  outageHistoryEvents,
  outageHistoryEvents => (outageHistoryEvents.length > 0
    && outageHistoryEvents.find(event => event?.product?.toLowerCase().includes(lob)))
);
