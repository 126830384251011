// Feature detection for passive event listeners:
// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Improving_scrolling_performance_with_passive_listeners
let passiveSupported = false;
try {
  window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
    get() {
      passiveSupported = true;
    },
  }));
} catch (err) {}

let lastActivity = Date.now();

export const markActivity = () => {
  lastActivity = Date.now();
};

const pollInactivity = periodDuration => {
  return new Promise(resolve => {
    const poll = () => {
      const timeSinceLastActivity = Date.now() - lastActivity;
      if (timeSinceLastActivity >= periodDuration) {
        resolve();
      } else {
        setTimeout(poll, periodDuration - timeSinceLastActivity + 1000);
      }
    };
    poll();
  });
};

let inactivityPromise = null;

export const waitForPeriodOfInactivity = periodDuration => {
  if (!inactivityPromise) {
    [
      'keydown',
      'mousedown',
      'mousemove',
      'scroll',
      'touchmove',
      'touchstart',
      'wheel',
    ].forEach(eventName =>
      window.addEventListener(
        eventName,
        markActivity,
        passiveSupported ? {passive: true} : false
      )
    );
    inactivityPromise = pollInactivity(periodDuration);
  }
  return inactivityPromise;
};
