import {errorToJSON} from 'src/shared/utils/errorToJSON';
import {AUTH} from 'src/shared/auth/actions';
import {waitUntil} from 'src/shared/events';
import {token} from 'src/shared/auth/selectors';
import {fetchWithToken} from 'src/shared/api';
import {dropBuryUrl} from 'src/shared/config/urls';

export const READ_DROP_BURY_START = 'READ_DROP_BURY_START';
export const READ_DROP_BURY_SUCCESS = 'READ_DROP_BURY_SUCCESS';
export const READ_DROP_BURY_ERROR = 'READ_DROP_BURY_ERROR';
export const READ_DROP_BURY_COMPLETE = 'READ_DROP_BURY_COMPLETE';

export const readDropBuryStart = () => ({
  type: READ_DROP_BURY_START,
});

export const readDropBurySuccess = dropBury => ({
  type: READ_DROP_BURY_SUCCESS,
  value: {dropBury},
});

export const readDropBuryError = error => ({
  type: READ_DROP_BURY_ERROR,
  value: {error: errorToJSON(error)},
});

export const readDropBuryComplete = () => ({
  type: READ_DROP_BURY_COMPLETE,
});

export const readDropBury = () => async (dispatch, getState) => {
  dispatch(readDropBuryStart());
  await waitUntil(AUTH);
  try {
    const response = await fetchWithToken(dropBuryUrl(getState()), token(getState()));
    if (response.ok) {
      dispatch(readDropBurySuccess(await response.json()));
    } else {
      dispatch(readDropBuryError(new Error(response.status)));
    }
  } catch (error) {
    dispatch(readDropBuryError(error));
  } finally {
    dispatch(readDropBuryComplete());
  }
};
