import React from 'react';
import {url} from 'src/shared/utils/url';

export const Accessibility = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props} role="img" aria-hidden="true">
    <g transform="translate(0 2.282)">
      <defs>
        <linearGradient
          id="a11y-gradient-1"
          x1="54.555"
          x2="54.004"
          y1="77.499"
          y2="76.948"
          gradientTransform="matrix(21.6282 0 0 21.6271 -1162.108 -1662.748)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#0b5d66"></stop>
          <stop offset="1" stopColor="#107e89"></stop>
        </linearGradient>
        <linearGradient
          id="a11y-gradient-2"
          x1="52.936"
          x2="52.385"
          y1="77.128"
          y2="76.622"
          gradientTransform="matrix(12.9277 0 0 12.3895 -674.932 -934.657)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#0b5d66"></stop>
          <stop offset="1" stopColor="#107e89"></stop>
        </linearGradient>
      </defs>
      <path fill="#B1C6D2" d="M28.9 11.9c0-.2.1-.4.1-.6v-.8c0-4.6-3.7-8.3-8.4-8.3-4.6 0-8.4 3.7-8.4 8.3 0 .8.1 1.7.4 2.4l1.7 5.2c.4 1.5 1.2 2.8 2.3 3.8l-.6 4.5h8.1V24c1.9-.5 3.5-1.8 4.1-3.7.4-.9 1.9-3.8 1.9-3.8-1.5-.4-1.3-3.3-1.2-4.6"></path>
      <path fill={url('#a11y-gradient-1')} d="M11.5.9v1.7c-.8.1-1.5.4-2.1.8L8.2 2.2 6.7 3.7l1.2 1.2c-.4.6-.7 1.3-.8 2.1H5.4v2.1h1.7c.2.7.4 1.4.8 2l-1.2 1.2 1.5 1.5 1.2-1.2c.6.4 1.3.7 2 .8v1.7h2.1v-1.7c.7-.2 1.4-.4 2-.8l1.2 1.2 1.5-1.5-1.2-1.2c.4-.6.7-1.3.8-2h1.7V7H18c-.2-.7-.4-1.4-.8-2l1.2-1.2-1.5-1.5-1.2 1.2c-.6-.4-1.3-.7-2-.8V.9h-2.2zM9.8 8c0-1.5 1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7S9.8 9.6 9.8 8z"></path>
      <path fill={url('#a11y-gradient-2')} d="M5.6 14v1c-.4.1-.8.2-1.2.5l-.7-.7-.9.9.7.7c-.2.3-.4.7-.5 1.2H2v1.2h1c.1.4.3.8.5 1.2l-.7.7.9.9.7-.7c.4.2.8.4 1.2.5v1h1.3v-1c.4-.1.8-.3 1.2-.5l.7.7.9-.9-.7-.8c.2-.3.4-.7.5-1.2h1v-1.2h-1c-.1-.4-.3-.8-.5-1.2l.7-.7-.9-.9-.7.7c-.4-.2-.8-.4-1.2-.5v-1l-1.3.1zm-1 4.1c0-.9.7-1.6 1.6-1.6s1.6.7 1.6 1.6c0 .9-.7 1.6-1.6 1.6-.8 0-1.6-.7-1.6-1.6z"></path>
    </g>
  </svg>
);
