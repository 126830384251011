import React from 'react';
import {url} from 'src/shared/utils/url';

export const StatusCenter = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props} role="img" aria-hidden="true">
    <g transform="translate(2)">
      <defs>
        <linearGradient
          id="status-center-gradient"
          x1="51.512"
          x2="52.206"
          y1="107.343"
          y2="107.343"
          gradientTransform="matrix(12.127 0 0 22.927 -614.873 -2438.518)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ffd870"></stop>
          <stop offset="1" stopColor="#ffb526"></stop>
        </linearGradient>
      </defs>
      <path fill={url('#status-center-gradient')} d="M9.8 30.5h8.4L14 14.6 9.8 30.5z"></path>
      <path fill="#414b59" d="M15.9 14.6c0 1-.8 1.9-1.9 1.9s-1.9-.8-1.9-1.9.8-1.9 1.9-1.9 1.9.8 1.9 1.9"></path>
      <path fill="#b0c5d1" d="M19.9 14.6c0-3.2-2.6-5.9-5.9-5.9s-5.9 2.6-5.9 5.9c0 2.1 1.1 3.9 2.7 4.9l.2-.7c-1.3-.9-2.2-2.5-2.2-4.2 0-2.8 2.3-5.1 5.1-5.1s5.1 2.3 5.1 5.1c0 1.7-.9 3.2-2.2 4.2l.2.7c1.8-1 2.9-2.8 2.9-4.9"></path>
      <path fill="#b0c5d1" d="M23.5 14.6c0-5.2-4.2-9.5-9.5-9.5s-9.5 4.2-9.5 9.5c0 3.8 2.2 7 5.4 8.5l.2-.7C7.2 21 5.2 18 5.2 14.6c0-4.8 3.9-8.8 8.8-8.8s8.8 3.9 8.8 8.8c0 3.4-2 6.4-4.8 7.8l.2.7c3.1-1.5 5.3-4.8 5.3-8.5"></path>
      <path fill="#b0c5d1" d="M14 1.5C6.8 1.5.9 7.4.9 14.6c0 5.4 3.3 10.1 8 12.1l.2-.7c-4.4-1.9-7.5-6.3-7.5-11.4C1.6 7.8 7.2 2.2 14 2.2s12.4 5.5 12.4 12.4c0 5.1-3.1 9.5-7.5 11.4l.2.7c4.7-2 8-6.7 8-12.1 0-7.2-5.9-13.1-13.1-13.1"></path>
      <path fill="#ffb527" d="M17.1 26.2l-4.8-4.8-2.4 9.2h8.4l-1.2-4.4z"></path>
    </g>
  </svg>
);
