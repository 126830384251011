import {combineReducers} from 'redux';

const parseQueryParams = args => (args || '')
  .replace(/^[?#]/, '')
  .split('&')
  .map(pair => pair.split('='))
  .reduce((acc, [param, value]) => {
    const decodedParam = param ? decodeURIComponent(param).trim() : '';
    const decodedValue = value ? decodeURIComponent(value).trim() : true;
    if (decodedParam) {
      acc[decodedParam] = decodedValue;
    }
    return acc;
  }, {});

const urlQuery = parseQueryParams(window.location.search);
const urlHash = parseQueryParams(window.location.hash);
const urlParams = Object.assign({}, urlQuery, urlHash);

export const params = combineReducers({
  query: () => urlQuery,
  hash: () => urlHash,
  combined: () => urlParams,
});
