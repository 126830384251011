import React from 'react';
import PropTypes from 'prop-types';
import Xfinity from 'src/svgs/Xfinity.svg';
import {
  container,
  header,
  headerBackground,
  logo as logoStyle,
  search as searchStyle,
} from './style.module.scss';
import classNames from 'classnames';
import {SlimNotice} from 'src/shared/components/SlimNotice';

export const StatefulHeader = ({
  children,
  className,
  slimNoticeEnabled = false,
  headline,
  logo = false,
  search,
  tagline,
}) => (
  <header className={header}>
    <div className={classNames(headerBackground, className)}>
      <div className={container}>
        {logo ? <Xfinity className={logoStyle} /> : null}
        {
          React.isValidElement(headline)
            ? headline
            : <h1 tabIndex="-1">{headline}</h1>
        }
        {
          tagline && (
            React.isValidElement(tagline)
              ? tagline
              : <p>{tagline}</p>
          )
        }
        {
          search && <div className={searchStyle}>{search}</div>
        }
        {children}
      </div>
    </div>
    {slimNoticeEnabled && <SlimNotice />}
  </header>
);

StatefulHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  slimNoticeEnabled: PropTypes.bool,
  headline: PropTypes.node.isRequired,
  logo: PropTypes.bool,
  search: PropTypes.element,
  tagline: PropTypes.node,
};
