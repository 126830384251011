import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef,
} from 'react';
import {LoadingDots} from 'src/shared/components/LoadingDots';
import {
  CHECKING,
  ERROR,
  GOOD,
  UNKNOWN,
  WARNING,
} from 'src/shared/components/Notice/statuses';
import {
  button,
  primary,
  utility,
} from 'src/styles/modules/button.module.scss';
import {
  checking,
  error,
  good,
  loading,
  notice,
  noticeAction,
  noticeBody,
  noticeHeadline,
  noticeIcon,
  noticeMessage,
  unknown,
  warning,
} from './style.module.scss';
import {HarnessLink} from 'src/shared/components/HarnessLink';
import {linkButton} from 'src/styles/modules/linkButton.module.scss';
import classNames from 'classnames';

const statusStyle = status => {
  switch (status) {
    case CHECKING:
      return checking;
    case GOOD:
      return good;
    case WARNING:
      return warning;
    case ERROR:
      return error;
    case UNKNOWN:
    default:
      return unknown;
  }
};

export const Notice = ({
  action,
  className,
  headline,
  icon,
  instanceId,
  isOutageMessage = false,
  link,
  message,
  status,
}) => {
  const ref = useRef(null);

  const noticeImpression = instanceId => {
    if (instanceId) {
      return JSON.stringify({
        containerName: 'Notice',
        component: [
          {
            componentInfo: {
              componentID: 'xhs-notice',
              componentName: 'Notice',
              description: isOutageMessage ? `Outage Message|${instanceId}` : instanceId,
            },
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (status !== CHECKING && instanceId) {
      const impressionData = new CustomEvent('c-tracking-log-dom', {
        bubbles: true,
        composed: true,
        detail: {
          eventMethod: 'component-Track',
          component: [{
            componentInfo: {
              componentID: 'xhs-notice',
              componentName: 'Notice',
              description: isOutageMessage ? `Outage Message|${instanceId}` : instanceId,
            },
          }],
        },
      });
      ref.current.dispatchEvent(impressionData);
    }
  }, [status]);

  return (
    <article
      className={classNames(notice, statusStyle(status), className)}
      data-tracking-parent
      data-tracking={noticeImpression(link && link.instanceId || action && action.instanceId || instanceId)}
      ref={ref}
    >
      {
        status === CHECKING
        && <LoadingDots className={loading} />
      }
      {
        status !== CHECKING
        && (
          <>
            {icon && <div className={noticeIcon}>{icon}</div>}
            <div className={noticeMessage}>
              <h2 className={noticeHeadline}>{headline}</h2>
              {
                (message || (link && link.text && !link.isButton))
                && (
                  <div className={noticeBody}>
                    {
                      link && link.prelink
                      && <HarnessLink className={linkButton} href={link.url}>{link.text}</HarnessLink>
                    }
                    {message}
                    {
                      link && !link.isButton && !link.prelink
                      && <HarnessLink className={linkButton} href={link.url}>{link.text}</HarnessLink>
                    }
                  </div>
                )
              }
            </div>
            {
              (action || link && link.isButton)
              && (
                <div className={noticeAction}>
                  {
                    action
                    && (
                      <button className={`${button} ${primary} ${utility}`} onClick={action.onClick}>{action.text}</button>
                    )
                  }
                  {
                    link && link.isButton
                    && <HarnessLink className={`${button} ${primary}`} href={link.url}>{link.text}</HarnessLink>
                  }
                </div>
              )
            }
          </>
        )
      }
    </article>
  );
};

Notice.propTypes = {
  action: PropTypes.shape({
    instanceId: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
  headline: PropTypes.node,
  icon: PropTypes.node,
  instanceId: PropTypes.string,
  isOutageMessage: PropTypes.bool,
  link: PropTypes.shape({
    instanceId: PropTypes.string,
    isButton: PropTypes.bool,
    prelink: PropTypes.bool,
    target: PropTypes.oneOf(['_self', '_blank']),
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  message: PropTypes.node,
  status: PropTypes.oneOf([
    CHECKING,
    ERROR,
    GOOD,
    UNKNOWN,
    WARNING,
  ]).isRequired,
};
