import {guidedSearchArticlesIndex} from 'src/shared/config/urls';
import {createFetchResourceAction} from 'src/shared/utils/resource';

export const READ_GUIDED_SEARCH_ARTICLES_INDEX_START = 'READ_GUIDED_SEARCH_ARTICLES_INDEX_START';
export const READ_GUIDED_SEARCH_ARTICLES_INDEX_SUCCESS = 'READ_GUIDED_SEARCH_ARTICLES_INDEX_SUCCESS';
export const READ_GUIDED_SEARCH_ARTICLES_INDEX_ERROR = 'READ_GUIDED_SEARCH_ARTICLES_INDEX_ERROR';
export const READ_GUIDED_SEARCH_ARTICLES_INDEX_COMPLETE = 'READ_GUIDED_SEARCH_ARTICLES_INDEX_COMPLETE';

export const readGuidedSearchArticlesIndex = () => createFetchResourceAction({
  start: READ_GUIDED_SEARCH_ARTICLES_INDEX_START,
  success: READ_GUIDED_SEARCH_ARTICLES_INDEX_SUCCESS,
  error: READ_GUIDED_SEARCH_ARTICLES_INDEX_ERROR,
  complete: READ_GUIDED_SEARCH_ARTICLES_INDEX_COMPLETE,
  url: guidedSearchArticlesIndex,
  postProcess: json => Object.entries(json).map(([key, {keywords, title}]) => ({
    android: '',
    hint: '',
    href: `articles/${key}`,
    ios: '',
    phrase: title,
    target: '_self',
    terms: {
      en: (keywords || '').trim(),
      es: '',
    },
  })),
});
