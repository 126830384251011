import {TRANSFERRED} from 'src/shared/channelTracking/constants/actionIds';
import {SUCCESS} from 'src/shared/channelTracking/constants/status';
import {UNKNOWN} from 'src/shared/channelTracking/constants/interactionType';
import {XA} from 'src/shared/channelTracking/constants/targetChannels';

export const trackXA = utterance => ({
  action: {
    id: TRANSFERRED,
    status: SUCCESS,
    utterance,
    metadata: {
      targetChannel: XA,
    },
  },
  eventTimestamp: Date.now().toString(),
  interactionType: UNKNOWN,
});
