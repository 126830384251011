import {createResourceReducer} from 'src/shared/utils/resource';
import {
  LOAD_OUTAGE_CONSOLIDATED_COMPLETE,
  LOAD_OUTAGE_CONSOLIDATED_ERROR,
  LOAD_OUTAGE_CONSOLIDATED_START,
  LOAD_OUTAGE_CONSOLIDATED_SUCCESS,
  LOAD_OUTAGES_COMPLETE,
  LOAD_OUTAGES_ERROR,
  LOAD_OUTAGES_START,
  LOAD_OUTAGES_SUCCESS,
  LOAD_OUTAGE_HISTORY_START,
  LOAD_OUTAGE_HISTORY_COMPLETE,
  LOAD_OUTAGE_HISTORY_SUCCESS,
  LOAD_OUTAGE_HISTORY_ERROR,
  LOAD_COMBINED_OUTAGE_OBJ_START,
  LOAD_COMBINED_OUTAGE_OBJ_COMPLETE,
  LOAD_COMBINED_OUTAGE_OBJ_SUCCESS,
  LOAD_COMBINED_OUTAGE_OBJ_ERROR,
} from './actions';

export const outages = createResourceReducer({
  start: LOAD_OUTAGES_START,
  success: LOAD_OUTAGES_SUCCESS,
  error: LOAD_OUTAGES_ERROR,
  complete: LOAD_OUTAGES_COMPLETE,
});

export const outageConsolidated = createResourceReducer({
  start: LOAD_OUTAGE_CONSOLIDATED_START,
  success: LOAD_OUTAGE_CONSOLIDATED_SUCCESS,
  error: LOAD_OUTAGE_CONSOLIDATED_ERROR,
  complete: LOAD_OUTAGE_CONSOLIDATED_COMPLETE,
});

export const outageHistory = createResourceReducer({
  start: LOAD_OUTAGE_HISTORY_START,
  success: LOAD_OUTAGE_HISTORY_SUCCESS,
  error: LOAD_OUTAGE_HISTORY_ERROR,
  complete: LOAD_OUTAGE_HISTORY_COMPLETE,
});

export const combinedOutageObj = createResourceReducer({
  start: LOAD_COMBINED_OUTAGE_OBJ_START,
  success: LOAD_COMBINED_OUTAGE_OBJ_SUCCESS,
  error: LOAD_COMBINED_OUTAGE_OBJ_ERROR,
  complete: LOAD_COMBINED_OUTAGE_OBJ_COMPLETE,
});
