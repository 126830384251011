import {createSelector} from 'reselect';

import {selectedMacAddress} from 'src/pages/repair/store/selectors/selectedMacAddress';
import {devices} from 'src/pages/repair/store/selectors/devices';

export const selectedDevice = createSelector(
  selectedMacAddress,
  devices,
  (selectedMacAddress, devices) =>
    devices.find(({mac}) => mac === selectedMacAddress) || {}
);
