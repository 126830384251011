exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".cardLink-b1a3b{box-shadow:0 .3rem .6rem 0 rgba(0,0,0,.1);padding:2.4rem 2.4rem 2.4rem 2.4rem;background-color:#fff;border:.1rem solid rgba(0,0,0,0);border-radius:.3rem;padding:2.4rem 2.4rem 2.4rem 2.4rem;transition:border .15s ease-in;align-items:center;color:#44484c;cursor:pointer;display:flex;flex-direction:column;justify-content:center;margin:0}@media only screen and (max-width: 530px){.cardLink-b1a3b{padding:1.8rem 1.2rem 1.8rem 1.2rem}}.cardLink-b1a3b>svg{color:#0272b6;height:4.8rem;width:6rem}.cardLink-b1a3b .linkText-84c4a{color:#0272b6}.cardLink-b1a3b .nonLinkText-6918d{color:#44484c}.cardLink-b1a3b:hover,.cardLink-b1a3b:focus{border:.1rem solid #015383;outline:none;text-decoration:none}.cardLink-b1a3b:hover .linkText-84c4a,.cardLink-b1a3b:focus .linkText-84c4a{text-decoration:underline}.cardLink-b1a3b.slim-a1a5a{flex-direction:row;justify-content:flex-start;padding:1.8rem 1.2rem}.cardLink-b1a3b.slim-a1a5a>svg{margin-right:1.2rem}", ""]);

// Exports
exports.locals = {
	"cardLink": "cardLink-b1a3b",
	"linkText": "linkText-84c4a",
	"nonLinkText": "nonLinkText-6918d",
	"slim": "slim-a1a5a"
};