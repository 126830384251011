import React from 'react';
import PropTypes from 'prop-types';
import {languageToggle} from './style.module.scss';
import {languages} from './propTypes';

export const UnconnectedLanguageToggle = ({currentLanguage, languages}) => (
  <div className={languageToggle}>
    <div>
      Support Site Language:
      <ul>
        {
          languages.map(language => (
            <li key={language.name}>
              {
                language.name === currentLanguage
                  ? <span>{language.text}</span>
                  : <a href={language.href} lang={language.name}>{language.text}</a>
              }
            </li>
          ))
        }
      </ul>
    </div>
  </div>
);

UnconnectedLanguageToggle.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  languages: languages.isRequired,
};
