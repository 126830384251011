import {isAuthenticated} from 'src/shared/auth/selectors';
import {accountNumber} from 'src/shared/linkedAccounts/selectors';

export const requiresAuthentication = state => {
  if (!isAuthenticated(state)) {
    throw new Error('Not authenticated.');
  }
};

export const requiresAccountNumber = state => {
  if (!accountNumber(state)) {
    throw new Error('Account number missing.');
  }
};
