import compact from 'lodash/compact';
import PropTypes from 'prop-types';
import React from 'react';
import {
  cols1,
  cols2,
  cols3,
  cols4,
  fill,
  item,
  mod31,
  mod32,
  mod41,
  mod42,
  mod43,
} from './style.module.scss';

const determineRowSize = childCount => Math.min(childCount, 4);

const rowSizeClass = rowSize => {
  switch (rowSize) {
    case 1:
      return cols1;
    case 2:
      return cols2;
    case 3:
      return cols3;
    case 4:
      return cols4;
    /* istanbul ignore next: the default case should never be hit in real code */
    default:
      return '';
  }
};

const fillClasses = (fillSpace, count) => {
  let classes = '';
  if (fillSpace) {
    classes += fill;
    if (count % 3 === 1) {
      classes += ` ${mod31}`;
    }
    if (count % 3 === 2) {
      classes += ` ${mod32}`;
    }
    if (count % 4 === 1) {
      classes += ` ${mod41}`;
    }
    if (count % 4 === 2) {
      classes += ` ${mod42}`;
    }
    if (count % 4 === 3) {
      classes += ` ${mod43}`;
    }
  }
  return classes;
};

export const FlexGrid = ({
  children,
  className,
  columns,
  fillSpace,
}) => {
  const childCount = React.Children
    .toArray(children)
    .filter(child => React.isValidElement(child)).length;
  if (childCount === 0) {
    return null;
  }
  if (!columns) {
    columns = determineRowSize(childCount);
  }
  return (
    <div className={compact([className, rowSizeClass(columns), fillClasses(fillSpace, childCount)]).join(' ')}>
      {
        React.Children
          .toArray(children)
          .filter(child => React.isValidElement(child))
          .map((child, index) => (<div key={index} className={item}>{child}</div>))
      }
    </div>
  );
};

FlexGrid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  columns: PropTypes.number,
  fillSpace: PropTypes.bool,
};
