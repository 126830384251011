import set from 'lodash/set';
import React from 'react';
import ReactDOM from 'react-dom';
import 'src/shared/a11y/reactAxe';
import {Provider} from 'react-redux';
import {App} from 'src/pages/home/components/App';
import {store} from 'src/pages/home/store';
import {startPage} from 'src/pages/home/store/actions/startPage';
import 'src/styles/base.scss';

store.dispatch(startPage());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app')
);

set(window, 'XHS.store', store);
